import {genericForwardRef, useForkRef} from '@cheddarup/react-util'
import {SetOptional, SimpleMerge} from '@cheddarup/util'
import {
  AriaCalendarCellProps,
  AriaCalendarGridProps,
  DateValue,
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
} from 'react-aria'
import {
  CalendarState,
  CalendarStateOptions,
  RangeCalendarState,
  useCalendarState,
} from 'react-stately'
import {cn} from '../../utils'
import {IconButton} from '../IconButton'
import {PhosphorIcon} from '../../icons'
import {Text} from '../Text'
import React, {useRef} from 'react'
import {
  DateDuration,
  endOfMonth,
  getLocalTimeZone,
  getWeeksInMonth,
  today,
} from '@internationalized/date'
import {Button} from '../Button'
import {createCalendar} from '../DateInput'

export interface CalendarProps<T extends DateValue>
  extends Omit<
    SimpleMerge<
      React.ComponentPropsWithoutRef<'div'>,
      SetOptional<CalendarStateOptions<T>, 'locale'>
    >,
    'createCalendar' | 'onChange'
  > {
  onValueChange?: (value: DateValue) => void
  disabledDays?: DateValue[] | ((date: DateValue) => boolean)
}

export const Calendar = genericForwardRef(
  <T extends DateValue>(
    {
      locale = 'en-US',
      onValueChange,
      className,
      disabledDays,
      isDateUnavailable,
      ...restProps
    }: CalendarProps<T>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const state = useCalendarState({
      locale,
      createCalendar,
      ...restProps,
      onChange: onValueChange,
      isDateUnavailable: disabledDays
        ? typeof disabledDays === 'function'
          ? disabledDays
          : (date) =>
              disabledDays.some(
                (disabledDate) => date.compare(disabledDate) === 0,
              )
        : isDateUnavailable,
    })
    const {
      calendarProps,
      prevButtonProps: {
        isDisabled: isPrevButtonDisabled,
        onPress: onPrevButtonPress,
        onFocusChange: onPrevButtonFocusChange,
        ...prevButtonProps
      },
      nextButtonProps: {
        isDisabled: isNextButtonDisabled,
        onPress: onNextButtonPress,
        onFocusChange: onNextButtonFocusChange,
        ...nextButtonProps
      },
      title,
    } = useCalendar(restProps, state)

    return (
      <div
        ref={forwardedRef}
        className={cn(
          'Calendar flex flex-col gap-3 rounded-md border bg-trueWhite p-3 drop-shadow-md',
          className,
        )}
        {...calendarProps}
      >
        <div className="Calendar-navContainer flex items-center justify-between gap-8">
          <IconButton
            className="Calendar-navPrev text-grey-500 hover:bg-grey-200"
            size="compact"
            variant="outlined"
            disabled={isPrevButtonDisabled}
            onClick={() => state.focusPreviousPage()}
            {...prevButtonProps}
          >
            <PhosphorIcon icon="caret-left" />
          </IconButton>

          <Text className="Calendar-navDisplayDate">{title}</Text>

          <IconButton
            className="Calendar-navNext text-grey-500 hover:bg-grey-200"
            size="compact"
            variant="outlined"
            disabled={isNextButtonDisabled}
            onClick={() => state.focusNextPage()}
            {...nextButtonProps}
          >
            <PhosphorIcon icon="caret-right" />
          </IconButton>
        </div>
        <CalendarGrid state={state} />
      </div>
    )
  },
)

// MARK: – CalendarGrid

export interface CalendarGridProps
  extends SimpleMerge<
    React.ComponentPropsWithoutRef<'div'>,
    AriaCalendarGridProps
  > {
  locale?: string
  offset?: DateDuration
  state: CalendarState | RangeCalendarState
}

export const CalendarGrid = React.forwardRef<HTMLDivElement, CalendarGridProps>(
  (
    {
      locale = 'en-US',
      state,
      className,
      weekdayStyle = 'short',
      offset,
      ...restProps
    },
    forwardedRef,
  ) => {
    const startDate = state.visibleRange.start.add(offset ?? {})
    const endDate = endOfMonth(startDate)

    const {
      gridProps,
      headerProps,
      weekDays: _weekDays,
    } = useCalendarGrid({weekdayStyle, startDate, endDate, ...restProps}, state)

    // Convert Sun, Mon, Tue -> Su, Mo, Tu
    const weekDays =
      weekdayStyle === 'short'
        ? _weekDays.map((day) => day.substring(0, 2))
        : _weekDays

    return (
      <div
        ref={forwardedRef}
        className={cn('CalendarGrid flex flex-col gap-1', className)}
        {...gridProps}
      >
        <div
          className="CalendarGrid-weekdaysContainer flex *:flex-[1_0_0px]"
          {...headerProps}
        >
          {weekDays.map((weekDayName, idx) => (
            <Text
              key={idx}
              className="Calendar-weekDay text-center text-ds-xs text-grey-500"
            >
              {weekDayName}
            </Text>
          ))}
        </div>

        <div className="CalendarGrid-content flex flex-col gap-0_5">
          {[
            ...Array.from({
              length: getWeeksInMonth(startDate, locale),
            }).keys(),
          ].map((weekIdx) => (
            <div
              key={weekIdx}
              className="CalendarGrid-weekContainer flex gap-0_5 *:flex-[1_0_0px]"
            >
              {state
                .getDatesInWeek(weekIdx, startDate)
                .map((date, idx) =>
                  date ? (
                    <CalendarCell key={idx} state={state} date={date} />
                  ) : (
                    <div key={idx} />
                  ),
                )}
            </div>
          ))}
        </div>
      </div>
    )
  },
)

// MARK: – CalendarCell

export interface CalendarCellProps
  extends SimpleMerge<
    React.ComponentPropsWithoutRef<'div'>,
    AriaCalendarCellProps
  > {
  state: CalendarState | RangeCalendarState
}

export const CalendarCell = React.forwardRef<HTMLDivElement, CalendarCellProps>(
  ({date, state, className, ...restProps}, forwardedRef) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const {
      cellProps,
      buttonProps,
      isOutsideVisibleRange,
      isDisabled,
      isUnavailable,
      formattedDate,
    } = useCalendarCell({...restProps, date}, state, ownRef)

    return (
      <div
        data-today={date.compare(today(getLocalTimeZone())) === 0}
        // data-selected-leading={state.selected}
        // data-selected-trailing={}
        className={cn('CalendarCell group', className)}
        {...cellProps}
      >
        <Button
          ref={ref}
          className={cn(
            'CalendarCell-button w-full rounded-md p-0 hover:bg-grey-200 hover:text-teal-600',
            'group-aria-selected:!bg-teal-600 group-aria-selected:text-trueWhite group-aria-selected:group-focus:bg-teal-80',
            'disabled:text-grey-500 group-data-[today="true"]:bg-gray100',
            'group-aria-selected:focus:shadow-[0_0_0_1px_theme(colors.trueWhite),0_0_0_2px_theme(colors.teal.600)]',
          )}
          hidden={isOutsideVisibleRange}
          size="default"
          variant="ghost"
          disabled={isUnavailable || isDisabled}
          {...buttonProps}
        >
          {formattedDate}
        </Button>
      </div>
    )
  },
)
