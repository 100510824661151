import * as Ariakit from '@ariakit/react'
import React, {useImperativeHandle} from 'react'
import {tailwindConfig} from '@cheddarup/tailwind-config'

import {NextButton} from './Button'
import {cn} from '../../utils'
import {Separator} from './Separator'

export interface MenuInstance extends Ariakit.MenuStore {}

export interface MenuProps extends Ariakit.MenuStoreProps {
  children?: React.ReactNode | ((menu: MenuInstance) => React.ReactNode)
}

export const Menu = React.forwardRef<MenuInstance, MenuProps>(
  ({children, ...restProps}, forwardedRef) => {
    const menu = Ariakit.useMenuStore(restProps)

    useImperativeHandle(forwardedRef, () => menu, [menu])

    return (
      <Ariakit.MenuProvider store={menu}>
        {typeof children === 'function' ? children(menu) : children}
      </Ariakit.MenuProvider>
    )
  },
)

// MARK: - MenuButton

export interface MenuButtonProps extends Ariakit.MenuButtonProps {}

export const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
  (props, forwardedRef) => (
    <Ariakit.MenuButton ref={forwardedRef} render={<NextButton />} {...props} />
  ),
)

// MARK: - MenuList

export interface MenuListProps extends Omit<Ariakit.MenuProps, 'children'> {
  children?: React.ReactNode | ((menu: MenuInstance) => React.ReactNode)
}

export const MenuList = React.forwardRef<HTMLDivElement, MenuListProps>(
  ({className, children, ...restProps}, forwardedRef) => {
    const menu = useMenu()
    const isOpen = Ariakit.useStoreState(menu, (s) => s.open)
    const isAnimating = Ariakit.useStoreState(menu, (s) => s.animating)

    if (!isOpen && !isAnimating) {
      return null
    }

    return (
      <Ariakit.Menu
        ref={forwardedRef}
        aria-label="Menu"
        className={cn(
          'flex flex-col overflow-y-auto rounded-default bg-trueWhite py-2 focus:outline-none data-[open=true]:shadow-500',
          '-translate-y-4 scale-90 opacity-0 transition data-[enter]:translate-y-0 data-[enter]:scale-100 data-[enter]:opacity-100',
          className,
        )}
        gutter={Number.parseInt(tailwindConfig.theme.spacing[2])}
        portal
        {...restProps}
      >
        {typeof children === 'function' ? children(menu) : children}
      </Ariakit.Menu>
    )
  },
)

// MARK: - MenuGroup

export interface MenuGroupProps extends Ariakit.MenuGroupProps {}

export const MenuGroup = Ariakit.MenuGroup

// MARK: - MenuItem

export interface MenuItemProps extends Ariakit.MenuItemProps {}

export const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(
  ({className, ...restProps}, forwardedRef) => (
    <Ariakit.MenuItem
      ref={forwardedRef}
      className={cn(
        'min-w-36 justify-between bg-trueWhite px-4 py-2 text-start font-normal text-ds-base text-grey-700',
        'transition-colors data-[active-item]:bg-teal-100',
        '[&_svg]:text-[1.25em]',
        className,
      )}
      render={
        <NextButton size="headless" variant="headless" roundness="headless" />
      }
      {...restProps}
    />
  ),
)

// MARK: - MenuSeparator

export interface MenuSeparatorProps extends Ariakit.MenuSeparatorProps {}

export const MenuSeparator = React.forwardRef<
  HTMLHRElement,
  MenuSeparatorProps
>((props, forwardedRef) => (
  <Ariakit.MenuSeparator
    ref={forwardedRef}
    render={<Separator variant="primary" />}
    {...props}
  />
))

// MARK: – Hooks

export function useMenu() {
  const menu = Ariakit.useMenuContext()

  if (!menu) {
    throw new Error('useMenu must be used in a component wrapped by menu')
  }

  return menu
}
