import * as NextUI from '@cheddarup/web-ui/next'
import * as WebUI from '@cheddarup/web-ui'
import {
  DashboardPageContainer,
  DashboardPageHeader,
  DashboardPageHeading,
  DashboardPageContent,
  DashboardPanel,
  DashboardPageFooter,
  DashboardSettingPanel,
  DashboardSettingPanelLabel,
  DashboardSettingContent,
} from 'src/components/DashboardPageLayout'
import {
  SplitLayout,
  SplitLayoutMain,
  SplitLayoutSecondary,
} from 'src/components/Layout'
import {
  SettingDisclosure,
  SettingDisclosureContent,
  SettingDisclosureSwitch,
} from 'src/components/SettingDisclosure'
import {useNavigate} from 'react-router-dom'
import {useCurrentFundraiserId, useCurrentFundraiserQuery} from '../hooks'
import {
  CalendarDate,
  getLocalTimeZone,
  Time,
  toCalendarDateTime,
} from '@internationalized/date'
import {useZodFormik} from '@cheddarup/react-util'
import {
  FormikControl,
  FormikField,
  FormikForm,
  FormikSubmitButton,
} from 'src/components/Formik'
import {TabBannerPicker} from 'src/components/TabBannerPicker'
import {BrandingUpsellPanel} from 'src/components/BrandingUpsellPanel'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'
import {z} from '@cheddarup/util'
import {
  p2pFundraisingLeaderboardTypeSchema,
  s3ImageSchema,
  useCreateTabMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'

const CALL_TO_ACTION_BUTTON_TEXT_MAX_LENGTH = 30

const formSchema = z.object({
  name: z.string().trim().min(1).default(''),
  description: z.string().default(''),
  header_images: s3ImageSchema.array().default([]),
  options: z.object({
    infoBlockSettings: z.object({
      videoLink: z.string().default(''),
      time: z
        .object({
          startDate: z.instanceof(CalendarDate).nullable().default(null),
          startTime: z.instanceof(Time).nullable().default(null),
          endDate: z.instanceof(CalendarDate).nullable().default(null),
          endTime: z.instanceof(Time).nullable().default(null),
        })
        .refine((val) => !val.startDate || val.endDate, {
          message: 'Required',
          path: ['endDate'],
        })
        .refine(
          (val) => {
            if (!val.startDate || !val.endDate) {
              return true
            }

            const startDateTime = toCalendarDateTime(
              val.startDate,
              val.startTime ?? undefined,
            )
            const endDateTime = toCalendarDateTime(
              val.endDate,
              val.endTime ?? undefined,
            )

            return startDateTime.compare(endDateTime) <= 0
          },
          {
            message: "Can't be earlier than start date",
            path: ['endDate'],
          },
        ),
      location: z.object({
        address: z.string().default(''),
        latitude: z.number().nullable().default(null),
        longitude: z.number().nullable().default(null),
      }),
      totalCollected: z.object({
        enabled: z.boolean().default(true),
      }),
      faqs: z.object({
        enabled: z.boolean().default(false),
      }),
    }),
    paymentGoal: z.object({
      value: z.coerce.string().default(''),
    }),
    p2pFundraising: z.object({
      supporterFeed: z.object({
        enabled: z.boolean().default(true),
      }),
      leaderboard: z.object({
        enabled: z.boolean().default(true),
        type: p2pFundraisingLeaderboardTypeSchema.default('participant'),
      }),
      callToAction: z.object({
        buttonText: z
          .string()
          .max(
            CALL_TO_ACTION_BUTTON_TEXT_MAX_LENGTH,
            `Cannot exceed ${CALL_TO_ACTION_BUTTON_TEXT_MAX_LENGTH} characters`,
          )
          .default(''),
        includeIcon: z.boolean().default(true),
      }),
    }),
  }),
})

export type FundraiserDetailsFormValues = z.infer<typeof formSchema>

export const FundraiserDetailsPage = () => {
  const navigate = useNavigate()
  const [managerRoleId] = useManagerRoleId()
  const fundraiserId = useCurrentFundraiserId()
  const [fundraiserQuery] = useCurrentFundraiserQuery()
  const createTabMutation = useCreateTabMutation()
  const updateTabMutation = useUpdateTabMutation()

  const fundraiser = fundraiserQuery.data

  const formik = useZodFormik({
    schema: formSchema,
    initialValues: fundraiser
      ? Util.nullToUndefined({
          name: fundraiser.name,
          description: fundraiser.description,
          header_images: Util.sort(fundraiser.header_images ?? []).asc(
            (hImg) =>
              fundraiser.options.imageCarouselIds?.indexOf(hImg.id) ??
              Number.MAX_SAFE_INTEGER,
          ),
          options: {
            infoBlockSettings: {
              videoLink: fundraiser.options.infoBlockSettings?.video?.enabled
                ? fundraiser.options.infoBlockSettings.video.link
                : undefined,
              time: {
                startDate: Util.parseCalendarDate(
                  fundraiser.options.infoBlockSettings?.time?.startTime ?? '',
                ),
                startTime: Util.parseTime(
                  fundraiser.options.infoBlockSettings?.time?.startTime ?? '',
                ),
                endDate: Util.parseCalendarDate(
                  fundraiser.options.infoBlockSettings?.time?.endTime ?? '',
                ),
                endTime: Util.parseTime(
                  fundraiser.options.infoBlockSettings?.time?.endTime ?? '',
                ),
              },
              location: fundraiser.options.infoBlockSettings?.location ?? {},
              totalCollected:
                fundraiser.options.infoBlockSettings?.totalCollected ?? {},
              faqs: fundraiser.options.infoBlockSettings?.faqs ?? {},
            },
            paymentGoal: fundraiser.options.paymentGoal ?? {},
            p2pFundraising: {
              supporterFeed:
                fundraiser.options.p2pFundraising?.supporterFeed ?? {},
              leaderboard: fundraiser.options.p2pFundraising?.leaderboard ?? {},
              callToAction:
                fundraiser.options.p2pFundraising?.callToAction ?? {},
            },
          },
        })
      : undefined,
    onSubmit: async (values) => {
      const startDateTime = values.options.infoBlockSettings.time.startDate
        ? toCalendarDateTime(
            values.options.infoBlockSettings.time.startDate,
            values.options.infoBlockSettings.time.startTime ?? undefined,
          )
        : null
      const endDateTime = values.options.infoBlockSettings.time.endDate
        ? toCalendarDateTime(
            values.options.infoBlockSettings.time.endDate,
            values.options.infoBlockSettings.time.endTime ?? undefined,
          )
        : null

      const body = {
        name: values.name,
        description: values.description,
        do_not_publish: true,
        imageCarouselIds: values.header_images.map((hi) => hi.id),
        featuredImageId: values.header_images[0]?.id,
        options: {
          paymentGoal: {
            enabled: typeof values.options.paymentGoal.value === 'number',
            value: values.options.paymentGoal.value
              ? Number(values.options.paymentGoal.value)
              : undefined,
          },
          p2pFundraising: {
            enabled: true,
            type: 'flat' as const,
            supporterFeed: values.options.p2pFundraising.supporterFeed,
            leaderboard: values.options.p2pFundraising.leaderboard,
            callToAction: values.options.p2pFundraising.callToAction,
          },
          infoBlockSettings: {
            location: values.options.infoBlockSettings.location,
            totalCollected: values.options.infoBlockSettings.totalCollected,
            faqs: values.options.infoBlockSettings.faqs,
            video: {
              enabled: values.options.infoBlockSettings.videoLink.length > 0,
              link:
                values.options.infoBlockSettings.videoLink ||
                fundraiser?.options.infoBlockSettings?.video?.link ||
                undefined,
              platform:
                WebUI.getVideoPlatformForUrl(
                  values.options.infoBlockSettings.videoLink,
                ) ?? undefined,
            },
            time:
              !!startDateTime && !!endDateTime
                ? {
                    enabled: true,
                    startTime: startDateTime
                      .toDate(getLocalTimeZone())
                      .toISOString(),
                    endTime: endDateTime
                      .toDate(getLocalTimeZone())
                      .toISOString(),
                    timeZone: getLocalTimeZone(),
                  }
                : {enabled: false},
          },
        },
      }

      let newFundraiser = fundraiser

      if (fundraiserId) {
        newFundraiser = await updateTabMutation.mutateAsync({
          pathParams: {
            tabId: fundraiserId,
          },
          body,
        })
      } else {
        newFundraiser = await createTabMutation.mutateAsync({body})
        navigate(`/fundraisers/${newFundraiser.id}/build/details`, {
          replace: true,
        })
      }

      navigate(`/fundraisers/${newFundraiser.id}/build/peer-to-peer`)
    },
  })

  return (
    <FormikForm className="contents" formik={formik}>
      <DashboardPageContainer>
        <DashboardPageContent>
          <DashboardPageHeader>
            <DashboardPageHeading>Fundraiser Details</DashboardPageHeading>
            <span>
              These details will be added to your main fundraising page. When
              visitors click to make a donation, they will be able to make a
              donation on behalf of a selected participant.
            </span>
          </DashboardPageHeader>

          <SplitLayout>
            <SplitLayoutMain>
              <DashboardPanel>
                <NextUI.PanelLabel>Fundraiser Details</NextUI.PanelLabel>

                <div className="flex flex-col gap-4">
                  <TabBannerPicker
                    tab={fundraiser}
                    images={formik.values.header_images}
                    videoLink={
                      formik.values.options.infoBlockSettings.videoLink
                    }
                    onImagesChange={(newImages) =>
                      formik.setFieldValue('header_images', newImages)
                    }
                    onVideoLinkChange={(videoValues) =>
                      formik.setFieldValue(
                        'options.infoBlockSettings.videoLink',
                        videoValues.link,
                      )
                    }
                  />

                  <FormikField
                    name="name"
                    label="Fundraiser Title"
                    control={
                      <NextUI.Input placeholder="Donate to Make a Difference" />
                    }
                  />
                  <FormikField
                    name="description"
                    required
                    label="Description"
                    control={(fieldProps, field, fieldMeta) => (
                      <WebUI.RichTextEditor
                        className="min-h-64 text-ds-base"
                        name={fieldProps.name}
                        defaultValue={fieldMeta.initialValue}
                        placeholder="Add a description"
                        onMarkdownValueChange={(newValue) =>
                          field.setValue(newValue)
                        }
                        onBlur={fieldProps.onBlur}
                      >
                        <WebUI.RichTextEditorToolbar rootClassName="-order-1" />
                      </WebUI.RichTextEditor>
                    )}
                  />

                  <div className="flex flex-row flex-wrap gap-4 *:flex-0">
                    <FormikField
                      name="options.infoBlockSettings.time.startDate"
                      label="Start Date"
                      control={(fieldProps, field) => (
                        <NextUI.DatePicker
                          value={fieldProps.value}
                          onValueChange={(newValue) => {
                            field.setValue(newValue)

                            if (
                              !formik.values.options.infoBlockSettings.time
                                .startTime
                            ) {
                              formik.setFieldValue(
                                'options.infoBlockSettings.time.startTime',
                                new Time(0, 0),
                              )
                            }
                          }}
                          onBlur={fieldProps.onBlur}
                        />
                      )}
                    />
                    <FormikField
                      name="options.infoBlockSettings.time.startTime"
                      label="Start Time"
                      control={(fieldProps, field) => (
                        <NextUI.TimeInput
                          value={fieldProps.value}
                          onValueChange={(newValue) => field.setValue(newValue)}
                          onBlur={fieldProps.onBlur}
                        />
                      )}
                    />

                    <FormikField
                      name="options.infoBlockSettings.time.endDate"
                      label="End Date"
                      control={(fieldProps, field) => (
                        <NextUI.DatePicker
                          value={fieldProps.value}
                          onValueChange={(newValue) => {
                            field.setValue(newValue)

                            if (
                              !formik.values.options.infoBlockSettings.time
                                .endTime
                            ) {
                              formik.setFieldValue(
                                'options.infoBlockSettings.time.endTime',
                                new Time(0, 0),
                              )
                            }
                          }}
                          onBlur={fieldProps.onBlur}
                        />
                      )}
                    />
                    <FormikField
                      name="options.infoBlockSettings.time.endTime"
                      label="End Time"
                      control={(fieldProps, field) => (
                        <NextUI.TimeInput
                          value={fieldProps.value}
                          onValueChange={(newValue) => field.setValue(newValue)}
                          onBlur={fieldProps.onBlur}
                        />
                      )}
                    />
                  </div>
                  <FormikField
                    name="options.infoBlockSettings.location.address"
                    label="Event Location"
                    control={(fieldProps, field, fieldMeta) => (
                      <WebUI.AddressCombobox
                        placeholder="Street Address"
                        defaultAddress={fieldMeta.initialValue?.address}
                        creatable
                        withDetails
                        onAddressChange={(location) =>
                          field.setValue({
                            address: location.address,
                            latitude:
                              location.details?.geometry?.location?.lat() ??
                              null,
                            longitude:
                              location.details?.geometry?.location?.lng() ??
                              null,
                          })
                        }
                        onBlur={fieldProps.onBlur}
                      />
                    )}
                  />
                  <NextUI.Switch className="mt-2 text-ds-sm" size="sm">
                    Set a date to stop accepting donations
                  </NextUI.Switch>
                </div>
              </DashboardPanel>

              <DashboardPanel>
                <NextUI.PanelLabel>Motivate Donors</NextUI.PanelLabel>

                <DashboardSettingPanel>
                  <DashboardSettingPanelLabel>
                    Add a fundraising goal for your campaign
                  </DashboardSettingPanelLabel>

                  <FormikField
                    name="options.paymentGoal.value"
                    label="Fundraising Goal"
                    control={(fieldProps, field) => (
                      <NextUI.AmountInput
                        placeholder="$0.00"
                        value={fieldProps.value}
                        name="options.paymentGoal.value"
                        onValueChange={(newValue) => field.setValue(newValue)}
                        onBlur={fieldProps.onBlur}
                      />
                    )}
                  />
                </DashboardSettingPanel>

                <SettingDisclosure
                  open={
                    formik.values.options.infoBlockSettings.totalCollected
                      .enabled
                  }
                  setOpen={(newValue) =>
                    formik.setFieldValue(
                      'options.infoBlockSettings.totalCollected.enabled',
                      newValue,
                    )
                  }
                >
                  <SettingDisclosureSwitch>
                    Show total amount raised
                  </SettingDisclosureSwitch>

                  <SettingDisclosureContent>
                    Share your fundraiser's success with the world by displaying
                    the amount you've raised.
                  </SettingDisclosureContent>
                </SettingDisclosure>
              </DashboardPanel>

              <DashboardPanel>
                <NextUI.PanelLabel>Boost Engagement</NextUI.PanelLabel>

                <SettingDisclosure
                  open={
                    formik.values.options.p2pFundraising.supporterFeed.enabled
                  }
                  setOpen={(newValue) =>
                    formik.setFieldValue(
                      'options.p2pFundraising.supporterFeed.enabled',
                      newValue,
                    )
                  }
                >
                  <SettingDisclosureSwitch>
                    Show donor feed
                  </SettingDisclosureSwitch>

                  <SettingDisclosureContent>
                    Reveal donor contributions in real time to inspire more
                    giving! Donors will have the option to display their name or
                    remain anonymous.
                  </SettingDisclosureContent>
                </SettingDisclosure>

                <SettingDisclosure
                  className="gap-4"
                  open={
                    formik.values.options.p2pFundraising.leaderboard.enabled
                  }
                  setOpen={(newValue) =>
                    formik.setFieldValue(
                      'options.p2pFundraising.leaderboard.enabled',
                      newValue,
                    )
                  }
                >
                  <SettingDisclosureSwitch>
                    Show leaderboard with top fundraising teams or participants
                  </SettingDisclosureSwitch>

                  <SettingDisclosureContent>
                    <FormikControl name="options.p2pFundraising.leaderboard.type">
                      <NextUI.RadioGroup size="sm">
                        <NextUI.Radio value="team">
                          Teams Leaderboard (Teams must be enabled when adding
                          participants)
                        </NextUI.Radio>
                        <NextUI.Radio value="participant">
                          Participants Leaderboard{' '}
                        </NextUI.Radio>
                      </NextUI.RadioGroup>
                    </FormikControl>
                  </SettingDisclosureContent>
                </SettingDisclosure>
              </DashboardPanel>

              <DashboardPanel>
                <NextUI.PanelLabel>Call to Action</NextUI.PanelLabel>

                <DashboardSettingPanel>
                  <DashboardSettingPanelLabel>
                    Customize your call-to-action button text
                  </DashboardSettingPanelLabel>
                  <DashboardSettingContent>
                    <span>
                      The call-to-action button is what donors will click to
                      contribute to your cause (e.g. “Donate”, “Give Today”)
                    </span>

                    <FormikField
                      name="options.p2pFundraising.callToAction.buttonText"
                      description={`${formik.values.options.p2pFundraising.callToAction.buttonText.length}/${CALL_TO_ACTION_BUTTON_TEXT_MAX_LENGTH}`}
                      control={<NextUI.Input placeholder="Donate Today" />}
                    />
                  </DashboardSettingContent>
                </DashboardSettingPanel>

                <SettingDisclosure
                  open={
                    formik.values.options.p2pFundraising.callToAction
                      .includeIcon
                  }
                  setOpen={(newValue) =>
                    formik.setFieldValue(
                      'options.p2pFundraising.callToAction.includeIcon',
                      newValue,
                    )
                  }
                >
                  <SettingDisclosureSwitch>
                    Include a call-to-action icon
                  </SettingDisclosureSwitch>
                  <SettingDisclosureContent>
                    <span>
                      Add a little pizazz to your page! Donors can click to
                      contribute.{' '}
                      <NextUI.NextAnchor
                        // TODO: add the href
                        href="#"
                      >
                        See example
                      </NextUI.NextAnchor>
                    </span>
                  </SettingDisclosureContent>
                </SettingDisclosure>
              </DashboardPanel>

              <DashboardPanel>
                <NextUI.PanelLabel>FAQs</NextUI.PanelLabel>

                <DashboardSettingPanel>
                  <NextUI.FormControl
                    name="options.infoBlockSettings.faqs.enabled"
                    render={
                      <NextUI.Switch
                        className="flex-row-reverse justify-between"
                        size="lg"
                      />
                    }
                  >
                    Include an FAQ section
                  </NextUI.FormControl>
                </DashboardSettingPanel>
              </DashboardPanel>
            </SplitLayoutMain>

            <SplitLayoutSecondary>
              {!managerRoleId && <BrandingUpsellPanel />}
            </SplitLayoutSecondary>
          </SplitLayout>
        </DashboardPageContent>
      </DashboardPageContainer>

      <DashboardPageFooter>
        <FormikSubmitButton size="md">
          Continue <WebUI.PhosphorIcon icon="caret-right" />
        </FormikSubmitButton>
      </DashboardPageFooter>
    </FormikForm>
  )
}
