import * as Yup from 'yup'
import {useFormik} from '@cheddarup/react-util'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {useEffect, useMemo, useRef} from 'react'
import {
  FieldSetList,
  FieldSetListInstance,
  FieldValue,
  makeFieldInitialValues,
  makeFieldsYupSchema,
} from 'src/components/FieldSetList'

import useCart, {
  useEnhancedCreateCartFormMutation,
  useEnhancedResolveCartFieldValues,
} from './hooks/useCart'
import usePublicCollection from './hooks/usePublicCollection'
import {NumberParam, useQueryParam} from 'use-query-params'
import {api, useUpdateCartFormMutation} from '@cheddarup/api-client'
import {PayerButton} from './components/PayerStyled'

const FormViewPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const urlParams = useParams()
  const media = WebUI.useMedia()
  const [linkedItemId] = useQueryParam('linkedItemId', NumberParam)
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const [, createCartFormAsync] = useEnhancedCreateCartFormMutation()
  const updateCartFormMutation = useUpdateCartFormMutation()
  const resolveCartFieldValues = useEnhancedResolveCartFieldValues()
  const dialogRef = useRef<WebUI.DialogInstance>(null)
  const fieldSetListRef = useRef<FieldSetListInstance>(null)

  const formId = Number(urlParams.form)

  // biome-ignore lint/style/noNonNullAssertion:
  const formView = publicCollection.forms.find((f) => f.id === formId)!
  const cartForm = cart?.forms.find(
    (cf) =>
      cf.tab_form.id === formId &&
      (!linkedItemId || cf.detail.linked_item_id === linkedItemId),
  )
  const dynamicFormNamePostFix = cartForm?.detail.name?.split(':')[1]
  const filteredFormViewFields = useMemo(
    () =>
      formView.options.isWaiver
        ? formView.fields.filter((f) => f.field_type === 'signature')
        : formView.fields,
    [formView],
  )
  const formik = useFormik<Record<number, FieldValue>>({
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape(
      makeFieldsYupSchema(filteredFormViewFields),
    ),
    initialValues: makeFieldInitialValues(
      filteredFormViewFields,
      cartForm?.cart_field_views,
    ),
    onSubmit: async (values) => {
      let cartFieldValues = await resolveCartFieldValues({
        fields: filteredFormViewFields,
        values,
      })
      if (cartFieldValues.length === 0 && !cartForm) {
        dialogRef.current?.hide()
        return
      }

      if (formView.options.isWaiver && cart?.uuid) {
        // biome-ignore lint/style/noNonNullAssertion: there must be a sign field on waiver
        const signerNumber = cartFieldValues[0]!.value
        const eSign = await api.eSignatures.detail.fetch({
          pathParams: {
            tabId: publicCollection.slug,
            cartUuid: cart.uuid,
            signerNumber,
          },
        })
        // biome-ignore lint/style/noNonNullAssertion: there must be name fields on waiver
        const firstNameField = formView.fields.find(
          (f) => f.metadata.waiverFieldType === 'first_name',
        )!
        // biome-ignore lint/style/noNonNullAssertion: there must be name fields on waiver
        const lastNameField = formView.fields.find(
          (f) => f.metadata.waiverFieldType === 'last_name',
        )!
        cartFieldValues = [
          ...cartFieldValues,
          {item_field_id: firstNameField.id, value: eSign.first_name},
          {item_field_id: lastNameField.id, value: eSign.last_name},
        ]
      }
      if (cart && cartForm) {
        await updateCartFormMutation.mutateAsync({
          pathParams: {
            tabId: publicCollection.slug,
            cartUuid: cart.uuid,
            formId: cartForm.id,
          },
          body: {
            form_id: formId,
            cart_field_values: cartFieldValues,
          },
        })
      } else {
        await createCartFormAsync({
          body: {
            form_id: formId,
            cart_field_values: cartFieldValues,
          },
        })
      }

      dialogRef.current?.hide()
    },
  })

  const [firstErroredFieldIdAsString] = Object.keys(formik.errors)
  const firstErroredFieldId = firstErroredFieldIdAsString
    ? Number(firstErroredFieldIdAsString)
    : null
  useEffect(() => {
    if (firstErroredFieldId && formik.submitCount > 0) {
      fieldSetListRef.current?.scrollToField(firstErroredFieldId)
    }
  }, [firstErroredFieldId, formik.submitCount])

  return (
    <WebUI.Modal
      ref={dialogRef}
      className="[&_>_.ModalContentView]:max-w-screen-md [&_>_.ModalContentView]:grow [&_>_.ModalContentView]:overflow-auto sm:[&_>_.ModalContentView]:rounded-extended"
      aria-label="Form view"
      onDidHide={() => navigate({pathname: '..', search: location.search})}
    >
      <form
        className="flex min-h-0 w-full grow flex-col"
        noValidate
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}
      >
        <div className="flex grow flex-col gap-8 px-4 py-7 sm:px-11">
          <WebUI.Text className="text-ds-sm">
            {formView.options.isWaiver ? 'Waiver' : 'Form'}
          </WebUI.Text>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <WebUI.Heading className="font-semibold" as="h2">
                  {formView.name}
                  {dynamicFormNamePostFix && (
                    <span className="font-light">{`: ${dynamicFormNamePostFix}`}</span>
                  )}
                </WebUI.Heading>
                {formView.required && (
                  <span className="font-normal text-ds-sm text-orange-500">
                    * Required to Checkout
                  </span>
                )}
              </div>
              <WebUI.Separator variant="primary" />
            </div>

            {formView.description && (
              <WebUI.MarkdownParagraph markdown={formView.description} />
            )}

            <FieldSetList
              ref={fieldSetListRef}
              className="max-w-[560px] overflow-y-visible pb-8"
              fieldSets={formView.options.fieldSets}
              fields={filteredFormViewFields}
              defaultValues={formik.initialValues as any}
              onValuesChange={(fieldId, fieldValue) =>
                formik.setFieldValue(String(fieldId), fieldValue)
              }
              errors={
                formik.submitCount > 0
                  ? (formik.errors as Record<number, string>)
                  : undefined
              }
            />
          </div>
        </div>

        <div className="sticky bottom-0 flex flex-row items-center justify-end gap-4 bg-trueWhite px-11 py-6 shadow-z3">
          {media.sm &&
            formik.submitCount > 0 &&
            Object.keys(formik.errors).length > 0 && (
              <WebUI.Text className="text-orange-500">
                Please correct the errors above
              </WebUI.Text>
            )}
          <PayerButton
            className="grow sm:grow-0"
            type="submit"
            size="large"
            loading={formik.isSubmitting}
          >
            {`Save ${
              formView.options.isWaiver ? 'Waiver' : 'Form'
            } and Continue`}
          </PayerButton>
        </div>
      </form>
      <WebUI.ModalCloseButton />
    </WebUI.Modal>
  )
}

export default FormViewPage
