// See https://ariakit.org/examples/tab-react-router

import {useHref, useLocation} from 'react-router-dom'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {
  Tab,
  TabPanel,
  TabPanelProps,
  TabProps,
  Tabs,
  TabsInstance,
  TabsProps,
  useTabsContext,
} from '@cheddarup/web-ui/next'
import {NextLinkButton, NextLinkButtonProps} from './LinkButton'
import {Merge} from '@cheddarup/util'

export interface RoutableTabsProps extends TabsProps {
  // Should be `true` if should match the entire URL pathname
  end?: boolean
}

export const RoutableTabs = React.forwardRef<TabsInstance, RoutableTabsProps>(
  (props, forwardedRef) => {
    const location = useLocation()
    return (
      <Tabs
        ref={forwardedRef}
        selectOnMove={false}
        selectedId={location.pathname}
        {...props}
      />
    )
  },
)

// MARK: – RoutableTab

export interface RoutableTabProps
  extends Merge<Omit<TabProps, 'id'>, NextLinkButtonProps> {
  end?: boolean
}

export const RoutableTab = React.forwardRef<
  HTMLButtonElement,
  RoutableTabProps
>(({end = false, disabled, to, children, ...restProps}, forwardedRef) => {
  const id = useHref(to).split('?')[0]

  const tabs = useTabsContext()
  const isSelected = WebUI.useStoreState(
    tabs,
    (s) =>
      s?.selectedId != null &&
      id != null &&
      (s.selectedId === id || (!end && s.selectedId.startsWith(id))),
  )

  return (
    <Tab
      ref={forwardedRef}
      id={id}
      disabled={disabled}
      render={
        <NextLinkButton
          aria-current={isSelected ? 'page' : undefined}
          disabled={disabled}
          to={to}
          size="md"
          variant="headless"
          {...restProps}
        />
      }
    >
      {children}
    </Tab>
  )
})

// MARK: – RoutableTabPanel

export interface RoutableTabPanelProps extends Omit<TabPanelProps, 'tabId'> {}

export const RoutableTabPanel = React.forwardRef<
  HTMLDivElement,
  RoutableTabPanelProps
>((props, forwardedRef) => {
  const tabs = useTabsContext()
  const tabId = WebUI.useStoreState(tabs, (s) => s?.selectedId)

  return <TabPanel ref={forwardedRef} tabId={tabId} {...props} />
})
