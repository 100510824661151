import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import React from 'react'

// MARK: – DashboardPageContainer

export const DashboardPageContainer = ({
  className,
  ...restProps
}: NextUI.RoleProps) => (
  <NextUI.Role
    className={WebUI.cn(
      'flex flex-auto flex-col items-center overflow-y-auto',
      className,
    )}
    {...restProps}
  />
)

// MARK: – DashboardPageContent

export const DashboardPageContent = ({
  className,
  ...restProps
}: NextUI.RoleProps) => (
  <NextUI.Role
    className={WebUI.cn(
      'container flex flex-auto flex-col gap-8 py-8 sm:px-9',
      className,
    )}
    render={<main />}
    {...restProps}
  />
)

// MARK: – DashboardPageHeader

export const DashboardPageHeader = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <div
    className={WebUI.cn('flex max-w-4xl flex-col gap-2 max-sm:px-4', className)}
    {...restProps}
  />
)

// MARK: – DashboardPageFooter

export const DashboardPageFooter = React.forwardRef<
  HTMLDivElement,
  NextUI.RoleProps
>(({className, ...restProps}, forwardedRef) => (
  <NextUI.Role
    ref={forwardedRef}
    className={WebUI.cn(
      'flex flex-row items-center justify-end gap-3 border-t bg-trueWhite px-10 py-6',
      className,
    )}
    {...restProps}
  />
))

// MARK: - DashboardPanelHeader

export const DashboardPanelHeader = DashboardPageHeader

// MARK: – DashboardPageHeading

export interface DashboardPageHeadingProps extends NextUI.NextHeadingProps {}

export const DashboardPageHeading = React.forwardRef<
  HTMLHeadingElement,
  DashboardPageHeadingProps
>(({className, ...restProps}, forwardedRef) => (
  <NextUI.NextHeading
    ref={forwardedRef}
    className={WebUI.cn('font-body font-bold text-ds-2xl', className)}
    as="h1"
    {...restProps}
  />
))

// MARK: – DashboardPanel

export const DashboardPanel = React.forwardRef<
  HTMLDivElement,
  NextUI.PanelProps
>(({className, ...restProps}, forwardedRef) => (
  <NextUI.Panel
    ref={forwardedRef}
    className={WebUI.cn('gap-4 px-4 py-5 sm:gap-8 sm:px-8 sm:py-9', className)}
    render={<section />}
    {...restProps}
  />
))

// MARK: – DashboardSettingPanel

export const DashboardSettingPanel = React.forwardRef<
  HTMLDivElement,
  NextUI.PanelProps
>(({className, ...restProps}, forwardedRef) => (
  <NextUI.Panel
    ref={forwardedRef}
    className={WebUI.cn(
      'flex flex-col gap-2 border-[0.4px] border-grey-400 p-4 sm:rounded sm:p-6',
      className,
    )}
    {...restProps}
  />
))

// MARK: – DashboardSettingPanelLabel

export const DashboardSettingPanelLabel = React.forwardRef<
  HTMLDivElement,
  NextUI.PanelLabelProps
>((props, forwardedRef) => (
  <NextUI.PanelLabel
    ref={forwardedRef}
    render={<span className="font-medium" />}
    {...props}
  />
))

// MARK: – DashboardSettingContent

export const DashboardSettingContent = React.forwardRef<
  HTMLDivElement,
  NextUI.RoleProps
>(({className, ...restProps}, forwardedRef) => (
  <NextUI.Role
    ref={forwardedRef}
    className={WebUI.cn('flex flex-col gap-4', className)}
    {...restProps}
  />
))
