import * as NextUI from '@cheddarup/web-ui/next'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'

// MARK: – SplitLayout

export interface SplitLayoutProps extends NextUI.RoleProps {
  compactBehavior?: 'stack' | 'hide'
}

export const SplitLayout = React.forwardRef<HTMLDivElement, SplitLayoutProps>(
  ({compactBehavior = 'stack', className, ...restProps}, forwardedRef) => {
    if (compactBehavior === 'hide') {
      throw new Error('`compactBehavior: hide` not supported')
    }

    return (
      <NextUI.Role.div
        ref={forwardedRef}
        data-compact-behavior={compactBehavior}
        className={WebUI.cn(
          'flex flex-row items-start gap-6 data-[compact-behavior=stack]:flex-wrap',
          className,
        )}
        {...restProps}
      />
    )
  },
)

// MARK: – SplitLayoutMain

export interface SplitLayoutMainProps extends NextUI.RoleProps {}

export const SplitLayoutMain = React.forwardRef<
  HTMLDivElement,
  SplitLayoutMainProps
>(({className, ...restProps}, forwardedRef) => (
  <NextUI.Role.div
    ref={forwardedRef}
    className={WebUI.cn('flex grow basis-96 flex-col gap-6', className)}
    {...restProps}
  />
))

// MARK: - DashboardLayout

export const DashboardContentLayout = SplitLayoutMain

// MARK: – SplitLayoutSecondary

export interface SplitLayoutSecondaryProps extends NextUI.RoleProps {}

export const SplitLayoutSecondary = React.forwardRef<
  HTMLDivElement,
  SplitLayoutSecondaryProps
>(({className, ...restProps}, forwardedRef) => (
  <NextUI.Role.div
    ref={forwardedRef}
    className={WebUI.cn('flex basis-[375px] flex-col gap-4', className)}
    {...restProps}
  />
))
