import * as NextUI from '@cheddarup/web-ui/next'
import {useCurrentFundraiserQuery} from '../../hooks'
import {CopyCollectionShareLink} from 'src/views/collection/share/components/InviteOthersPanels'

interface ShareRegistrationLinkModalProps extends NextUI.DialogProps {}

export const ShareRegistrationLinkModal: React.FC<
  ShareRegistrationLinkModalProps
> = (props) => {
  const [{data: fundraiser}] = useCurrentFundraiserQuery()

  return (
    <NextUI.Dialog aria-label="Share Registration Link" {...props}>
      <NextUI.ModalContent className="sm:max-w-[620px]">
        <div className="flex flex-col">
          <div className="m-6">
            <NextUI.Text className="font-extrabold text-ds-lg text-grey-800">
              Share Registration Link
            </NextUI.Text>
            <NextUI.ModalCloseButton className="text-ds-xl" />
          </div>
          <NextUI.Separator />
          <div className="m-6 flex flex-col items-start gap-4">
            <NextUI.Text className="font-extrabold text-ds-md text-grey-800">
              Let participants register themselves
            </NextUI.Text>
            <NextUI.Text className="text-ds-base text-grey-800">
              Copy and share your registration link in any of your
              communications. As participants register, they will be added to
              the participants table.
            </NextUI.Text>
            {fundraiser && <CopyCollectionShareLink collection={fundraiser} />}
          </div>
        </div>
      </NextUI.ModalContent>
    </NextUI.Dialog>
  )
}
