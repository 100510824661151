import * as Yup from 'yup'
import {Checkbox as ReakitCheckbox} from 'reakit'
import {
  CreateTokenBankAccountData,
  StripeAddressElement,
} from '@stripe/stripe-js'
import {useElements, useStripe} from '@stripe/react-stripe-js'
import {useFormik} from '@cheddarup/react-util'
import {useLocation, useParams} from 'react-router-dom'
import {
  api,
  endpoints,
  getEndpointKey,
  useCreatePaymentMethodMutation,
  useQueryClient,
  useUpdateSubscriptionMutation,
  useUpdateUserMutation,
} from '@cheddarup/api-client'
import queryString from 'query-string'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import * as Util from '@cheddarup/util'
import {
  BankAccountFormSection,
  BankAccountSelectRow,
  CreditCardSelectRow,
} from 'src/components'
import {PaymentElement} from 'src/components/Stripe/PaymentElement'
import {
  trackPurchaseSubscriptionEvent,
  trackUserSubscriptionChangeEvent,
} from 'src/helpers/analytics'
import {readApiError} from 'src/helpers/error-formatting'
import {AddressElement} from 'src/components/Stripe/AddressElement'

import CheckoutDiscountCode from './CheckoutDiscountCode'

export interface FieldPanel {
  value: string
  title: React.ReactNode
  description: React.ReactNode
  discountBadge?: React.ReactNode
  price: number
}

export interface UpgradePlanFormProps {
  fieldPanels: FieldPanel[]
  onDidUpdateSubscription: () => void
}

const UpgradePlanForm = ({
  fieldPanels,
  onDidUpdateSubscription,
}: UpgradePlanFormProps) => {
  const location = useLocation()
  const urlParams = useParams()
  const stripe = useStripe()
  const elements = useElements()
  const [discountData, setDiscountData] = useState<any | null>(null)
  const [planDataWithTax, setPlanDataWithTax] =
    useState<Api.BillingDetails | null>(null)
  const [calculatingPlanData, setCalculatingPlanData] = useState(false)
  const [isSavingAddress, setIsSavingAddress] = useState(false)
  const queryClient = useQueryClient()
  const {data: session} = api.auth.session.useQuery()
  const paymentMethodsQuery = api.paymentMethods.list.useQuery()
  const {data: partnerPromoCode} = api.subscriptions.partnerPromoCode.useQuery(
    undefined,
    {
      enabled: !!session?.organization,
      select: (promoCode) => (promoCode.valid ? promoCode : null),
    },
  )
  const createPaymentMethodMutation = useCreatePaymentMethodMutation()
  const updateSubscriptionMutation = useUpdateSubscriptionMutation()
  const updateUserMutation = useUpdateUserMutation()

  const userProfile = session?.user.profile
  const subscriptionEcheckEnabled =
    userProfile?.features?.subscriptionEcheckEnabled
  const growlActions = WebUI.useGrowlActions()
  const addressElementRef = useRef<StripeAddressElement | null>(null)
  const [isAddressDisclosureVisible, setIsAddressDisclosureVisible] = useState(
    !session?.user.personal_address.line1 ||
      !session?.user.personal_address.city ||
      !session?.user.personal_address.state,
  )

  const {banks, cards} = paymentMethodsQuery.data ?? {banks: [], cards: []}

  const queryParams = queryString.parse(location.search)

  const isAffiliateReferred = !!session?.user.profile.affiliateReferrer.id
  const affiliateFullName = `${
    session?.user.profile.affiliateReferrer.firstname || ''
  } ${session?.user.profile.affiliateReferrer.lastname || ''}`
  const currentPlanKey = session?.analytics.sib.subscription
  const partnerPromoCodeByPlanKey: Record<string, any> | null = useMemo(
    () =>
      partnerPromoCode
        ? Util.mapToObj(partnerPromoCode.validPlans, (vp) => [
            vp.stripe_plan_id,
            vp,
          ])
        : null,
    [partnerPromoCode],
  )
  const formik = useFormik({
    initialValues: {
      plan: fieldPanels[0]?.value ?? '',
      creditCardId: cards[0]?.id ?? null,
      bankAccountId: banks[0]?.id ?? null,
      newMethod: cards.length === 0,
      method: 'card',
      coupon: typeof queryParams?.code === 'string' ? queryParams.code : null,
      bankAccount: {
        routingNumber: '',
        accountNumber: '',
        accountNumberRepeat: '',
      },
    },
    validationSchema: Yup.object().shape({
      newMethod: Yup.boolean(),
      bankAccount: Yup.object().when(
        ['newMethod', 'method'],
        ([newMethod, method], schema) =>
          newMethod && method === 'echeck' && subscriptionEcheckEnabled
            ? schema.shape({
                routingNumber: Yup.string().required('Required'),
                accountNumber: Yup.string().required('Required'),
                accountNumberRepeat: Yup.string()
                  .required('Required')
                  .oneOf(
                    [Yup.ref('accountNumber')],
                    "Account numbers don't match",
                  ),
              })
            : schema,
      ),
    }),
    onSubmit: async (values) => {
      try {
        if (!stripe || !elements) {
          return
        }

        let paymentMethodId =
          values.method === 'card' ? values.creditCardId : values.bankAccountId
        if (values.newMethod) {
          if (values.method === 'card') {
            const confirmSetupRes = await stripe.confirmSetup({
              elements,
              confirmParams: {
                return_url: window.location.href,
                payment_method_data: {
                  billing_details: {
                    name: session?.user.full_name,
                    email: session?.user.email,
                    address: {
                      country: session?.user.personal_address.country,
                      postal_code:
                        session?.user.personal_address.postal_code ?? undefined,
                      state: session?.user.personal_address.state ?? undefined,
                      city: session?.user.personal_address.city ?? undefined,
                      line1: session?.user.personal_address.line1 ?? undefined,
                      line2: session?.user.personal_address.line2 ?? '',
                    },
                  },
                },
              },
              redirect: 'if_required',
            })

            if (confirmSetupRes.error) {
              throw new Error(confirmSetupRes.error.message)
            }
            if (
              typeof confirmSetupRes.setupIntent.payment_method === 'string'
            ) {
              paymentMethodId = confirmSetupRes.setupIntent.payment_method
            } else if (confirmSetupRes.setupIntent.payment_method) {
              paymentMethodId = confirmSetupRes.setupIntent.payment_method.id
            }
          } else if (values.method === 'echeck' && subscriptionEcheckEnabled) {
            const createTokenRes = await stripe.createToken('bank_account', {
              country: 'US',
              currency: 'USD',
              account_number: values.bankAccount.accountNumber,
              routing_number: values.bankAccount.routingNumber,
            } as CreateTokenBankAccountData)
            if (createTokenRes.error) {
              throw new Error(createTokenRes.error.message)
            }
            const createdPaymentMethod =
              await createPaymentMethodMutation.mutateAsync({
                body: {source: createTokenRes.token.id},
              })
            paymentMethodId = createdPaymentMethod.id
          }
        }

        if (!paymentMethodId) {
          return
        }

        const couponUsed = values.coupon || discountData?.coupon
        const newPlan = await updateSubscriptionMutation.mutateAsync({
          body: {
            plan: values.plan,
            coupon: couponUsed,
            source_id: paymentMethodId,
          },
        })

        trackUserSubscriptionChangeEvent(newPlan, discountData)
        trackPurchaseSubscriptionEvent(
          discountData,
          newPlan,
          values.method,
          session?.user.currency,
        )

        if (values.plan === 'no_change') {
          growlActions.show('success', {
            body: 'Your payment method was successfully updated',
            title: 'Success',
          })
        } else if (values.plan === 'free') {
          growlActions.show('success', {
            body: "You've been downgraded",
            title: 'Back to Basics',
          })
        } else {
          growlActions.show('success', {
            title: 'Success',
            body: `Your account was successfully changed to ${newPlan.newPlan}`,
          })
        }

        if (urlParams.collection) {
          const tabQueryKey = getEndpointKey(endpoints.tabs.detail, {
            pathParams: {
              tabId: urlParams.collection,
            },
          })
          queryClient.invalidateQueries({queryKey: tabQueryKey})
        }
        onDidUpdateSubscription()
      } catch (err: any) {
        const errorMessage = readApiError(
          err,
          {
            tabs_have_balances:
              'You must withdraw your balance prior to downgrading.',
            invalid_payment_method:
              'eCheck payments are not enabled for your account',
            update_failed:
              'An unknown error occurred while updating your subscription',
            account_already_exists:
              'Looks like you are trying to pay with a bank account that is already saved to your Cheddar Up account. Please click "Use Saved Account" to proceed with this payment method',
          },
          true,
        )
        growlActions.show('error', {
          title: 'Sorry!',
          body: errorMessage,
        })
      }
    },
  })

  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  const selectedPlan = fieldPanels.find(
    (fp) => fp.value === formik.values.plan,
  )!
  const referralCode = isAffiliateReferred
    ? {
        team_monthly: 'AFFILIATETMMO15',
        team_annual: 'AFFILIATETMYR15',
        pro_monthly: 'AFFILIATEPRMO15',
        pro_annual: 'AFFILIATEPRYR15',
      }[formik.values.plan]
    : undefined

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const calculateTaxes = async () => {
      setCalculatingPlanData(true)
      const data = await api.subscriptions.calculateWithTax.fetch({
        body: {
          plan: formik.values.plan,
          coupon:
            discountData?.coupon ||
            (partnerPromoCodeByPlanKey?.[formik.values.plan]
              ? partnerPromoCode?.code
              : undefined),
        },
      })
      setCalculatingPlanData(false)
      setPlanDataWithTax(data)
      if (!data?.coupon_discount) {
        setDiscountData(null)
      }
    }
    calculateTaxes()
  }, [
    discountData,
    formik.values.plan,
    session?.user.personal_address,
    partnerPromoCodeByPlanKey,
    partnerPromoCode,
  ])

  console.log({fieldPanels, partnerPromoCode}, formik.values.plan)
  return (
    <WebUI.Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <WebUI.Panel className="flex flex-col gap-4 p-5">
        {partnerPromoCode && (
          <NextUI.Text className="font-normal">
            <span className="font-extrabold text-orange-500">
              Partner Discount Alert!
            </span>{' '}
            {partnerPromoCode.label}{' '}
            <span className="font-extrabold">
              {partnerPromoCode.description}
            </span>{' '}
            at checkout
          </NextUI.Text>
        )}
        <WebUI.RadioGroup
          aria-label="Subscription plan"
          className="gap-4"
          name="plan"
          size="large"
          state={formik.values.plan}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          {(radio) => (
            <>
              {fieldPanels.map((fp) => (
                <WebUI.VStack
                  key={fp.value}
                  className="gap-4 overflow-hidden rounded bg-gray100 p-4 text-gray800 transition-colors duration-100 ease-in-out [&:has(>_.Radio_>_input[aria-checked=true])]:bg-depr-aqua-100"
                  role="radio"
                  onClick={() => {
                    if (fp.value !== currentPlanKey) {
                      radio.setState(fp.value)
                    }
                  }}
                >
                  <WebUI.Radio
                    className="gap-4"
                    value={fp.value}
                    disabled={fp.value === currentPlanKey}
                  >
                    <div className="flex flex-col">
                      <WebUI.Text className="text-ds-md leading-compact">
                        {fp.title}
                      </WebUI.Text>
                      <WebUI.Text className="text-ds-sm">
                        {fp.description}
                      </WebUI.Text>
                      {partnerPromoCodeByPlanKey?.[fp.value] && (
                        <span className="text-ds-sm text-orange-500">
                          {partnerPromoCode?.percentage} off will be applied at
                          checkout
                        </span>
                      )}
                    </div>
                  </WebUI.Radio>
                  {fp.value === formik.values.plan && fp.discountBadge}
                </WebUI.VStack>
              ))}
            </>
          )}
        </WebUI.RadioGroup>
      </WebUI.Panel>

      <WebUI.Panel className="flex min-w-[320px] max-w-full flex-col gap-4 p-5">
        <WebUI.Text className="text-ds-md">
          Please provide an address for your account
        </WebUI.Text>

        <WebUI.Disclosure
          initialVisible={
            !session?.user.personal_address.line1 ||
            !session?.user.personal_address.city ||
            !session?.user.personal_address.state
          }
          onVisibleChange={(newVisible) =>
            setIsAddressDisclosureVisible(newVisible)
          }
        >
          {(disclosure) => (
            <WebUI.VStack className="gap-2">
              {session?.user.personal_address.line1 &&
                session?.user.personal_address.city &&
                session?.user.personal_address.state &&
                !disclosure.visible && (
                  <WebUI.Text className="text-ds-sm">
                    {[
                      session.user.personal_address.line1,
                      session.user.personal_address.city,
                      `${session.user.personal_address.state ?? ''} ${
                        session.user.personal_address.postal_code ?? ''
                      }`,
                      session.user.personal_address.country,
                    ]
                      .filter(
                        (str) => typeof str === 'string' && str.length > 0,
                      )
                      .join(', ')}
                  </WebUI.Text>
                )}
              {!disclosure.visible && (
                <WebUI.DisclosureButton
                  className="text-ds-sm"
                  iconBefore={null}
                  variant="link"
                >
                  Change
                </WebUI.DisclosureButton>
              )}
              <WebUI.DisclosureContent>
                <WebUI.VStack className="gap-4">
                  <AddressElement
                    options={{
                      mode: 'billing',
                      fields: {
                        phone: 'never',
                      },
                      defaultValues: {
                        name: session?.user.full_name,
                        address: {
                          country:
                            session?.user.currency === 'usd' ? 'US' : 'CA',
                        },
                      },
                    }}
                    onReady={(addressElementInstance) => {
                      addressElementRef.current = addressElementInstance
                    }}
                  />

                  <WebUI.HStack className="gap-3">
                    <WebUI.Button
                      className="self-start"
                      loading={isSavingAddress}
                      onClick={async () => {
                        if (!stripe || !addressElementRef.current) {
                          return
                        }

                        const res = await addressElementRef.current.getValue()

                        if (!res.complete) {
                          return
                        }

                        try {
                          setIsSavingAddress(true)

                          const personTokenRes = await stripe.createToken(
                            'person',
                            {
                              address: {
                                ...res.value.address,
                                line2: res.value.address.line2 ?? undefined,
                              },
                            },
                          )
                          if (personTokenRes.error) {
                            throw new Error(personTokenRes.error.message)
                          }

                          await updateUserMutation.mutateAsync({
                            body: {
                              person_token: personTokenRes.token.id,
                            },
                          })
                          disclosure.hide()
                        } catch (err: any) {
                          growlActions.show('error', {
                            title: 'Error',
                            body: err.message ?? 'Something went wrong',
                          })
                        } finally {
                          setIsSavingAddress(false)
                        }
                      }}
                    >
                      Save
                    </WebUI.Button>
                    <WebUI.Button
                      variant="secondary"
                      onClick={() => disclosure.hide()}
                    >
                      Cancel
                    </WebUI.Button>
                  </WebUI.HStack>
                </WebUI.VStack>
              </WebUI.DisclosureContent>
            </WebUI.VStack>
          )}
        </WebUI.Disclosure>
      </WebUI.Panel>

      <WebUI.Panel className="flex min-w-[320px] max-w-full flex-col gap-2 p-5 *:flex-[1_0_auto] sm:flex-row *:sm:flex-[1_0_0px]">
        <WebUI.Disclosure
          visible={
            !!session?.user.personal_address.line1 &&
            !isAddressDisclosureVisible
          }
        >
          {(disclosure) => (
            <>
              {!disclosure.visible && (
                <WebUI.Text className="text-ds-md text-gray400">
                  Payment Method
                </WebUI.Text>
              )}

              <WebUI.DisclosureContent className="max-w-xl">
                <div className="flex flex-col gap-4">
                  <WebUI.Text className="text-ds-md">Payment Method</WebUI.Text>
                  {subscriptionEcheckEnabled && (
                    <WebUI.RadioGroup
                      className="flex-row"
                      name="method"
                      aria-label="Payment method"
                      state={formik.values.method}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <WebUI.Radio value="card">Credit Card</WebUI.Radio>
                      <WebUI.Radio value="echeck">eCheck</WebUI.Radio>
                    </WebUI.RadioGroup>
                  )}

                  <WebUI.Text className="font-light text-ds-sm">
                    Your payment method will be saved and your subscription will
                    auto renew at the end of the term. Cancel anytime from My
                    Account.
                  </WebUI.Text>

                  {formik.values.method === 'card' ? (
                    <>
                      {formik.values.newMethod ? (
                        <PaymentElement
                          options={{
                            defaultValues: {
                              billingDetails: {
                                name: session?.user.full_name,
                                email: session?.user.email,
                                address: {
                                  country:
                                    session?.user.personal_address.country,
                                  postal_code:
                                    session?.user.personal_address
                                      .postal_code ?? undefined,
                                  state:
                                    session?.user.personal_address.state ??
                                    undefined,
                                  city:
                                    session?.user.personal_address.city ??
                                    undefined,
                                  line1:
                                    session?.user.personal_address.line1 ?? '',
                                  line2:
                                    session?.user.personal_address.line2 ??
                                    undefined,
                                },
                              },
                            },
                            fields: {
                              billingDetails: {
                                name: 'never',
                                email: 'never',
                              },
                            },
                          }}
                        />
                      ) : (
                        <WebUI.RadioGroup
                          name="creditCardId"
                          aria-label="Credit card select"
                          state={formik.values.creditCardId as any}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          {cards.map((card) => (
                            <CreditCardSelectRow
                              key={card.id}
                              value={card.id}
                              as={WebUI.Radio}
                              creditCard={card}
                            />
                          ))}
                        </WebUI.RadioGroup>
                      )}
                    </>
                  ) : (
                    <>
                      {banks.length === 0 || formik.values.newMethod ? (
                        <BankAccountFormSection formik={formik as any} />
                      ) : (
                        <WebUI.RadioGroup
                          name="bankAccountId"
                          aria-label="Bank account select"
                          state={formik.values.bankAccountId as any}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          {banks.map((bank) => (
                            <BankAccountSelectRow
                              key={bank.id}
                              value={bank.id}
                              as={WebUI.Radio}
                              bankAccount={bank}
                            />
                          ))}
                        </WebUI.RadioGroup>
                      )}
                    </>
                  )}

                  {(formik.values.method === 'card' ? cards : banks).length >
                    0 && (
                    <ReakitCheckbox
                      className="text-ds-sm"
                      as={WebUI.Button}
                      variant="link"
                      name="newMethod"
                      checked={formik.values.newMethod}
                      onChange={(event: any) =>
                        formik.setFieldValue('newMethod', event.target.checked)
                      }
                      onBlur={formik.handleBlur}
                    >
                      {formik.values.newMethod
                        ? `Use Saved ${
                            formik.values.method === 'card' ? 'Card' : 'Account'
                          }`
                        : `Use New ${
                            formik.values.method === 'card' ? 'Card' : 'Account'
                          }`}
                    </ReakitCheckbox>
                  )}

                  {!partnerPromoCode && (
                    <CheckoutDiscountCode
                      initialCode={
                        typeof queryParams?.code === 'string'
                          ? queryParams?.code
                          : undefined
                      }
                      plan={formik.values.plan}
                      onDidApplyDiscount={setDiscountData}
                      referralCode={referralCode}
                      withSeparator={false}
                    />
                  )}
                </div>
              </WebUI.DisclosureContent>
            </>
          )}
        </WebUI.Disclosure>
      </WebUI.Panel>

      {calculatingPlanData ? (
        <WebUI.Skeleton className="h-24" />
      ) : planDataWithTax ? (
        <WebUI.Panel className="p-5">
          <div className="flex max-w-xl flex-col gap-5">
            {partnerPromoCodeByPlanKey?.[formik.values.plan] && (
              <div className="flex flex-col gap-1">
                <span className="text-ds-sm">
                  {selectedPlan.title} Plan:{' '}
                  {Util.formatAmount(selectedPlan.price)}{' '}
                </span>
                <span className="font-extrabold">
                  {partnerPromoCode?.percentage} OFF PARTNER DISCOUNT (-
                  {Util.formatAmount(partnerPromoCode?.discount ?? 0)})*
                </span>
                <WebUI.Separator />
              </div>
            )}
            {planDataWithTax.proration_details.current_plan &&
              planDataWithTax.proration_details.new_plan && (
                <WebUI.Text className="text-ds-sm">
                  Thank you for upgrading to{' '}
                  {
                    PLAN_KEY_TO_NAME_MAP[
                      planDataWithTax.proration_details.new_plan
                    ]
                  }
                  . A credit for your remaining time on{' '}
                  {
                    PLAN_KEY_TO_NAME_MAP[
                      planDataWithTax.proration_details.current_plan
                    ]
                  }{' '}
                  has been applied to your bill. Your next charge will be on{' '}
                  {planDataWithTax.proration_details.next_billing_date} for{' '}
                  {Util.formatAmount(planDataWithTax.plan_cost / 100)}.
                </WebUI.Text>
              )}
            <div className="flex flex-col gap-3 pb-2 [&_>_div]:justify-between">
              <div className="flex [&_.Text]:font-light [&_.Text]:text-ds-sm">
                <WebUI.Text>
                  {planDataWithTax.proration_details.current_plan
                    ? 'Pay Now'
                    : 'Subtotal'}
                  :
                </WebUI.Text>
                <WebUI.Text>
                  {Util.formatAmount(planDataWithTax.subtotal / 100)}
                </WebUI.Text>
              </div>
              <div className="flex [&_.Text]:font-light [&_.Text]:text-ds-sm">
                <WebUI.Text>Sales Tax:</WebUI.Text>
                <WebUI.Text>
                  {Util.formatAmount(planDataWithTax.total_tax / 100)}
                </WebUI.Text>
              </div>
              {discountData && (
                <div className="flex">
                  <WebUI.Text>{discountData.coupon}</WebUI.Text>
                  <WebUI.Text variant="subtle">
                    -{Util.formatAmount(discountData.discount)}
                  </WebUI.Text>
                </div>
              )}
              <WebUI.Separator className="my-1" />
              <div className="flex">
                <WebUI.Text className="font-normal">Total:</WebUI.Text>
                <WebUI.Text className="font-bold">
                  {Util.formatAmount(planDataWithTax.total / 100)}
                </WebUI.Text>
              </div>

              {discountData && (
                <WebUI.Text className="font-light text-ds-sm italic">
                  {isAffiliateReferred && referralCode
                    ? `You're saving 15% thanks to ${affiliateFullName.trim()}'s referral!`
                    : discountData.description}
                </WebUI.Text>
              )}
            </div>
            {partnerPromoCodeByPlanKey?.[formik.values.plan] &&
              selectedPlan && (
                <span className="text-ds-sm italic">
                  {partnerPromoCode?.duration === 'forever'
                    ? '*This discount lasts as long as your subscription does!'
                    : {
                        pro_monthy:
                          '*One-time discount on your monthly subscription, available for the first month.',
                        team_monthy:
                          '*One-time discount on your monthly subscription, available for the first month.',
                        pro_annual:
                          '*One-time discount on your annual subscription, available for the first year.',
                        team_annual:
                          '*One-time discount on your annual subscription, available for the first year.',
                      }[selectedPlan.value]}
                </span>
              )}
            <WebUI.Button
              type="submit"
              size="large"
              variant="primary"
              loading={formik.isSubmitting}
            >
              Pay Now
            </WebUI.Button>
          </div>
        </WebUI.Panel>
      ) : null}
    </WebUI.Form>
  )
}

// Mark: - Helpers

const PLAN_KEY_TO_NAME_MAP = {
  pro_monthly: 'Pro Monthly',
  pro_annual: 'Pro Annual',
  team_monthly: 'Team Monthly',
  team_annual: 'Team Annual',
}

export default UpgradePlanForm
