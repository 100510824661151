import {Icon, IconProps} from '@iconify/react'
import React from 'react'
import type phIcons from '@iconify-json/ph/icons.json'

import {cn} from '../utils'

export type PhosphorIconName = keyof (typeof phIcons)['icons']

export interface PhosphorIconProps extends IconProps {
  icon: PhosphorIconName
}

export const PhosphorIcon = React.forwardRef<SVGSVGElement, PhosphorIconProps>(
  ({icon, className, ...restProps}, forwardedRef) => {
    return (
      <Icon
        ref={forwardedRef as any}
        className={cn(
          'PhosphorIcon',
          `PhosphorIcon-${icon}`,
          'shrink-0',
          className,
        )}
        icon={`ph:${icon}`}
        {...restProps}
      />
    )
  },
)
