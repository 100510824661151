import React, {useRef} from 'react'
import {DateValue, useDatePicker} from 'react-aria'
import {DatePickerStateOptions, useDatePickerState} from 'react-stately'
import {SimpleMerge} from '@cheddarup/util'
import {genericForwardRef} from '@cheddarup/react-util'

import type {InputBoxProps} from './Input'
import {NextPopover, NextPopoverContent, NextPopoverDisclosure} from './Popover'
import {DateInput} from './DateInput'
import {PhosphorIcon} from '../../icons'
import {Calendar} from './Calendar'
import {NextButton} from './Button'

export interface DatePickerProps<T extends DateValue>
  extends Omit<
    SimpleMerge<InputBoxProps, Omit<DatePickerStateOptions<T>, 'isInvalid'>>,
    'createCalendar' | 'onChange'
  > {
  contentClassName?: string
  onValueChange?: DatePickerStateOptions<T>['onChange']
}

export const DatePicker = genericForwardRef(
  <T extends DateValue>(
    {
      'aria-invalid': ariaInvalid,
      size,
      variant,
      onValueChange,
      contentClassName,
      className,
      style,
      ...restProps
    }: DatePickerProps<T>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const disclosureRef = useRef<HTMLButtonElement>(null)
    const state = useDatePickerState({
      onChange: onValueChange,
      ...restProps,
    })
    const {
      fieldProps,
      groupProps,
      buttonProps: {isDisabled: buttonDisabled, onPress, ...buttonProps},
      dialogProps,
      calendarProps,
    } = useDatePicker(
      {'aria-label': 'Date picker', ...restProps},
      state,
      disclosureRef,
    )

    return (
      <NextPopover
        open={state.isOpen}
        setOpen={state.setOpen}
        placement="bottom"
      >
        <DateInput
          ref={forwardedRef}
          aria-invalid={ariaInvalid}
          className={className}
          style={style}
          size={size}
          variant={variant}
          {...fieldProps}
        >
          {(dateSegments) => (
            <div className="contents" {...groupProps}>
              <NextPopoverDisclosure
                ref={disclosureRef}
                className="text-ds-lg"
                disabled={buttonDisabled}
                render={<NextButton size="headless" variant="transparent" />}
                {...buttonProps}
                onClick={(event) => {
                  event.preventDefault()

                  state.toggle()
                }}
                onKeyDown={(event) => {
                  event.preventDefault()

                  if (
                    event.key === ' ' ||
                    event.key === 'Space' ||
                    event.key === 'Enter'
                  ) {
                    state.toggle()
                  }
                }}
              >
                <PhosphorIcon icon="calendar-dots" />
              </NextPopoverDisclosure>

              <div className="inline-flex flex-row">{dateSegments}</div>
            </div>
          )}
        </DateInput>

        <NextPopoverContent
          aria-label="Calendar"
          className={contentClassName}
          gutter={16}
          portal
          {...dialogProps}
        >
          <Calendar
            {...calendarProps}
            onValueChange={(newValue) => {
              calendarProps.onChange?.(newValue)
              state.close()
            }}
          />
        </NextPopoverContent>
      </NextPopover>
    )
  },
)
