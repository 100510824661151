import * as WebUI from '@cheddarup/web-ui'
import React, {useState} from 'react'
import {
  AlbumImagePickerForm,
  usePartnerAlbumsQuery,
} from 'src/components/AlbumImagePickerForm'
import {
  UploadPinturaImageForm,
  UploadPinturaImageFormProps,
} from 'src/components/UploadPinturaImageForm'
import GroupPageTopBannerPlaceholder from 'src/images/GroupPageTopBannerPlaceholder.jpg'

export interface TopBannerBackgroundImagePickerModalProps
  extends WebUI.ModalProps,
    Pick<
      TopBannerBackgroundImagePickerProps,
      'initialImage' | 'onImageSubmit'
    > {}

export const TopBannerBackgroundImagePickerModal = React.forwardRef<
  WebUI.DialogInstance,
  TopBannerBackgroundImagePickerModalProps
>(
  (
    {
      initialImage,
      onImageSubmit,
      initialVisible = false,
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <WebUI.Modal
      ref={forwardedRef}
      aria-label="Image picker"
      className={WebUI.cn('[&_>.ModalContentView]:h-full', className)}
      initialVisible={initialVisible}
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />

          <WebUI.ModalHeader>Add a Background Image</WebUI.ModalHeader>

          <TopBannerBackgroundImagePicker
            className="min-h-0 grow"
            initialImage={initialImage}
            onImageSubmit={(newImage) => {
              onImageSubmit?.(newImage)
              dialog.hide()
            }}
          />
        </>
      )}
    </WebUI.Modal>
  ),
)

// MARK: – TopBannerBackgroundImagePicker

export interface TopBannerBackgroundImagePickerProps
  extends React.ComponentPropsWithoutRef<'div'> {
  initialImage?: UploadPinturaImageFormProps['initialImage']
  onImageSubmit?: UploadPinturaImageFormProps['onSubmitAsPinturaRes']
}

export const TopBannerBackgroundImagePicker = ({
  initialImage,
  onImageSubmit,
  className,
  ...restProps
}: TopBannerBackgroundImagePickerProps) => {
  const media = WebUI.useMedia()
  const albumsQuery = usePartnerAlbumsQuery()
  const [selectedImage, setSelectedImage] = useState<
    UploadPinturaImageFormProps['initialImage']
  >(initialImage ?? null)

  return (
    <WebUI.Tabs
      className={WebUI.cn('flex-col divide-y', className)}
      orientation={media.lg ? 'vertical' : 'horizontal'}
      {...restProps}
    >
      {(tabs) => (
        <div className="flex min-h-0 grow flex-col divide-y lg:flex-row lg:divide-x lg:divide-y-0">
          <WebUI.TabList
            aria-label="Navigation"
            className="!border-r-0 !border-b-0 min-w-[256px] flex-0 overflow-x-auto lg:overflow-hidden"
          >
            <WebUI.Tab id="upload-image">Upload Image</WebUI.Tab>
            {albumsQuery.data?.map((p) => (
              <WebUI.Tab key={p.key} id={p.key}>
                {p.name}
              </WebUI.Tab>
            ))}
          </WebUI.TabList>

          <WebUI.TabPanel
            className="flex min-h-0 grow flex-col gap-8 overflow-y-auto"
            tabId="upload-image"
          >
            <WebUI.Well className="mx-8 mt-8 flex flex-0 flex-col gap-3 text-ds-sm">
              <span className="font-normal">
                <span className="font-bold text-orange-500">Pro Tip:</span> Your
                background image will be cropped depending on your viewer’s
                browser size and device. Here are some guidelines:
              </span>

              <ul className="list-inside list-disc">
                <li>Use images with abstract patterns and without borders</li>
                <li>Use images between 1500 pixels and 2500 pixels wide</li>
                <li>Ensure images are wider than they are tall</li>
                <li>
                  Don’t use images with text in the actual image file. Instead,
                  add a headline block for text.
                </li>
              </ul>
            </WebUI.Well>

            <UploadPinturaImageForm
              className="w-full grow"
              imageCropAspectRatio={3 / 1}
              utils={['crop', 'filter', 'finetune']}
              placeholderSrc={GroupPageTopBannerPlaceholder}
              initialImage={initialImage}
              image={selectedImage}
              onSubmitAsPinturaRes={onImageSubmit}
            />
          </WebUI.TabPanel>

          {albumsQuery.data?.map((p) => (
            <WebUI.TabPanel key={p.key} className="min-h-0 grow" tabId={p.key}>
              <AlbumImagePickerForm
                className="h-full"
                images={p.images}
                onSubmit={(selectedImageId) => {
                  const selectedImage = p.images.find(
                    (i) => i.id === selectedImageId,
                  )
                  if (selectedImage) {
                    setSelectedImage(selectedImage)
                    tabs.setSelectedId('upload-image')
                  }
                }}
              />
            </WebUI.TabPanel>
          ))}
        </div>
      )}
    </WebUI.Tabs>
  )
}
