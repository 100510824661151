import * as Yup from 'yup'
import * as Util from '@cheddarup/util'
import {useFormik} from '@cheddarup/react-util'
import React from 'react'
import {
  api,
  endpoints,
  getEndpointKey,
  useCloneTabSignupMutation,
  useMoveFormsMutation,
  useMoveItemsMutation,
  useQueryClient,
} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {useUndoableMutation} from 'src/hooks/useUndoableMutation'

import {CollectionCombobox} from './CollectionCombobox'

type ObjectType = 'item' | 'form' | 'signup'

export interface MoveTabObjectsToAnotherCollectionOrCategoryModalProps
  extends WebUI.ModalProps {
  objectType: ObjectType
  selectedItems?: Api.TabItem[]
  collectionId: number
  tabObjectIds: number[]
  onDidMove?: () => void
}

export const MoveTabObjectsToAnotherCollectionOrCategoryModal =
  React.forwardRef<
    WebUI.DialogInstance,
    MoveTabObjectsToAnotherCollectionOrCategoryModalProps
  >(
    (
      {
        objectType,
        collectionId,
        tabObjectIds,
        onDidMove,
        initialVisible = false,
        selectedItems = [],
        className,
        ...restProps
      },
      forwardedRef,
    ) => (
      <WebUI.Modal
        ref={forwardedRef}
        aria-label={`Move ${objectType}s to another collection`}
        className={WebUI.cn(
          '[&_>_.ModalContentView]:max-w-[490px] [&_>_.ModalContentView]:rounded-large [&_>_.ModalContentView]:p-9',
          className,
        )}
        initialVisible={initialVisible}
        {...restProps}
      >
        {(dialog) => (
          <>
            <WebUI.ModalCloseButton />
            <WebUI.VStack className="gap-6">
              <WebUI.Heading as="h2">
                Move {Util.capitalize(objectType)}(s)
              </WebUI.Heading>
              <WebUI.Text className="font-light text-ds-sm">
                {objectType === 'item'
                  ? 'Move items to a category or copy items to another collection.'
                  : `Move ${objectType}(s) to another collection by selecting a
              destination below. (Note that these ${objectType}(s) will remain on
              your existing collection until you delete them.)`}
              </WebUI.Text>
              <MoveTabObjectsToAnotherCollectionOrCategoryForm
                objectType={objectType}
                collectionId={collectionId}
                tabObjectIds={tabObjectIds}
                selectedItems={selectedItems}
                onDidSave={() => {
                  onDidMove?.()
                  dialog.hide()
                }}
              />
            </WebUI.VStack>
          </>
        )}
      </WebUI.Modal>
    ),
  )

// MARK: – MoveTabObjectsToAnotherCollectionOrCategoryForm

interface MoveTabObjectsToAnotherCollectionOrCategoryFormProps
  extends WebUI.FormProps,
    Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  objectType: ObjectType
  selectedItems?: Api.TabItem[]
  collectionId: number
  tabObjectIds: number[]
  onDidSave?: () => void
}

const MoveTabObjectsToAnotherCollectionOrCategoryForm = ({
  objectType,
  collectionId,
  tabObjectIds,
  selectedItems = [],
  onDidSave,
  className,
  ...restProps
}: MoveTabObjectsToAnotherCollectionOrCategoryFormProps) => {
  const {data: categories} = api.tabCategories.list.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const collectionsQuery = api.tabs.list.useQuery(undefined, {
    select: (collections) => collections.filter(({id}) => id !== collectionId),
  })
  const moveItemsUndoableMutation = useUndoableMutation(
    useMoveItemsMutation({timeout: 8000}),
  )
  const moveFormsMutation = useMoveFormsMutation()
  const cloneTabSignupMutation = useCloneTabSignupMutation()
  const growlActions = WebUI.useGrowlActions()
  const queryClient = useQueryClient()

  const formik = useFormik<{
    collection_id: number | null
    categoryId: number | string
    include_categories: boolean
  }>({
    validationSchema: Yup.object().shape({
      categoryId: Yup.mixed().required('Required'),
      collection_id: Yup.number().when(
        ['categoryId'],
        ([categoryId], schema) =>
          categoryId === 'another_collection'
            ? schema.typeError('Required').required('Required')
            : schema.nullable(),
      ),
    }),
    initialValues: {
      collection_id: null,
      include_categories: false,
      categoryId: objectType !== 'item' ? 'another_collection' : '',
    },
    onSubmit: async (values) => {
      if (values.categoryId === 'another_collection' && values.collection_id) {
        if (objectType === 'signup') {
          const signUpId = tabObjectIds[0]
          if (signUpId) {
            await cloneTabSignupMutation.mutateAsync({
              pathParams: {
                tabId: collectionId,
                signupId: signUpId,
              },
              body: {
                new_tab_id: values.collection_id,
              },
            })
          }
        } else if (objectType === 'item') {
          moveItemsUndoableMutation.mutateWithUndo(
            {
              description: 'Your items have been moved.',
            },
            {
              pathParams: {
                tabId: collectionId,
              },
              body: {
                collection_id: values.collection_id,
                tab_item_ids: tabObjectIds,
                include_categories: values.include_categories,
              },
            },
          )
        } else if (objectType === 'form') {
          await moveFormsMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
            },
            body: {
              collection_id: values.collection_id,
              tab_item_ids: tabObjectIds,
              include_categories: values.include_categories,
            },
          })
        }
      } else {
        const categoryItemsCount =
          categories?.find((c) => c.id === values.categoryId)?.items?.length ??
          0

        await api.misc.batch.fetch({
          body: {
            sequential: true,
            ops: Util.sort(selectedItems)
              .asc([
                (i) => {
                  const itemCategoryPosition = categories?.find(
                    (c) => c.id === i.category?.id,
                  )?.position
                  return itemCategoryPosition ?? Number.POSITIVE_INFINITY
                },
                (i) => i.position,
              ])
              .map((i, idx) => ({
                method: 'patch',
                url: `/api/users/tabs/${collectionId}/items/${i.id}`,
                params: {
                  parent_id: values.categoryId,
                  position: categoryItemsCount + idx,
                },
              })),
          },
        })

        const tabItemListQueryKey = getEndpointKey(endpoints.tabItems.list, {
          pathParams: {
            tabId: collectionId,
          },
        })
        const categoryListQueryKey = getEndpointKey(
          endpoints.tabCategories.list,
          {
            pathParams: {
              tabId: collectionId,
            },
          },
        )

        queryClient.invalidateQueries({queryKey: tabItemListQueryKey})
        queryClient.invalidateQueries({queryKey: categoryListQueryKey})
      }

      onDidSave?.()

      if (objectType !== 'item') {
        growlActions.show('success', {
          title: `Success! Your ${objectType}(s) has been moved.`,
        })
      }
    },
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      {...restProps}
    >
      <WebUI.VStack className="grow gap-6 overflow-y-auto">
        <WebUI.VStack className="gap-3">
          {objectType === 'item' && (
            <WebUI.FormField error={formik.errors.categoryId}>
              <WebUI.DropdownSelect
                name="categoryId"
                placeholder="Select Destination"
                size="compact"
                value={formik.values.categoryId}
                onValueChange={(newcategoryId) =>
                  formik.setFieldValue('categoryId', newcategoryId)
                }
              >
                {categories?.map((c) => (
                  <WebUI.DropdownSelectOption key={c.id} value={c.id}>
                    {c.name || 'Default'}
                  </WebUI.DropdownSelectOption>
                ))}
                <WebUI.DropdownSelectOption
                  value="another_collection"
                  variant="link"
                >
                  Copy to Another Collection
                </WebUI.DropdownSelectOption>
              </WebUI.DropdownSelect>
            </WebUI.FormField>
          )}
          {(objectType !== 'item' ||
            formik.values.categoryId === 'another_collection') && (
            <WebUI.FormField error={formik.errors.collection_id}>
              <CollectionCombobox
                placeholder="Collection Name"
                popoverClassName="[&_>_.PopoverContent-inner_.PopoverContent-body_.ComboboxList-wrapper_.ComboboxList_.ComboboxOption]:text-ds-sm [&_>_.PopoverContent-inner_.PopoverContent-body]:py-4"
                collections={collectionsQuery.data ?? []}
                selectedCollectionId={formik.values.collection_id}
                onSelectedCollectionIdChange={(newCollectionId) =>
                  formik.setFieldValue('collection_id', newCollectionId)
                }
              />
            </WebUI.FormField>
          )}
        </WebUI.VStack>
        {objectType === 'item' &&
          formik.values.categoryId === 'another_collection' && (
            <WebUI.Checkbox
              name="include_categories"
              size="compact"
              checked={formik.values.include_categories}
              onChange={(event) =>
                formik.setFieldValue('include_categories', event.target.checked)
              }
            >
              Include categories if applicable
            </WebUI.Checkbox>
          )}
        <WebUI.Button
          className="self-start px-6"
          type="submit"
          loading={formik.isSubmitting}
        >
          Move
        </WebUI.Button>
      </WebUI.VStack>
    </form>
  )
}
