import React, {useContext, useMemo} from 'react'
import {unparseFieldGroups, type TransientField} from '@cheddarup/core'

import {FieldSetList} from '../FieldSetList'
import {FieldsEditInnerContext} from './FieldsEdit'

export interface FieldGroupsPreviewProps {
  fields?: Array<TransientField | Api.TabObjectField>
  fieldSets?: Api.FieldSet[]
}

export const FieldGroupsPreview: React.FC<FieldGroupsPreviewProps> = ({
  fields: fieldsProp,
  fieldSets: fieldSetsProp,
}) => {
  const contextValue = useContext(FieldsEditInnerContext)

  const fieldGroups = useMemo(
    () =>
      fieldsProp
        ? []
        : unparseFieldGroups({
            fieldGroups: contextValue.fieldGroups,
            fields: [],
          }),
    [fieldsProp, contextValue.fieldGroups],
  )

  const fieldSets = useMemo(
    () => fieldSetsProp ?? fieldGroups.map((fg) => fg.fieldSet),
    [fieldGroups, fieldSetsProp],
  )
  const fields = useMemo(
    () => fieldsProp ?? fieldGroups.flatMap((fg) => fg.fields),
    [fieldGroups, fieldsProp],
  )

  return (
    <FieldSetList
      fieldSets={fieldSets}
      fields={fields as any}
      defaultValues={{}}
      onValuesChange={() => {}}
    />
  )
}
