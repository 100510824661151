import * as WebUI from '@cheddarup/web-ui'
import React, {useEffect} from 'react'
import {useLocation, useResolvedPath} from 'react-router-dom'
import {NextNavLinkButton, NextNavLinkButtonProps} from './__next/LinkButton'

export interface NavListItemNavLinkProps extends NextNavLinkButtonProps {}

export const NavListItemNavLink = React.forwardRef<
  HTMLAnchorElement,
  NavListItemNavLinkProps
>((props, forwardedRef) => {
  const navListSubNav = WebUI.useNavListSubNav()
  const locationPathname = useLocation().pathname
  const toPathname = useResolvedPath(props.to, {
    relative: props.relative,
  }).pathname

  const isActive = locationPathname === toPathname

  useEffect(() => {
    if (isActive) {
      navListSubNav?.setVisible(true)
    }
  }, [isActive, navListSubNav?.setVisible])

  return (
    <WebUI.NavListItemAnchor
      render={
        <NextNavLinkButton
          ref={forwardedRef}
          variant="headless"
          size="headless"
          {...props}
        />
      }
    />
  )
})
