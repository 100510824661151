import React from 'react'
import * as NextUI from '@cheddarup/web-ui/next'
import {RecipientsModal} from 'src/components/RecipientsModal/RecipientsModal'
import {useCreateParticipantsMutation} from '@cheddarup/api-client'
import {useCurrentFundraiserId} from '../../hooks'
import {useGrowlActions} from '@cheddarup/web-ui'

export const AddParticipantsModal = React.forwardRef<
  NextUI.DialogInstance,
  NextUI.DialogProps
>((props, forwardedRef) => {
  const growlActions = useGrowlActions()
  const fundraiserId = Number(useCurrentFundraiserId())
  const createParticipantsMutation = useCreateParticipantsMutation()

  return (
    <RecipientsModal
      ref={forwardedRef}
      aria-label="Add Participants"
      contactType="Participant"
      contactTabsOrder={[
        'add-manually',
        'upload-file',
        'copy-paste',
        'my-contacts',
      ]}
      // enforceLastName
      description="Add participants by selecting contacts in your address book, uploading a csv file, or manually adding their information"
      initialRecipients={[]}
      onRecipientsSave={async (newRecipients) => {
        const newParticipants = await createParticipantsMutation.mutateAsync({
          pathParams: {fundraiserId},
          body: {
            participants: newRecipients.map((rec) => {
              const [first_name = '', last_name = ''] = rec.name.split(' ')
              return {
                first_name,
                last_name,
                email: rec.email,
              }
            }),
          },
        })
        growlActions.show(
          newParticipants.results.failed.length > 0 ? 'error' : 'success',
          {
            title: newParticipants.message,
          },
        )
      }}
      {...props}
    />
  )
})
