import {Outlet} from 'react-router-dom'
import * as NextUI from '@cheddarup/web-ui/next'
import {
  DashboardPageContainer,
  DashboardPageContent,
  DashboardPageHeader,
  DashboardPageHeading,
} from 'src/components/DashboardPageLayout'
import {DashboardContentLayout} from 'src/components/Layout'
import {
  RoutableTab,
  RoutableTabPanel,
  RoutableTabs,
} from 'src/components/__next/RoutableTabs'

export const FundraiserParticipantsPage = () => {
  return (
    <DashboardPageContainer>
      <DashboardPageContent>
        <DashboardPageHeader>
          <DashboardPageHeading>Participants</DashboardPageHeading>
          <NextUI.Text>
            Add participants below to automatically create participant
            fundraising pages. After adding your participants, send them an
            invitation, which will include links to view and edit their
            fundraising page. Participants can also be grouped into teams.{' '}
          </NextUI.Text>
        </DashboardPageHeader>
        <DashboardContentLayout>
          <RoutableTabs>
            <div className="flex grow flex-col gap-4">
              <NextUI.TabList aria-label="Navigation">
                <RoutableTab className="p-2" to="index">
                  Participants
                </RoutableTab>
                <RoutableTab className="p-2" to="teams">
                  Teams
                </RoutableTab>
                <NextUI.SelectedTabUnderline />
              </NextUI.TabList>
              <RoutableTabPanel>
                <Outlet />
              </RoutableTabPanel>
            </div>
          </RoutableTabs>
        </DashboardContentLayout>
      </DashboardPageContent>
    </DashboardPageContainer>
  )
}
