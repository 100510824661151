import * as NextUI from '@cheddarup/web-ui/next'
import {NextLinkButton} from 'src/components/__next/LinkButton'
import FundraiserAddParticipantImage from 'src/images/add-participant.png'

export interface AddParticipantsReminderDisclosure
  extends NextUI.DisclosureProps {}

export const AddParticipantsReminderDisclosure: React.FC<
  AddParticipantsReminderDisclosure
> = (props) => {
  return (
    <NextUI.Disclosure {...props}>
      {(disclosure) => (
        <NextUI.DisclosureContent
          className="flex-row items-center gap-7 bg-teal-200 px-8 py-7 sm:rounded-default"
          render={<NextUI.Panel />}
        >
          <NextUI.Image
            src={FundraiserAddParticipantImage}
            alt="participant"
            width={150}
            height={188}
          />
          <div className="flex flex-col items-start gap-4 text-grey-800">
            <NextUI.Text className="font-extrabold text-ds-xl">
              Don't forget to add participants
            </NextUI.Text>
            <NextUI.Text className="text-ds-md">
              Your main page is ready to share but still needs participants to
              take donations on your organization's behalf. The more
              participants you add and invite the more successful your
              fundraiser!
            </NextUI.Text>
            <div className="flex flex-row gap-4">
              <NextLinkButton size="xl" variant="teal" to="../participants">
                Add Participants
              </NextLinkButton>
              <NextUI.NextButton
                size="xl"
                variant="outlined"
                onClick={disclosure.hide}
              >
                Dismiss
              </NextUI.NextButton>
            </div>
          </div>
        </NextUI.DisclosureContent>
      )}
    </NextUI.Disclosure>
  )
}
