import {genericForwardRef} from '@cheddarup/react-util'
import {
  AccessorFnColumnDef,
  createColumnHelper as builtInCreateColumnHelper,
  DisplayColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  Table,
  TableOptions as BuiltInTableOptions,
  useReactTable,
} from '@tanstack/react-table'
import React, {useContext, useImperativeHandle, useMemo} from 'react'
import {NextButton} from './Button'
import {PhosphorIcon} from '../../icons'
import {omitBy, SetOptional} from '@cheddarup/util'
import {AccessorFn} from '@tanstack/react-table'
import {cn} from '../../utils'
import {Pagination, PaginationProps} from './Pagination'
import {Checkbox} from './Checkbox'

export type TableInstance<D extends RowData = unknown> = Table<D>

// MARK: – TableProvider

interface TableContextValue<D extends RowData> {
  table: TableInstance<D>
}

const TableContext = React.createContext<TableContextValue<any> | null>(null)

export type TableOptions<D extends RowData> = Omit<
  SetOptional<BuiltInTableOptions<D>, 'sortingFns'>,
  | '_features'
  | 'debugAll'
  | 'debugCells'
  | 'debugColumns'
  | 'debugHeaders'
  | 'debugRows'
  | 'debugTable'
  | 'getFacetedMinMaxValues'
  | 'getFacetedRowModel'
  | 'getFacetedUniqueValues'
  | 'enableGrouping'
  | 'getGroupedRowModel'
  | 'groupedColumnMode'
  | 'manualGrouping'
  | 'onGroupingChange'
  | 'aggregationFns'
  | 'enablePinning'
  | 'getCoreRowModel'
  | 'getFilteredRowModel'
  | 'getSortedRowModel'
  | 'getExpandedRowModel'
  | 'getPaginationRowModel'
  | 'autoResetExpanded'
  | 'enableExpanding'
  | 'getIsRowExpanded'
  | 'getRowCanExpand'
  | 'manualExpanding'
  | 'onExpandedChange'
  | 'paginateExpandedRows'
  | 'enableHiding'
  | 'onColumnVisibilityChange'
  | 'onColumnOrderChange'
  | 'enableColumnPinning'
  | 'onColumnPinningChange'
  | 'enableRowPinning'
  | 'keepPinnedRows'
  | 'onRowPinningChange'
  | 'enableColumnFilters'
  | 'enableFilters'
  | 'filterFromLeafRows'
  | 'maxLeafRowFilterDepth'
  | 'onColumnFiltersChange'
  | 'filterFns'
>

export interface TableProviderProps<D extends RowData> extends TableOptions<D> {
  enableResizing?: boolean
  children: React.ReactNode | ((table: TableInstance<D>) => React.ReactNode)
}

export const TableProvider = genericForwardRef(
  <D extends RowData>(
    {
      manualFiltering,
      manualSorting,
      manualPagination,
      enableRowSelection = true,
      defaultColumn,
      sortingFns,
      onRowSelectionChange,
      onPaginationChange,
      onColumnSizingChange,
      onColumnSizingInfoChange,
      onGlobalFilterChange,
      onSortingChange,
      columns: columnsProp,
      enableResizing,
      children,
      ...restProps
    }: TableProviderProps<D>,
    forwardedRef: React.Ref<TableInstance<D>>,
  ) => {
    const columns = useMemo(() => {
      const columnHelper = createColumnHelper<D>()

      const checkboxColumn = columnHelper.display({
        id: '_checkbox_',
        minSize: 40,
        size: 40,
        maxSize: 40,
        enableResizing: false,
        header: ({table}) => (
          <Checkbox
            checked={
              table.getIsAllRowsSelected() ||
              (table.getIsSomeRowsSelected() ? 'mixed' : false)
            }
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({row}) => (
          <Checkbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      })

      return [
        enableRowSelection ? checkboxColumn : undefined,
        ...columnsProp.map((col) => ({
          enableResizing,
          ...col,
        })),
      ].filter((col) => col != null)
    }, [enableResizing, enableRowSelection, columnsProp])

    const table = useReactTable({
      columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: manualFiltering ? undefined : getFilteredRowModel(),
      getSortedRowModel: manualSorting ? undefined : getSortedRowModel(),
      getPaginationRowModel: manualPagination
        ? undefined
        : getPaginationRowModel(),
      enableRowSelection,
      defaultColumn: {
        size: 160,
        minSize: 40,
        maxSize: Number.MAX_SAFE_INTEGER,
        ...defaultColumn,
      },
      columnResizeMode: 'onChange',
      filterFns: {
        fuzzy: truthy,
      },

      ...omitBy(
        {
          onRowSelectionChange,
          onPaginationChange,
          onColumnSizingChange,
          onColumnSizingInfoChange,
          onGlobalFilterChange,
          onSortingChange,
        },
        (val) => val === undefined,
      ),

      sortingFns: {
        ...(sortingFns as any),
      },

      ...restProps,
    })

    useImperativeHandle(forwardedRef, () => table, [table])

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const contextValue: TableContextValue<D> = useMemo(
      () => ({
        table,
      }),
      [table, table.getState()],
    )

    return (
      <TableContext.Provider value={contextValue}>
        {typeof children === 'function' ? children(table) : children}
      </TableContext.Provider>
    )
  },
)

// MARK: – TableView

export interface TableViewProps
  extends Omit<React.ComponentPropsWithoutRef<'table'>, 'children'> {}

export const TableView = React.forwardRef<HTMLTableElement, TableViewProps>(
  ({className, ...restProps}, forwardedRef) => {
    const table = useTable()

    return (
      <table
        ref={forwardedRef}
        className={cn('w-full bg-trueWhite font-normal', className)}
        {...restProps}
      >
        {table.getHeaderGroups().length > 0 && (
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="relative"
                    colSpan={header.colSpan}
                    style={{
                      // width: `calc(var(--header-${header.id}-size) * 1px)`,
                      width: header.column.getSize(),
                    }}
                  >
                    <div className="inline-flex w-full flex-row items-center gap-2 whitespace-nowrap px-4 py-2 font-semibold text-ds-xs">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {header.column.getCanSort() && (
                        <NextButton
                          className="text-ds-base"
                          size="headless"
                          variant="headless"
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? (
                              <PhosphorIcon icon="sort-descending" />
                            ) : (
                              <PhosphorIcon icon="sort-ascending" />
                            )
                          ) : (
                            <PhosphorIcon icon="funnel-simple" />
                          )}
                        </NextButton>
                      )}
                    </div>

                    {header.column.getCanResize() && (
                      <div
                        data-active={header.column.getIsResizing()}
                        className="absolute inset-y-0 right-0 w-0_5 cursor-col-resize touch-none select-none bg-grey-200 transition-colors data-[active=true]:bg-teal-500"
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                      />
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody className="divide-y">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{
                    // width: `calc(var(--col-${cell.column.id}-size) * 1px)`,
                    width: cell.column.getSize(),
                  }}
                >
                  <div className="relative inline-flex flex-row items-center gap-2 p-4 font-normal">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  },
)

// MARK: – TablePagination

export interface TablePaginationProps
  extends Omit<PaginationProps, 'pagesCount' | 'page' | 'onPageChange'> {}

export const TablePagination = React.forwardRef<
  HTMLDivElement,
  TablePaginationProps
>((props, forwardedRef) => {
  const table = useTable()

  const paginationState = table.getState().pagination

  return (
    <Pagination
      ref={forwardedRef}
      page={paginationState.pageIndex + 1}
      pagesCount={table.getPageCount()}
      onPageChange={(newPageIndex) => table.setPageIndex(newPageIndex - 1)}
      {...props}
    />
  )
})

// MARK: – Helpers

export function useTable<D extends RowData>() {
  const contextValue = useContext(TableContext) as TableContextValue<D>
  if (!contextValue) {
    throw new Error('`useTable` usage must be wrapped in `TableProvider`')
  }

  return contextValue.table
}

export function createColumnHelper<TData extends RowData>() {
  const builtInColumnHelper = builtInCreateColumnHelper<TData>()

  return {
    ...builtInColumnHelper,
    accessor: <
      TAccessor extends AccessorFn<TData>,
      TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
        ? TReturn
        : never,
    >(
      accessor: TAccessor,
      column: DisplayColumnDef<TData, TValue>,
    ): AccessorFnColumnDef<TData, TValue> =>
      builtInColumnHelper.accessor(accessor, column),
  }
}

function truthy() {
  return true
}
