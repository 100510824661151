import {
  createSearchParams,
  LinkProps as LinkPrimitiveProps,
  NavLink as NavLinkPrimitive,
  NavLinkProps as NavLinkPrimitiveProps,
  useHref,
  useLinkClickHandler,
  useLocation,
  useResolvedPath,
} from 'react-router-dom'
import React from 'react'
import {NextAnchor, NextAnchorProps} from '@cheddarup/web-ui/next'
import * as WebUI from '@cheddarup/web-ui'

export interface NextLinkProps
  extends Omit<LinkPrimitiveProps, 'relative'>,
    UseLinkOptions,
    NextAnchorProps {}

export const NextLink = React.forwardRef<HTMLAnchorElement, NextLinkProps>(
  (
    {
      to,
      reloadDocument,
      preventScrollReset,
      relative,
      target,
      replace,
      state,
      onClick: onClickProp,
      preserveSearch,
      disabled,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {onClick, ...linkProps} = useLinkProps({
      preserveSearch,
      relative,
      to,
      replace,
      state,
      target,
      preventScrollReset,
    })

    return (
      <NextAnchor
        ref={forwardedRef}
        disabled={disabled}
        onClick={(event) => {
          onClickProp?.(event)
          if (!disabled && reloadDocument !== true && !event.defaultPrevented) {
            onClick(event)
          }
        }}
        {...linkProps}
        {...restProps}
      />
    )
  },
)

// MARK: – NavLink

export interface NextNavLinkProps
  extends Omit<NavLinkPrimitiveProps, 'className' | 'style' | 'children'>,
    Pick<
      NextLinkProps,
      'className' | 'style' | 'disabled' | 'variant' | 'render' | 'children'
    > {}

export const NextNavLink = React.forwardRef<
  HTMLAnchorElement,
  NextNavLinkProps
>(({to, disabled, variant, className, ...restProps}, forwardedRef) => (
  <NextAnchor
    ref={forwardedRef}
    className={WebUI.cn(
      'text-inherit hover:text-inherit aria-current-page:text-orange-500',
      className,
    )}
    disabled={disabled}
    variant={variant}
    render={<NavLinkPrimitive to={to} {...restProps} />}
  />
))

// MARK: – Hooks

type LinkRelativeRoutingType = LinkPrimitiveProps['relative']

interface UseLinkOptions
  extends Pick<
    NextLinkProps,
    'to' | 'replace' | 'state' | 'target' | 'preventScrollReset'
  > {
  preserveSearch?: boolean
  relative?: LinkRelativeRoutingType
}

function useLinkProps({
  preserveSearch,
  relative,
  to,
  replace,
  state,
  target,
  preventScrollReset,
}: UseLinkOptions) {
  const location = useLocation()
  const toPath = useResolvedPath(to)

  const newPath = toPath

  if (preserveSearch) {
    newPath.search = createSearchParams({
      ...Object.fromEntries(createSearchParams(location.search)),
      ...Object.fromEntries(createSearchParams(toPath.search)),
    }).toString()
  }

  return {
    href: useHref(toPath, {relative}),
    onClick: useLinkClickHandler(newPath, {
      replace,
      state,
      target,
      relative,
      preventScrollReset,
    }),
    target,
  }
}
