import * as NextUI from '@cheddarup/web-ui/next'
import * as WebUI from '@cheddarup/web-ui'
import React, {useEffect, useState} from 'react'
import {api} from '@cheddarup/api-client'
import AddBannerImageIcon from 'src/images/AddBannerImageIcon.svg'
import CollectionBannerPlaceholder from 'src/images/CollectionBannerPlaceholder.jpg'

import {SharpImage} from 'src/components/SharpImage'
import * as Util from '@cheddarup/util'
import {
  PlanUpgradeButton,
  PremiumFeatureSideSheetDisclosure,
  PremiumFeaturesSideSheet,
  PremiumFeaturesSideSheetProps,
} from 'src/components/PremiumFeaturesSideSheet'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'
import {
  UploadPinturaImageForm,
  UploadPinturaImageFormProps,
} from './UploadPinturaImageForm'
import {
  AlbumImagePickerForm,
  usePartnerAlbumsQuery,
} from './AlbumImagePickerForm'
import {DashboardPageFooter} from './DashboardPageLayout'
import {FormikField, FormikForm, FormikSubmitButton} from './Formik'
import {useFormik} from '@cheddarup/react-util'

export interface TabBannerPickerProps
  extends NextUI.DialogProps,
    Pick<ImagesStackBuilderProps, 'images' | 'onImagesChange'> {
  tab?: Api.Tab
  videoLink?: string | null
  onVideoLinkChange?: TabVideoFormProps['onDidSubmit']
}

export const TabBannerPicker = React.forwardRef<
  NextUI.DialogInstance,
  TabBannerPickerProps
>(
  (
    {tab, images, videoLink, onImagesChange, onVideoLinkChange, ...restProps},
    forwardedRef,
  ) => (
    <NextUI.Dialog
      ref={forwardedRef}
      disclosure={
        !!videoLink || images.length > 0 ? (
          <TabBannerCarousel
            className="w-full"
            tabId={tab?.id}
            images={images}
            videoLink={videoLink}
            onImagesChange={onImagesChange}
          />
        ) : (
          <NextUI.DialogDisclosure
            className="w-full justify-start gap-4 p-6 text-start"
            render={<NextUI.NextButton size="headless" variant="gray" />}
          >
            <NextUI.Image className="h-[3em]" src={AddBannerImageIcon} alt="" />
            Create a banner
          </NextUI.DialogDisclosure>
        )
      }
      {...restProps}
    >
      {(dialog) => (
        <NextUI.ModalContent variant="overlay">
          <PremiumFeaturesSideSheet
            tabId={tab?.id}
            enforcedPremiumMeta={{
              pro: {
                multipleBannerImages: true,
                videoLink: true,
              },
            }}
          >
            <WebUI.ModalHeader variant="compact">
              <NextUI.DialogHeading>Create a Banner</NextUI.DialogHeading>
            </WebUI.ModalHeader>

            <NextUI.Tabs>
              <NextUI.TabList className="px-12">
                <NextUI.Tab id="images">Image(s)</NextUI.Tab>
                <NextUI.Tab id="video">Video</NextUI.Tab>

                <NextUI.SelectedTabUnderline />
              </NextUI.TabList>

              <NextUI.TabPanel
                className="flex grow flex-col gap-4 pt-6"
                tabId="images"
              >
                <span className="mx-12">
                  Add a single image or encourage interaction with an image
                  carousel of up to 4 images.
                </span>

                <ImagesStackBuilder
                  className="mx-12 grow"
                  tab={tab}
                  images={images}
                  onImagesChange={onImagesChange}
                />

                <DashboardPageFooter>
                  <NextUI.NextButton
                    type="button"
                    size="md"
                    variant="orange"
                    onClick={() => dialog.hide()}
                  >
                    Save
                  </NextUI.NextButton>
                </DashboardPageFooter>
              </NextUI.TabPanel>

              <NextUI.TabPanel className="flex grow flex-col" tabId="video">
                <TabVideoForm
                  className="grow"
                  tab={tab}
                  initialValues={{link: videoLink ?? ''}}
                  onDidSubmit={(values) => {
                    onVideoLinkChange?.(values)
                    dialog.hide()
                  }}
                />
              </NextUI.TabPanel>
            </NextUI.Tabs>
          </PremiumFeaturesSideSheet>

          <NextUI.ModalCloseButton />
        </NextUI.ModalContent>
      )}
    </NextUI.Dialog>
  ),
)

// MARK: – TabBannerCarousel

interface TabBannerCarouselProps
  extends WebUI.CarouselProps,
    React.ComponentPropsWithoutRef<'div'>,
    Pick<PremiumFeaturesSideSheetProps, 'tabId'> {
  images: Api.S3Image[]
  videoLink?: string | null
  onImagesChange?: (newImages: Api.S3Image[]) => void
  onVideoLinkChange?: (newVideoLink: string) => void
}

const TabBannerCarousel = ({
  tabId,
  images,
  videoLink,
  onImagesChange,
  onVideoLinkChange,
  options,
  ...restProps
}: TabBannerCarouselProps) => {
  const [mrId] = useManagerRoleId()
  const isSubscribedToProQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_pro,
  })

  const isSubscribedToPro = isSubscribedToProQuery.data !== false || !!mrId

  return (
    <PremiumFeaturesSideSheet
      modal
      tabId={tabId}
      enforcedPremiumMeta={{
        pro: {
          multipleBannerImages: true,
          videoLink: true,
        },
      }}
    >
      <WebUI.Carousel
        className="w-full"
        options={{loop: true, ...options}}
        {...restProps}
      >
        <WebUI.CarouselContent className="min-h-32">
          {images.map((headerImage, idx) => (
            <WebUI.CarouselItem
              key={headerImage.id}
              className="flex items-center justify-center bg-trueBlack"
            >
              <SharpImage
                alt="Collection banner"
                width="100%"
                image={headerImage}
              />

              <div className="absolute top-4 right-4 flex flex-row gap-3">
                {!isSubscribedToPro && idx > 0 && (
                  <PremiumFeatureSideSheetDisclosure />
                )}
                <NextUI.DialogDisclosure
                  className="p-2 text-ds-lg"
                  render={<NextUI.NextButton size="headless" variant="gray" />}
                >
                  <WebUI.PhosphorIcon icon="pencil" />
                </NextUI.DialogDisclosure>
                <NextUI.NextButton
                  className="p-2 text-ds-lg"
                  size="headless"
                  variant="gray"
                  onClick={() =>
                    onImagesChange?.(images.filter((hi) => hi !== headerImage))
                  }
                >
                  <WebUI.PhosphorIcon icon="x" />
                </NextUI.NextButton>
              </div>
            </WebUI.CarouselItem>
          ))}

          {!!videoLink && (
            <WebUI.CarouselItem className="flex items-center justify-center bg-trueBlack">
              <WebUI.VideoPlayer
                className="[&_>_div]:!flex [&_>_div]:!items-center [&_>_div]:!justify-center"
                width="100%"
                height="100%"
                url={videoLink}
              />

              <div className="absolute top-4 right-4 flex flex-row gap-3">
                {!isSubscribedToPro && <PremiumFeatureSideSheetDisclosure />}

                <NextUI.DialogDisclosure
                  className="p-2 text-ds-lg"
                  render={<NextUI.NextButton size="headless" variant="gray" />}
                >
                  <WebUI.PhosphorIcon icon="pencil" />
                </NextUI.DialogDisclosure>
                <NextUI.NextButton
                  className="p-2 text-ds-lg"
                  size="headless"
                  variant="gray"
                  onClick={() => onVideoLinkChange?.('')}
                >
                  <WebUI.PhosphorIcon icon="x" />
                </NextUI.NextButton>
              </div>
            </WebUI.CarouselItem>
          )}
        </WebUI.CarouselContent>

        {images.length + (videoLink ? 1 : 0) > 1 && <WebUI.CarouselStepper />}
      </WebUI.Carousel>
    </PremiumFeaturesSideSheet>
  )
}

// MARK: – ImagesStackBuilder

interface ImagesStackBuilderProps
  extends React.ComponentPropsWithoutRef<'div'> {
  tab?: Api.Tab
  images: Api.S3Image[]
  onImagesChange?: (newImages: Api.S3Image[]) => void
}

const ImagesStackBuilder = ({
  tab,
  images,
  onImagesChange,
  className,
  ...restProps
}: ImagesStackBuilderProps) => {
  const [selectedImageIdx, setSelectedImageIdx] = useState<number>(0)
  const [editingImageIdx, setEditingImageIdx] = useState<number | null>(null)

  const selectedHeaderImage = images[selectedImageIdx]

  return (
    <>
      <div
        className={WebUI.cn(
          'relative flex w-full max-w-screen-sm flex-col gap-4',
          className,
        )}
        {...restProps}
      >
        {selectedHeaderImage ? (
          <WithButtonOverlay
            className="rounded-default"
            overlay={
              <div className="absolute inset-x-6 top-6 flex flex-row justify-between gap-3">
                {selectedImageIdx === 0 && images.length > 1 ? (
                  <NextUI.Tag
                    className="bg-trueWhite text-ds-xs"
                    variant="headless"
                  >
                    Main Image
                  </NextUI.Tag>
                ) : (
                  <div />
                )}
                <NextUI.NextButton
                  className="p-2 text-ds-lg"
                  size="headless"
                  variant="gray"
                  onClick={() =>
                    onImagesChange?.(
                      images.filter((image) => image !== selectedHeaderImage),
                    )
                  }
                >
                  <WebUI.PhosphorIcon icon="x" />
                </NextUI.NextButton>
              </div>
            }
            onClick={() => setEditingImageIdx(selectedImageIdx)}
          >
            <SharpImage
              key={selectedHeaderImage.id}
              alt="Main"
              width="100%"
              image={selectedHeaderImage}
            />
          </WithButtonOverlay>
        ) : (
          <NextUI.NextButton
            className="aspect-video w-full flex-col"
            size="headless"
            variant="gray"
            onClick={() => setEditingImageIdx(0)}
          >
            <NextUI.Image className="h-[3em]" alt="" src={AddBannerImageIcon} />
            Select Image
          </NextUI.NextButton>
        )}

        {images.length > 0 && (
          <ImagesStack
            tab={tab}
            selectedImageId={selectedHeaderImage?.id}
            images={images}
            onSelectImageId={(newSelectedImageId) =>
              setSelectedImageIdx(
                images.findIndex((i) => i.id === newSelectedImageId),
              )
            }
            onOrderChange={(newOrder) =>
              onImagesChange?.(
                Util.sort(images).asc((i) => newOrder.indexOf(i.id)),
              )
            }
            onAddImage={() => setEditingImageIdx(images.length)}
          />
        )}
      </div>

      <HeaderImagePickerModal
        key={editingImageIdx}
        open={editingImageIdx != null}
        imageCropAspectRatio={16 / 9}
        imageCropLimitToImage={false}
        placeholderSrc={CollectionBannerPlaceholder}
        initialImage={images[editingImageIdx ?? -1]}
        onImageSubmit={(newImage) => {
          if (editingImageIdx != null) {
            const newHeaderImages = Util.splice(images, editingImageIdx, 1, [
              newImage,
            ])
            onImagesChange?.(newHeaderImages)
          }
        }}
        setOpen={(isOpen) => {
          if (!isOpen) {
            setEditingImageIdx(null)
          }
        }}
      />
    </>
  )
}

// MARK: – ImagesStack

interface ImagesStackProps extends React.ComponentPropsWithoutRef<'div'> {
  maxImagesCount?: number
  tab?: Api.Tab
  images: Api.S3Image[]
  selectedImageId?: number | null
  onAddImage: () => void
  onSelectImageId: (imageId: number) => void
  onOrderChange: (imageIds: number[]) => void
}

const ImagesStack = ({
  maxImagesCount = 4,
  tab,
  images,
  selectedImageId,
  onAddImage,
  onSelectImageId,
  onOrderChange,
  className,
  ...restProps
}: ImagesStackProps) => {
  const [mrId] = useManagerRoleId()
  const isSubscribedToProQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_pro,
  })

  const isSubscribedToPro = isSubscribedToProQuery.data !== false || !!mrId

  const planBadge = isSubscribedToPro ? null : !tab ||
    tab.status === 'draft' ? (
    <PremiumFeatureSideSheetDisclosure key="premium-feature-side-sheet-disclosure" />
  ) : (
    <PlanUpgradeButton key="plan-upgrade-button" upgradeTo="pro" />
  )

  return (
    <div className={WebUI.cn('flex flex-row gap-3', className)} {...restProps}>
      <WebUI.DragAndDrop
        dragOverlayPortal={false}
        onDragEnd={(event) => {
          if (event.over) {
            const currentOrder = images.map((i) => i.id)
            const activeIdx = currentOrder.indexOf(Number(event.active.id))
            const overIdx = currentOrder.indexOf(Number(event.over.id))

            onOrderChange(Util.swapIndices(currentOrder, activeIdx, overIdx))
          }
        }}
      >
        <WebUI.SortableContext
          strategy={WebUI.rectSortingStrategy}
          items={images}
        >
          {({items: imageIds}) => (
            <>
              {imageIds.map((imageId, idx) => {
                const image = images.find((image) => imageId === image.id)

                return image ? (
                  <WebUI.Sortable
                    key={image.id}
                    id={imageId}
                    className="flex-1"
                  >
                    <WithButtonOverlay
                      aria-selected={selectedImageId === image.id}
                      className="rounded-default ring-offset-1 transition-shadow aria-selected:ring-2"
                      overlay={
                        planBadge &&
                        idx > 0 && (
                          <div className="absolute top-2 right-2">
                            {planBadge}
                          </div>
                        )
                      }
                      // biome-ignore lint/style/noNonNullAssertion:
                      onClick={() => onSelectImageId(image.id!)}
                    >
                      <SharpImage alt="" image={image} />
                    </WithButtonOverlay>
                  </WebUI.Sortable>
                ) : null
              })}
              {imageIds.length < maxImagesCount && (
                <div className="relative flex-1">
                  <NextUI.NextButton
                    className="size-full flex-col"
                    size="headless"
                    variant="gray"
                    onClick={() => {
                      if (
                        isSubscribedToPro ||
                        !tab ||
                        tab.status === 'draft' ||
                        imageIds.length === 0
                      ) {
                        onAddImage()
                      }
                    }}
                  >
                    <WebUI.PhosphorIcon
                      className="text-ds-lg"
                      icon="plus-bold"
                    />
                    Add Image
                  </NextUI.NextButton>
                  {planBadge && imageIds.length > 0 && (
                    <div className="absolute top-2 right-2">{planBadge}</div>
                  )}
                </div>
              )}
              {Array.from({length: maxImagesCount - images.length - 1}).map(
                (_, idx) => (
                  <div key={idx} className="flex-1" />
                ),
              )}
            </>
          )}
        </WebUI.SortableContext>
      </WebUI.DragAndDrop>
    </div>
  )
}

// MARK: – WithButtonOverlay

interface WithButtonOverlayProps
  extends Util.Merge<
    React.ComponentPropsWithoutRef<'div'>,
    Pick<React.ComponentPropsWithoutRef<'button'>, 'onClick'>
  > {
  overlay?: React.ReactNode
}

const WithButtonOverlay = ({
  overlay,
  className,
  children,
  onClick,
  ...restProps
}: WithButtonOverlayProps) => {
  return (
    <div
      className={WebUI.cn('relative overflow-hidden', className)}
      {...restProps}
    >
      {children}
      <div className="absolute inset-0">
        <button
          className="absolute inset-0 cursor-pointer bg-transparent"
          type="button"
          onClick={onClick}
        />
        {overlay}
      </div>
    </div>
  )
}

// MARK: – TabVideoForm

interface TabVideoFormValues {
  platform: WebUI.SupportedVideoPlatform
  link: string
}

interface TabVideoFormProps extends React.ComponentPropsWithoutRef<'form'> {
  initialValues?: Partial<TabVideoFormValues>
  tab?: Api.Tab
  onDidSubmit?: (values: TabVideoFormValues) => void
}

const TabVideoForm = ({
  initialValues,
  tab,
  onDidSubmit,
  className,
  ...restProps
}: TabVideoFormProps) => {
  const [mrId] = useManagerRoleId()
  const isSubscribedToProQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_pro,
  })

  const isSubscribedToPro = isSubscribedToProQuery.data !== false || !!mrId
  const isDisabled = !isSubscribedToPro && !!tab && tab.status !== 'draft'

  const formik = useFormik<TabVideoFormValues>({
    initialValues: {
      platform: initialValues?.platform ?? 'youtube',
      link: initialValues?.link ?? '',
    },
    onSubmit: (values, formikHelpers) => {
      const platform = WebUI.getVideoPlatformForUrl(values.link)

      if (values.link.length === 0 || platform) {
        onDidSubmit?.(values)
      } else {
        formikHelpers.setFieldError('videoLink', 'Unsupported video platform')
        return
      }
    },
  })

  useEffect(() => {
    const linkVideoPlatform = WebUI.getVideoPlatformForUrl(formik.values.link)
    if (linkVideoPlatform) {
      formik.setFieldValue('platform', linkVideoPlatform)
    }
  }, [formik.values.link, formik.setFieldValue])

  return (
    <FormikForm
      className={WebUI.cn('flex flex-col gap-4', className)}
      formik={formik}
      {...restProps}
    >
      <div className="flex max-w-lg grow flex-col gap-4 overflow-y-auto px-12 py-6">
        {!isSubscribedToPro &&
          (!tab || tab.status === 'draft' ? (
            <PremiumFeatureSideSheetDisclosure />
          ) : (
            <PlanUpgradeButton key="plan-upgrade-button" upgradeTo="pro" />
          ))}
        <span className="text-ds-base">
          Your video will be embedded and playable directly from your page.
        </span>

        <FormikField
          name="platform"
          required
          label="Video platform"
          control={(fieldProps, field) => (
            <WebUI.DropdownSelect
              name={fieldProps.name}
              disabled={isDisabled}
              value={fieldProps.value}
              onValueChange={(newVideoPlatform) =>
                field.setValue(newVideoPlatform)
              }
              onBlur={fieldProps.onBlur}
            >
              {WebUI.supportedVideoPlatforms.map((vp) => (
                <WebUI.DropdownSelectOption key={vp} value={vp}>
                  {WebUI.formattedVideoPlatforms[vp]}
                </WebUI.DropdownSelectOption>
              ))}
            </WebUI.DropdownSelect>
          )}
        />

        <FormikField
          name="link"
          required
          label="Video URL"
          control={<NextUI.Input type="url" disabled={isDisabled} />}
        />
      </div>

      <DashboardPageFooter>
        <FormikSubmitButton size="md" variant="orange">
          Save
        </FormikSubmitButton>
      </DashboardPageFooter>
    </FormikForm>
  )
}

// MARK: – HeaderImagePickerModal

export interface HeaderImagePickerModalProps
  extends NextUI.DialogProps,
    Pick<
      UploadPinturaImageFormProps,
      | 'placeholderSrc'
      | 'initialImage'
      | 'imageType'
      | 'imageCropAspectRatio'
      | 'imageCropLimitToImage'
    > {
  heading?: React.ReactNode
  pinturaUtils?: UploadPinturaImageFormProps['utils']
  parentPath?: UploadPinturaImageFormProps['parentPath']
  onImageSubmit?: UploadPinturaImageFormProps['onSubmit']
}

export const HeaderImagePickerModal = React.forwardRef<
  NextUI.DialogInstance,
  HeaderImagePickerModalProps
>(
  (
    {
      heading = 'Add Image',
      placeholderSrc,
      initialImage,
      imageCropAspectRatio,
      imageCropLimitToImage,
      imageType = 'header',
      parentPath = 'user',
      pinturaUtils,
      onImageSubmit,
      ...restProps
    },
    forwardedRef,
  ) => {
    const media = WebUI.useMedia()
    const albumsQuery = usePartnerAlbumsQuery()

    return (
      <NextUI.Dialog ref={forwardedRef} {...restProps}>
        {(dialog) => (
          <NextUI.ModalContent
            aria-label="Header image picker"
            variant="overlay"
          >
            <WebUI.ModalHeader>
              <NextUI.DialogHeading>{heading}</NextUI.DialogHeading>
            </WebUI.ModalHeader>

            <WebUI.Tabs
              className="min-h-0 grow flex-col divide-y"
              orientation={media.lg ? 'vertical' : 'horizontal'}
            >
              <div className="flex min-h-0 grow flex-col divide-y lg:flex-row lg:divide-x lg:divide-y-0">
                <WebUI.TabList
                  aria-label="Navigation"
                  className="!border-r-0 !border-b-0 min-w-[256px] flex-0 overflow-x-auto lg:overflow-hidden"
                >
                  <WebUI.Tab id="upload-image">Upload Image</WebUI.Tab>
                  {albumsQuery.data?.map((p) => (
                    <WebUI.Tab key={p.key} id={p.key}>
                      {p.name}
                    </WebUI.Tab>
                  ))}
                </WebUI.TabList>

                <WebUI.TabPanel className="min-h-0 grow" tabId="upload-image">
                  <UploadPinturaImageForm
                    className="h-full"
                    imageType={imageType}
                    imageCropAspectRatio={imageCropAspectRatio}
                    imageCropLimitToImage={imageCropLimitToImage}
                    parentPath={parentPath}
                    utils={pinturaUtils}
                    placeholderSrc={placeholderSrc}
                    initialImage={initialImage}
                    onSubmit={(newImage) => {
                      onImageSubmit?.(newImage)
                      dialog.hide()
                    }}
                  />
                </WebUI.TabPanel>

                {albumsQuery.data?.map((p) => (
                  <WebUI.TabPanel
                    key={p.key}
                    className="min-h-0 grow"
                    tabId={p.key}
                  >
                    <AlbumImagePickerForm
                      className="h-full"
                      images={p.images}
                      onSubmit={(selectedImageId) => {
                        const selectedImage = p.images.find(
                          (i) => i.id === selectedImageId,
                        )
                        if (selectedImage) {
                          onImageSubmit?.(selectedImage)
                          dialog.hide()
                        }
                      }}
                    />
                  </WebUI.TabPanel>
                ))}
              </div>
            </WebUI.Tabs>

            <NextUI.ModalCloseButton />
          </NextUI.ModalContent>
        )}
      </NextUI.Dialog>
    )
  },
)
