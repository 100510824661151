// @ts-expect-error
import _cartesianProduct from 'cartesian'
import lodash from 'lodash'
import {isPlainObject, mapToObj, mapValues} from 'remeda'
import {Primitive} from 'type-fest'

export {deepEqual} from 'fast-equals'
export {
  hasSubObject,
  invert,
  isEmpty,
  mapKeys,
  merge,
  mergeDeep,
  omit,
  omitBy,
  pick,
  setPath as setAt,
} from 'remeda'

export {mapValues, mapToObj}

const {isMatch, mergeWith, maxBy} = lodash

export {isMatch, mergeWith, maxBy}

export function pickBy<T, U extends T>(
  obj: Record<string, T> | null | undefined,
  predicate: (val: T, key: string) => val is U,
): Record<string, U>
export function pickBy<T>(
  obj: Record<string, T> | null | undefined,
  predicate: (val: T, key: string) => boolean,
): Record<string, T>
export function pickBy<T extends object>(
  obj: T | null | undefined,
  predicate: (val: T[keyof T], key: keyof T) => boolean,
): Partial<T>
export function pickBy(
  obj: Record<string, unknown> | null | undefined,
  predicate: (val: unknown, key: string) => boolean,
) {
  const ret = {} as Record<string, unknown>
  if (obj) {
    for (const prop in obj) {
      if (predicate(obj[prop], prop)) {
        ret[prop] = obj[prop]
      }
    }
  }
  return ret
}

// TODO: Replace this with something from remeda
export function objectFromObject<T, U = T>(
  obj: Record<string, T>,
  valueSelector: (key: string, value: T, index: number) => U,
  /** return undefined to filter out */
  keySelector: (key: string, value: T, index: number) => string | undefined = (
    key,
  ) => key,
) {
  let index = 0
  const ret: Record<string, U> = {}
  for (const [key, value] of Object.entries(obj)) {
    const newKey = keySelector(key, value, index)
    if (newKey != null) {
      if (newKey in ret) {
        console.warn(
          `Duplicate key "${newKey.toString()}" during objectFromObject`,
        )
      }
      ret[newKey] = valueSelector(key, value, index)
    }
    index++
  }
  return ret
}

// TODO: Replace this with something from remeda
export function arrayFromObject<T, U = T>(
  obj: Record<string, T>,
  selector: (key: string, value: T, index: number) => U,
) {
  const items: U[] = []
  const keys = Object.keys(obj)
  for (const key of keys) {
    // biome-ignore lint/style/noNonNullAssertion:
    items.push(selector(key, obj[key]!, keys.indexOf(key)))
  }
  return items
}

interface Cartesian {
  <T extends any[][]>(arr: T): T
  <T>(obj: Record<string, T[]>): Array<Record<string, T>>
}

const cartesianProduct: Cartesian = _cartesianProduct

export {cartesianProduct}

// See https://gist.github.com/tkrotoff/a6baf96eb6b61b445a9142e5555511a0

type PlainObject = Record<PropertyKey, unknown>

export type NullToUndefined<T> = T extends null
  ? undefined
  : T extends PlainObject
    ? {[K in keyof T]: NullToUndefined<T[K]>}
    : T

/**
 * Recursively converts all plain object null values to undefined.
 *
 * @param obj object to convert
 * @returns a copy of the object with all its null values converted to undefined
 */
export function nullToUndefined<T>(obj: T): NullToUndefined<T> {
  if (obj === null) {
    return undefined as any
  }
  if (isPlainObject(obj)) {
    return mapValues(obj, (val) => nullToUndefined(val)) as any
  }

  return obj as any
}

type DeepOmitArray<T extends any[], K> = {
  [P in keyof T]: DeepOmit<T[P], K>
}

export type DeepOmit<T, K> = T extends Primitive
  ? T
  : {
      [P in Exclude<keyof T, K>]: T[P] extends infer TP
        ? TP extends Primitive
          ? TP // leave primitives and functions alone
          : TP extends any[]
            ? DeepOmitArray<TP, K> // Array special handling
            : DeepOmit<TP, K>
        : never
    }

export function omitValuesDeep<T, V>(
  obj: T,
  values: unknown[],
): DeepOmit<T, V> {
  if (isPlainObject(obj)) {
    return Object.entries(obj)
      .filter(([_k, v]) => !values.includes(v))
      .reduce(
        (r, [key, value]) => ({...r, [key]: omitValuesDeep(value, values)}),
        {},
      ) as any
  }

  return obj as any
}
