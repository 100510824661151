import * as Ariakit from '@ariakit/react'
import {useTagName} from '@ariakit/react-core/utils/hooks'
import {getFirstTabbableIn} from '@ariakit/core/utils/focus'
import React, {useCallback, useContext, useId, useMemo} from 'react'

import {Text, TextProps} from './Text'
import {cn} from '../../utils'

interface InternalFormFieldContextValue {
  required?: boolean
}

const InternalFormFieldContext =
  React.createContext<InternalFormFieldContextValue>(
    {} as InternalFormFieldContextValue,
  )

export type FormFieldSize = 'default' | 'compact'

export interface FormFieldProps extends Ariakit.RoleProps {
  label?: React.ReactNode
  description?: React.ReactNode
  error?: React.ReactNode
  required?: boolean
}

export const FormField = React.forwardRef<HTMLDivElement, FormFieldProps>(
  (
    {children, className, label, description, error, required, ...restProps},
    forwardedRef,
  ) => {
    const collectionStore = Ariakit.useCollectionStore()

    const contextValue: InternalFormFieldContextValue = useMemo(
      () => ({
        required,
      }),
      [required],
    )

    return (
      <InternalFormFieldContext.Provider value={contextValue}>
        <Ariakit.Collection
          store={collectionStore}
          render={
            <Ariakit.Role
              ref={forwardedRef}
              className={cn('flex min-w-0 flex-col gap-2', className)}
              {...restProps}
            />
          }
        >
          {!!label && <FormFieldLabel>{label}</FormFieldLabel>}

          {children}

          {!!description && (
            <FormFieldDescription>{description}</FormFieldDescription>
          )}

          {!!error && error !== true && (
            <FormFieldError>{error}</FormFieldError>
          )}
        </Ariakit.Collection>
      </InternalFormFieldContext.Provider>
    )
  },
)

// MARK: – FormControl

export interface FormControlProps extends Ariakit.RoleProps<'input'> {}

export const FormControl = React.forwardRef<HTMLInputElement, FormControlProps>(
  (
    {
      'aria-describedby': ariaDescribedby,
      id: idProp,
      required: requiredProp,
      ...restProps
    },
    forwardedRef,
  ) => {
    const collectionStore = Ariakit.useCollectionContext()
    const contextValue = useContext(InternalFormFieldContext)
    const id = idProp ?? useId()

    const labelItem = Ariakit.useStoreState(collectionStore, (s) =>
      s?.items.find((i) => (i as any).type === 'label'),
    )
    const descriptionItem = Ariakit.useStoreState(collectionStore, (s) =>
      s?.items.find((i) => (i as any).type === 'description'),
    )
    const errorItem = Ariakit.useStoreState(collectionStore, (s) =>
      s?.items.find((i) => (i as any).type === 'error'),
    )

    const describedBy = [errorItem?.id, descriptionItem?.id, ariaDescribedby]
      .filter((elId) => !!elId)
      .join(' ')

    const required = requiredProp ?? contextValue.required

    return (
      <Ariakit.CollectionItem
        ref={forwardedRef}
        aria-labelledby={labelItem?.id}
        aria-invalid={!!errorItem}
        aria-errormessage={errorItem?.element?.id}
        aria-describedby={describedBy || undefined}
        id={id}
        aria-required={required}
        getItem={useCallback<
          Required<Ariakit.CollectionItemOptions>['getItem']
        >((item) => ({...item, type: 'control'}), [])}
        render={<Ariakit.Role.input required={required} />}
        {...restProps}
      />
    )
  },
)

// MARK: - FormFieldError

export interface FormFieldErrorProps extends TextProps {}

export const FormFieldError = React.forwardRef<
  HTMLSpanElement,
  FormFieldErrorProps
>(({className, id: idProp, ...restProps}, forwardedRef) => {
  const id = idProp ?? useId()

  return (
    <Ariakit.CollectionItem
      role="alert"
      id={id}
      getItem={useCallback<Required<Ariakit.CollectionItemOptions>['getItem']>(
        (item) => ({...item, type: 'error'}),
        [],
      )}
      render={
        <Text
          ref={forwardedRef}
          className={cn('text-ds-xs text-orange-500', className)}
          {...restProps}
        />
      }
    />
  )
})

// MARK: - FormFieldLabel

export interface FormFieldLabelProps extends Omit<Ariakit.RoleProps, 'render'> {
  required?: boolean
}

export const FormFieldLabel = React.forwardRef<
  HTMLDivElement,
  FormFieldLabelProps
>(
  (
    {
      required: requiredProp,
      className,
      id: idProp,
      children,
      onClick,
      ...restProps
    },
    forwardedRef,
  ) => {
    const collectionStore = Ariakit.useCollectionContext()
    const contextValue = useContext(InternalFormFieldContext)
    const id = idProp ?? useId()

    const controlCollectionItem = Ariakit.useStoreState(collectionStore, (s) =>
      s?.items.find((i) => (i as any).type === 'control'),
    )

    const fieldTagName = useTagName(controlCollectionItem?.element, 'input')
    const isNativeLabel = supportsNativeLabel(fieldTagName)

    const required = requiredProp ?? contextValue.required

    return (
      <Ariakit.CollectionItem
        id={id}
        getItem={useCallback<
          Required<Ariakit.CollectionItemOptions>['getItem']
        >((item) => ({...item, type: 'label'}), [])}
        onClick={(event) => {
          onClick?.(event)
          const controlEl = controlCollectionItem?.element

          if (event.defaultPrevented || isNativeLabel || !controlEl) {
            return
          }

          // See https://github.com/ariakit/ariakit/blob/main/packages/ariakit-react-core/src/form/form-label.tsx#L95
          queueMicrotask(() => {
            getFirstTabbableIn(controlEl, true, true)?.focus()
          })
        }}
        render={
          <Ariakit.Role
            ref={forwardedRef}
            className={cn('font-body font-normal text-ds-sm', className)}
            render={
              isNativeLabel ? (
                // biome-ignore lint/a11y/noLabelWithoutControl:
                <label htmlFor={controlCollectionItem?.id} />
              ) : (
                <span />
              )
            }
            {...restProps}
          >
            {children} {required && <span className="text-orange-500">*</span>}
          </Ariakit.Role>
        }
      />
    )
  },
)

// MARK: - FormFieldDescription

export interface FormFieldDescriptionProps extends Ariakit.RoleProps<'span'> {}

export const FormFieldDescription = React.forwardRef<
  HTMLSpanElement,
  FormFieldDescriptionProps
>(({className, id: idProp, ...restProps}, forwardedRef) => {
  const id = idProp ?? useId()

  return (
    <Ariakit.CollectionItem
      id={id}
      getItem={useCallback<Required<Ariakit.CollectionItemOptions>['getItem']>(
        (item) => ({...item, type: 'description'}),
        [],
      )}
      render={
        <Ariakit.Role.span
          ref={forwardedRef}
          className={cn('text-ds-xs text-grey-500', className)}
          {...restProps}
        />
      }
    />
  )
})

// MARK: – Helpers

// See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label
function supportsNativeLabel(tagName?: string) {
  return (
    tagName === 'button' ||
    tagName === 'input' ||
    tagName === 'textarea' ||
    tagName === 'select' ||
    tagName === 'meter' ||
    tagName === 'output' ||
    tagName === 'progress'
  )
}
