import {endpoints} from '../../endpoints'
import {participantEmailInviteStatus} from '../../schemas'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useCreateParticipantsMutation = makeUseMutation(
  endpoints.participants.createBulk,
  (vars) => ({
    regular: (newParticipants) => [
      makeQueryUpdate(
        endpoints.participants.list,
        (existingParticipants) =>
          existingParticipants
            ? {
                data: [
                  ...newParticipants.results.successful,
                  ...existingParticipants.data,
                ],
                pagination: existingParticipants.pagination,
              }
            : existingParticipants,
        vars,
      ),
    ],
  }),
)

export const useDeleteParticipantsMutation = makeUseMutation(
  endpoints.participants.deleteBulk,
  (vars) => ({
    regular: () => [
      makeQueryUpdate(
        endpoints.participants.list,
        (participants) =>
          participants
            ? {
                data: participants.data.filter(
                  (p) => !vars.body?.participant_ids.includes(p.id),
                ),
                pagination: participants.pagination,
              }
            : participants,
        vars,
      ),
    ],
  }),
)

export const useSendParticipantInviteMutation = makeUseMutation(
  endpoints.participants.sendInvite,
  (vars) => ({
    regular: () => [
      makeQueryUpdate(
        endpoints.participants.list,
        (participants) =>
          participants
            ? {
                data: participants.data.map((p) =>
                  vars.body?.participant_ids?.includes(p.id)
                    ? {
                        ...p,
                        invite_email_status:
                          participantEmailInviteStatus.enum.sent,
                      }
                    : p,
                ),
                pagination: participants.pagination,
              }
            : participants,
        vars,
      ),
    ],
  }),
)

export const useResendtParticipantInviteMutation = makeUseMutation(
  endpoints.participants.resendInvite,
  (vars) => ({
    regular: () => [
      makeQueryUpdate(
        endpoints.participants.list,
        (participants) =>
          participants
            ? {
                data: participants.data.map((p) =>
                  vars.body?.participant_ids?.includes(p.id)
                    ? {
                        ...p,
                        invite_email_status:
                          participantEmailInviteStatus.enum.sent,
                      }
                    : p,
                ),
                pagination: participants.pagination,
              }
            : participants,
        vars,
      ),
    ],
  }),
)

export const useUpdateParticipantMutation = makeUseMutation(
  endpoints.participants.update,
  (vars) => ({
    regular: (updatedParticipant) => [
      makeQueryUpdate(
        endpoints.participants.list,
        (participants) =>
          participants
            ? {
                data: participants.data.map((p) =>
                  p.id === updatedParticipant.id ? updatedParticipant : p,
                ),
                pagination: participants.pagination,
              }
            : participants,
        vars,
      ),
    ],
  }),
)
