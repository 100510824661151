import * as Ariakit from '@ariakit/react'
import React, {useState} from 'react'

import {cn, VariantsProps} from '../../utils'
import {cva} from 'class-variance-authority'
import {Merge} from '@cheddarup/util'

export interface SwitchProps
  extends Merge<Ariakit.CheckboxProps, Pick<SwitchIndicatorProps, 'size'>> {}

export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      size = 'md',

      defaultValue = false,
      value,
      store,

      className,
      disabled,
      onFocusVisible,
      onBlur,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const checkbox = Ariakit.useCheckboxContext()
    const [isFocusVisible, setIsFocusVisible] = useState(false)

    return (
      // biome-ignore lint/a11y/noLabelWithoutControl:
      <Ariakit.Role.label
        data-focus-visible={isFocusVisible || undefined}
        data-disabled={disabled}
        className={cn(
          'group/switch relative inline-flex cursor-pointer flex-row items-baseline gap-[0.5em]',
          'font-normal data-[disabled=true]:text-grey-300',
          // TODO: align first line with the switch thumb in size lg
          size === 'lg' ? 'items-center' : 'items-baseline',
          className,
        )}
      >
        <Ariakit.Checkbox
          ref={forwardedRef}
          role="switch"
          className="peer/input absolute z-[-1] h-px w-px overflow-hidden opacity-0"
          store={checkbox}
          disabled={disabled}
          value={value}
          onFocusVisible={(event) => {
            onFocusVisible?.(event)
            setIsFocusVisible(true)
          }}
          onBlur={(event) => {
            onBlur?.(event)
            setIsFocusVisible(false)
          }}
          {...restProps}
        />

        <SwitchIndicator className="shrink-0 items-center" size={size}>
          {/* Zero-width space character, used to align switch properly */}
          &#8203;
        </SwitchIndicator>

        {children}
      </Ariakit.Role.label>
    )
  },
)

// MARK: – SwitchIndicator

const switchIndicator = cva(
  [
    'group/indicator relative inline-flex bg-grey-300 shadow-[0px_0.6px_0.61px_#0000004D] transition',
    'peer-aria-checked/input:bg-teal-600',
    'peer-aria-disabled/input:bg-depr-grey-50',
    'peer-aria-disabled/input:peer-aria-checked/input:bg-teal-300',
    'group-data-[focus-visible]/switch:shadow-[inset_0_0_0_1px_theme(colors.teal.600)]',

    // thumb styling
    "before:m-0_5 before:block before:rounded-full before:bg-depr-grey-50 before:transition before:content-['']",
  ],
  {
    variants: {
      size: {
        sm: [
          'h-[15.59px] w-[26px] rounded-[18px] before:size-3',
          String.raw`peer-aria-checked/input:before:translate-x-[calc(26px-100%-theme(spacing.0\_5)*2)]`,
        ],
        md: [
          'h-[18px] w-[30px] rounded-[18px] before:size-[14px]',
          String.raw`peer-aria-checked/input:before:translate-x-[calc(30px-100%-theme(spacing.0\_5)*2)]`,
        ],
        lg: [
          'h-6 w-10 rounded-[34px] before:size-[21px]',
          String.raw`peer-aria-checked/input:before:translate-x-[calc(theme(width.10)-100%-theme(spacing.0\_5)*2)]`,
        ],
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
)

interface SwitchIndicatorProps
  extends React.ComponentPropsWithoutRef<'div'>,
    VariantsProps<typeof switchIndicator> {
  value?: string | number
}

const SwitchIndicator = React.forwardRef<HTMLDivElement, SwitchIndicatorProps>(
  ({size = 'md', className, ...restProps}, forwardedRef) => {
    return (
      <div
        ref={forwardedRef}
        className={cn(switchIndicator({size}), className)}
        {...restProps}
      />
    )
  },
)
