import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import React, {useMemo, useState} from 'react'
import {
  api,
  useUpdateParticipantMutation,
  useDeleteParticipantsMutation,
  useSendParticipantInviteMutation,
  useResendtParticipantInviteMutation,
} from '@cheddarup/api-client'
import {SearchForm} from 'src/components'
import {Link} from 'src/components/Link'
import {SearchFormProps} from 'src/components/SearchForm'
import {useCurrentFundraiserId} from '../hooks'
import {useUndoableMutation} from 'src/hooks/useUndoableMutation'
import {
  AddParticipantsModal,
  SendInvitesModal,
  ShareRegistrationLinkModal,
} from './modals'
import {menu} from 'framer-motion/client'
import {ref} from 'yup'
import participants from 'node_modules/@cheddarup/api-client/endpoints/participants'

export interface ParticipantsTableProps
  extends React.ComponentPropsWithoutRef<'div'> {
  fundraiserId: number
}

const PARTICIPANTS_PER_PAGE = 50
const columnHelper = WebUI.createColumnHelper<Api.FundraiserParticipant>()

const ParticipantsTable = ({
  fundraiserId,
  className,
  ...restProps
}: ParticipantsTableProps) => {
  const growlActions = WebUI.useGrowlActions()
  const updateParticipantMutation = useUpdateParticipantMutation()

  const [query, setQuery] = useState({
    page: 1,
    perPage: PARTICIPANTS_PER_PAGE,
    name_or_email: '',
  })
  const participantsQuery = api.participants.list.useQuery(
    {
      pathParams: {
        fundraiserId,
      },
      queryParams: query,
    },
    {placeholderData: (prevData) => prevData},
  )

  const {data: participants, pagination} = participantsQuery.data ?? {
    data: [],
    pagination: {},
  }

  const paginationState = useMemo(
    () => ({
      pageSize: PARTICIPANTS_PER_PAGE,
      pageIndex: query.page - 1,
    }),
    [query.page],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((p) => `${p.first_name} ${p.last_name}`, {
        id: 'name',
        size: 290,
        header: 'First and Last Name',
        cell: ({row: {original: participant}}) => (
          <WebUI.InlineEditInput<typeof WebUI.Input>
            InputComponent={WebUI.Input}
            inputClassName="z-[2]"
            defaultValue={`${participant.first_name} ${participant.last_name}`}
            inputSize="compact"
            inputOffset={[-8, -8]}
            onBlur={(event) => {
              const updatedName = event.target.value
              if (
                updatedName &&
                updatedName !==
                  `${participant.first_name} ${participant.last_name}`
              ) {
                const [first_name, last_name] = updatedName.split(' ')
                if (first_name && last_name) {
                  updateParticipantMutation.mutate({
                    pathParams: {
                      fundraiserId,
                      id: participant.id,
                    },
                    body: {first_name, last_name},
                  })
                } else {
                  growlActions.show('error', {
                    title: 'Error',
                    body: 'Please specify last name.',
                  })
                }
              }
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.currentTarget.blur()
              }
            }}
          />
        ),
      }),
      columnHelper.accessor((p) => p.email, {
        id: 'email',
        size: 330,
        header: 'Email',
      }),
      // TODO: add team column
      columnHelper.accessor((p) => p.donations, {
        id: 'donations',
        size: 150,
        header: 'Donations',
      }),
      columnHelper.accessor((p) => Util.formatAmount(p.totalCollected), {
        id: 'total',
        size: 150,
        header: 'Total Raised',
      }),
      columnHelper.display({
        id: 'status',
        minSize: 114,
        size: 260,
        header: 'Invite Status',
        cell: ({row: {original: participant}}) => (
          <ParticipantInviteStatus
            fundraiserId={fundraiserId}
            participant={participant}
          />
        ),
      }),
      columnHelper.display({
        id: 'actions',
        minSize: 44,
        size: 44,
        meta: {
          align: 'right',
        },
        cell: ({row: {original: participant}}) => (
          <ParticipantActionsDropdown
            participant={participant}
            fundraiserId={fundraiserId}
          />
        ),
      }),
    ],
    [updateParticipantMutation.mutate, growlActions.show, fundraiserId],
  )

  const pageCount = Math.ceil((pagination.total ?? 0) / PARTICIPANTS_PER_PAGE)

  return (
    <div className={WebUI.cn('flex flex-col gap-4', className)} {...restProps}>
      <div className="flex items-center justify-between">
        {/* <WebUI.Text className="text-ds-sm">
          Participants:{' '}
          {participantsQuery.isPending ? (
            <WebUI.Skeleton width={60} height={12} />
          ) : (
            participants.length
          )}
        </WebUI.Text> */}
        <WebUI.DropdownSelect
          variant="secondary"
          size="compact"
          value={'active'}
        >
          <WebUI.DropdownSelectOption value={'active'}>
            Active
          </WebUI.DropdownSelectOption>
          <WebUI.DropdownSelectOption value={'archive'}>
            Archived
          </WebUI.DropdownSelectOption>
        </WebUI.DropdownSelect>

        {participants.length > 0 && (
          <div className="flex gap-4">
            <ShareRegistrationLinkModal
              disclosure={
                <NextUI.DialogDisclosure
                  render={
                    <NextUI.NextButton
                      className="bg-teal-200 text-teal-600 hover:bg-teal-300"
                      size="md"
                    />
                  }
                >
                  <WebUI.PhosphorIcon className="text-ds-lg" icon="link" />
                  Share Registration Link
                </NextUI.DialogDisclosure>
              }
            />
            <AddParticipantsModal
              disclosure={
                <NextUI.DialogDisclosure
                  render={<NextUI.NextButton size="md" />}
                >
                  <WebUI.PhosphorIcon className="text-ds-lg" icon="user-plus" />
                  Add Participants
                </NextUI.DialogDisclosure>
              }
            />
            <SendInvitesModal
              fundraiserId={fundraiserId}
              participantIds={participants.map((p) => p.id)}
              disclosure={
                <NextUI.DialogDisclosure
                  render={<NextUI.NextButton size="md" />}
                >
                  <WebUI.PhosphorIcon
                    className="text-ds-lg"
                    icon="paper-plane-tilt"
                  />
                  Send Invites
                </NextUI.DialogDisclosure>
              }
            />
          </div>
        )}
      </div>
      <NextUI.Panel className="gap-0 p-0 sm:rounded-default">
        <WebUI.TableView
          columns={columns}
          loading={
            participantsQuery.isLoading || participantsQuery.isRefetching
          }
          data={participants}
          className={WebUI.cn(
            'text-grey-800 [&_.TableView-headerGroup]:px-0 sm:[&_.TableView-headerGroup]:px-4',
            '[&_.TableViewRow]:px-0 sm:[&_.TableViewRow]:px-4 sm:[&_.TableViewRow]:py-3',
            '[&_.TableViewCell]:min-h-10 [&_.TableViewCell]:items-center [&_.TableViewCell]:font-normal [&_.TableViewCell_.SubCell]:flex [&_.TableViewCell_.SubCell]:min-h-10 [&_.TableViewCell_.SubCell]:items-center',
          )}
          state={{
            pagination: paginationState,
          }}
          onPaginationChange={(updater) => {
            const newPagination =
              typeof updater === 'function' ? updater(paginationState) : updater
            setQuery((prevQuery) => ({
              ...prevQuery,
              page: newPagination.pageIndex + 1,
            }))
          }}
          pageCount={pageCount}
          manualPagination
          enableRowSelection
          selectAllVisible
          sortByTogglesVisible
          sortable
        >
          {(table) => (
            <>
              <ParticipantsTableViewToolbar
                className="-order-1"
                table={table}
                searchTerm={query.name_or_email}
                onSearch={(values) => {
                  if (values.term !== query.name_or_email) {
                    setQuery((prevQuery) => ({
                      ...prevQuery,
                      page: 1,
                      name_or_email: values.term,
                    }))
                  }
                }}
                noSearchResults={
                  query.name_or_email.length > 0 &&
                  participantsQuery.isSuccess &&
                  participants.length === 0
                }
              />
              {table.getPageCount() > 1 && (
                <div className="flex justify-end px-8 py-4">
                  <WebUI.TablePaginator />
                </div>
              )}
            </>
          )}
        </WebUI.TableView>
      </NextUI.Panel>
    </div>
  )
}

// MARK: - ParticipantsTableViewToolbar

export interface ParticipantsTableViewToolbarProps
  extends React.ComponentPropsWithoutRef<'div'> {
  table: WebUI.TableViewInstance<Api.FundraiserParticipant>
  searchTerm: string
  onSearch: SearchFormProps['onSubmit']
  noSearchResults: boolean
}

export const ParticipantsTableViewToolbar = ({
  table,
  className,
  onSearch,
  searchTerm,
  noSearchResults,
  ...restProps
}: ParticipantsTableViewToolbarProps) => {
  const fundraiserId = Number(useCurrentFundraiserId())
  const deleteParticipantsUndoableMutation = useUndoableMutation(
    useDeleteParticipantsMutation({timeout: 5000}),
  )

  const selectedParticipantIds = table
    .getSelectedRowModel()
    .flatRows.map((r) => r.original.id)

  return (
    <div
      className={WebUI.cn(
        'flex items-center justify-end gap-4 border-b px-4 py-3',
        className,
      )}
      {...restProps}
    >
      {selectedParticipantIds.length > 0 ? (
        <>
          <NextUI.Text className="font-extrabold">
            {selectedParticipantIds.length} Selected Participant
          </NextUI.Text>
          <ParticipantsBulkActionConfirmationAlert
            heading="Delete Participant(s)"
            description="Are you sure? When you delete a participant their fundraising page can not be restored."
            actionType="delete"
            onExecute={() =>
              deleteParticipantsUndoableMutation.mutateAsyncWithUndo(
                {
                  description: 'If this was done in error, you can undo',
                },
                {
                  pathParams: {
                    fundraiserId,
                  },
                  body: {participant_ids: selectedParticipantIds},
                },
              )
            }
            disclosure={
              <WebUI.DialogDisclosure variant="outlined" as={NextUI.NextButton}>
                <WebUI.PhosphorIcon
                  className="text-ds-base text-orange-500"
                  icon="trash"
                />
                Delete
              </WebUI.DialogDisclosure>
            }
          />
          <ParticipantsBulkActionConfirmationAlert
            heading="Archive Participant(s)"
            description="Are you sure? Archived participants will be hidden from your main fundraising page and teams (if applicable). Their fundraising page will be also be closed."
            actionType="archive"
            onExecute={() => console.log(selectedParticipantIds)}
            disclosure={
              <WebUI.DialogDisclosure variant="outlined" as={NextUI.NextButton}>
                <WebUI.PhosphorIcon
                  className="text-ds-base text-orange-500"
                  icon="archive"
                />
                Archive
              </WebUI.DialogDisclosure>
            }
          />
          {/* TODO: assign to team dropdown */}
        </>
      ) : (
        <SearchForm
          className="min-w-80 text-ds-xs placeholder:text-ds-xs"
          iconClassName="text-gray400"
          size="compact"
          initialValues={{term: searchTerm}}
          onSubmit={onSearch}
          noResult={noSearchResults}
          placeholder="Search participant by name or email"
        />
      )}
    </div>
  )
}

// MARK: – ParticipantActionsDropdown

interface ParticipantActionsDropdownProps
  extends WebUI.MenuButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  participant: Api.FundraiserParticipant
  fundraiserId: number
}

const ParticipantActionsDropdown = React.forwardRef<
  HTMLButtonElement,
  ParticipantActionsDropdownProps
>(({participant, fundraiserId, className, ...restProps}, forwardedRef) => {
  const updateParticipantMutation = useUpdateParticipantMutation()
  const deleteParticipantsUndoableMutation = useUndoableMutation(
    useDeleteParticipantsMutation({timeout: 5000}),
  )

  return (
    <WebUI.Menu placement="bottom-start">
      <WebUI.MenuButton
        ref={forwardedRef}
        className={WebUI.cn(' text-grey-700', className)}
        as={WebUI.IconButton}
        size="compact"
        {...restProps}
      >
        <WebUI.PhosphorIcon icon="dots-three-outline-fill" width={25} />
      </WebUI.MenuButton>

      <WebUI.MenuList className="font-light text-ds-sm" shrinkable={false}>
        {(participant.invite_email_status === 'active' ||
          participant.invite_email_status === 'failed') && (
          <WebUI.MenuItem>Resend Invite</WebUI.MenuItem>
        )}
        {participant.invite_email_status === 'sent' && (
          <WebUI.MenuItem as={Link} variant="default" to={'#'}>
            View Participant Page
          </WebUI.MenuItem>
        )}
        {!participant.archived && (
          <WebUI.MenuItem
            className="font-normal text-ds-sm text-orange-600"
            hideOnClick={false}
          >
            <ParticipantsBulkActionConfirmationAlert
              heading="Archive Participant"
              description="Are you sure? Archived participants will be hidden from your main fundraising page and teams (if applicable). Their fundraising page will be also be closed."
              actionType="archive"
              onExecute={async () => {
                await updateParticipantMutation.mutateAsync({
                  pathParams: {fundraiserId, id: participant.id},
                  body: {
                    archived: true,
                  },
                })
              }}
              disclosure={
                <WebUI.DialogDisclosure as={NextUI.Text}>
                  Archive Participant
                </WebUI.DialogDisclosure>
              }
            />
          </WebUI.MenuItem>
        )}
        {participant.archived && (
          <WebUI.MenuItem>Restore Participant</WebUI.MenuItem>
        )}
        {participant.archived && (
          <WebUI.MenuItem
            className="font-normal text-ds-sm text-orange-600"
            hideOnClick={false}
          >
            <ParticipantsBulkActionConfirmationAlert
              heading="Delete Participant"
              description="Are you sure? When you delete a participant their fundraising page can not be restored."
              actionType="delete"
              onExecute={() => {
                deleteParticipantsUndoableMutation.mutateAsyncWithUndo(
                  {
                    description: 'If this was done in error, you can undo',
                  },
                  {
                    pathParams: {
                      fundraiserId,
                    },
                    body: {participant_ids: [participant.id]},
                  },
                )
              }}
              disclosure={
                <WebUI.DialogDisclosure as={NextUI.Text}>
                  Delete Participant
                </WebUI.DialogDisclosure>
              }
            />
          </WebUI.MenuItem>
        )}
      </WebUI.MenuList>
    </WebUI.Menu>
  )
})

// MARK: – ParticipantInviteStatus

interface ParticipantInviteStatusProps
  extends React.ComponentPropsWithoutRef<'div'> {
  fundraiserId: number
  participant: Api.FundraiserParticipant
}

const ParticipantInviteStatus = ({
  participant,
  fundraiserId,
  className,
  ...restProps
}: ParticipantInviteStatusProps) => {
  const updateParticipantMutation = useUpdateParticipantMutation()
  const sendParticipantInviteMutation = useSendParticipantInviteMutation()
  const resendParticipantInviteMutation = useResendtParticipantInviteMutation()

  const inviteStatus = participant.invite_email_status
  const parseParticipantInviteStatus = () => {
    if (!inviteStatus) {
      return 'Needs Invite'
    }
    return {
      active: 'Active',
      sent: 'Invite Sent',
      failed: 'Failed',
    }[inviteStatus]
  }

  return (
    <div className={WebUI.cn('flex gap-3', className)} {...restProps}>
      <WebUI.Tooltip>
        <WebUI.TooltipAnchor
          className={WebUI.cn(
            'flex min-w-32 items-center gap-2 rounded-default border px-3 py-2 text-ds-xs text-grey-800',
            inviteStatus === 'active' && 'bg-teal-100',
            inviteStatus === 'failed' && 'bg-orange-100',
            participant.archived && 'bg-grey-100',
          )}
        >
          <WebUI.PhosphorIcon
            className={WebUI.cn(
              'text-ds-lg',
              inviteStatus && inviteStatus !== 'failed'
                ? 'text-teal-50'
                : 'text-orange-500',
              participant.archived && 'text-teal-50',
            )}
            icon={
              participant.archived
                ? 'archive'
                : {
                    sent: 'paper-plane-tilt',
                    active: 'check-circle',
                    failed: 'x-circle',
                    warning: 'warning-circle',
                  }[inviteStatus ?? 'warning']
            }
          />
          {participant.archived ? 'Archived' : parseParticipantInviteStatus()}
        </WebUI.TooltipAnchor>
        <WebUI.TooltipContent variant="light">
          {participant.archived
            ? 'Archived participants can be restored to active status.'
            : {
                sent: "Your invitation has been sent and the participant's page has been published.",
                active:
                  'Participant has opened the invitation and can edit his/her participant page.',
                failed:
                  'Invites can fail if an email address is invalid, please update it with the correct one to fix error.',
                noInvite:
                  'This participant has not yet received an invitation to view and edit their participant page.',
              }[inviteStatus ?? 'noInvite']}
        </WebUI.TooltipContent>
      </WebUI.Tooltip>
      {inviteStatus !== 'sent' && (
        <NextUI.NextButton
          variant="headless"
          className="text-teal-50"
          loading={
            updateParticipantMutation.isPending ||
            sendParticipantInviteMutation.isPending ||
            resendParticipantInviteMutation.isPending
          }
          onClick={async () => {
            if (participant.archived) {
              await updateParticipantMutation.mutateAsync({
                pathParams: {fundraiserId, id: participant.id},
                body: {
                  archived: false,
                },
              })
            } else if (inviteStatus) {
              await resendParticipantInviteMutation.mutateAsync({
                pathParams: {fundraiserId, id: participant.id},
              })
            } else {
              await sendParticipantInviteMutation.mutateAsync({
                pathParams: {fundraiserId},
                body: {
                  type: 'specific',
                  participant_ids: [participant.id],
                },
              })
            }
          }}
        >
          {participant.archived
            ? 'Restore'
            : inviteStatus
              ? 'Resend Invite'
              : 'Send Invite'}
        </NextUI.NextButton>
      )}
    </div>
  )
}

// MARK: - ParticipantsBulkActionConfirmationAlert

export interface ParticipantsBulkActionConfirmationAlertProps
  extends WebUI.AlertProps {
  heading: string
  description: string
  actionType: 'delete' | 'archive'
  onExecute: () => void
}

export const ParticipantsBulkActionConfirmationAlert = React.forwardRef<
  WebUI.DialogInstance,
  ParticipantsBulkActionConfirmationAlertProps
>(
  (
    {heading, description, actionType, onExecute, ...restProps},
    forwardedRef,
  ) => (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label={`${actionType} participant list confirmation`}
      {...restProps}
    >
      <WebUI.AlertHeader>
        <NextUI.Text className="font-extrabold text-ds-lg">
          {heading}
        </NextUI.Text>
      </WebUI.AlertHeader>
      <WebUI.AlertContentView
        className="text-ds-sm text-grey-800 [&_>_.AlertContentView-text]:font-normal"
        text={description}
        actions={
          <WebUI.AlertActionButton
            execute={onExecute}
            variant="primary"
            size="default"
          >
            {Util.capitalize(actionType)}
          </WebUI.AlertActionButton>
        }
      />
    </WebUI.Alert>
  ),
)

export default ParticipantsTable
