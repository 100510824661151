import React, {useRef} from 'react'
import {DateValue, useDateField} from 'react-aria'
import {DateFieldStateOptions, useDateFieldState} from 'react-stately'
import {useForkRef} from 'reakit-utils'
import {GregorianCalendar} from '@internationalized/date'
import {SetOptional, SimpleMerge} from '@cheddarup/util'
import {genericForwardRef} from '@cheddarup/react-util'

import {DateSegment} from './DateSegment'
import {InputBox, InputBoxProps} from './Input'

export interface DateInputProps<T extends DateValue>
  extends Omit<
    SimpleMerge<InputBoxProps, SetOptional<DateFieldStateOptions<T>, 'locale'>>,
    'createCalendar' | 'onChange' | 'children'
  > {
  onValueChange?: DateFieldStateOptions<T>['onChange']
  children?: (dateSegments: React.ReactNode) => React.ReactNode
}

export const DateInput = genericForwardRef(
  <T extends DateValue>(
    {
      'aria-invalid': ariaInvalid,
      variant = 'default',
      size = 'default',
      roundness = 'default',
      locale = 'en-US',
      onValueChange,
      disabled,
      required,
      readOnly,
      children,
      className,
      style,
      ...restProps
    }: DateInputProps<T>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const state = useDateFieldState({
      createCalendar,
      locale,
      isDisabled: disabled,
      isReadOnly: readOnly,
      isRequired: required,
      onChange: onValueChange,
      ...restProps,
    })
    const {fieldProps} = useDateField(restProps, state, ownRef)

    const dateSegments = state.segments.map((segment, idx) => (
      <DateSegment key={idx} segment={segment} state={state} />
    ))

    return (
      <InputBox
        ref={ref}
        aria-invalid={ariaInvalid}
        className={className}
        style={style}
        size={size}
        variant={variant}
        roundness={roundness}
        {...fieldProps}
      >
        {children ? children(dateSegments) : dateSegments}
      </InputBox>
    )
  },
)

// MARK: – Helpers

// See https://react-spectrum.adobe.com/react-aria/useDateField.html#reducing-bundle-size
export function createCalendar(identifier: string) {
  switch (identifier) {
    case 'gregory':
      return new GregorianCalendar()
    default:
      throw new Error(`Unsupported calendar ${identifier}`)
  }
}
