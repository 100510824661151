import {
  api,
  useCreateTabTeamMutation,
  useDeleteTabTeamMutation,
  useUpdateTabTeamMutation,
} from '@cheddarup/api-client'
import {useForkRef, useZodFormik} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import {z} from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import React, {useMemo, useRef} from 'react'
import {
  FormikField,
  FormikForm,
  FormikSubmitButton,
} from 'src/components/Formik'
import AddTeamssImage from 'src/images/Fundraiser-Add-Teams.png'
import {useCurrentFundraiserId} from '../hooks'
import {guessError} from 'src/helpers/error-utils'
import {useUndoableMutation} from 'src/hooks/useUndoableMutation'

export const TeamsTab = () => {
  const fundraiserId = useCurrentFundraiserId()
  const teamsQuery = api.tabs.listTeams.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      tabId: fundraiserId!,
    },
  })

  const columnHelper = useMemo(
    () => NextUI.createColumnHelper<Api.TabTeam>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((t) => t.name, {
        id: 'name',
        header: 'Team',
      }),
      columnHelper.accessor((t) => t.participants, {
        id: 'participants',
        header: 'Participants',
      }),
      columnHelper.accessor((t) => t.donations, {
        id: 'donations',
        header: '# of Donations',
      }),
      columnHelper.accessor((t) => t.totalCollected, {
        id: 'totalCollected',
        header: 'Total Raised',
        cell: ({cell}) => Util.formatAmount(cell.getValue()),
      }),
      columnHelper.display({
        id: 'actions',
        size: 40,
        cell: ({row: {original: team}}) => (
          <TeamActionsMenu variant="transparent" team={team}>
            <WebUI.PhosphorIcon icon="dots-three-outline-fill" />
          </TeamActionsMenu>
        ),
      }),
    ],
    [columnHelper],
  )

  if (teamsQuery.isSuccess && teamsQuery.data.length === 0) {
    return <TeamsEmptyState />
  }

  return (
    <div className="flex flex-col gap-4">
      <NextUI.TableProvider columns={columns} data={teamsQuery.data ?? []}>
        {(table) => (
          <>
            <div className="flex items-center justify-between gap-4">
              <div />
              <AddTeamsAlert
                disclosure={
                  <NextUI.DialogDisclosure
                    render={<NextUI.NextButton size="md" />}
                  >
                    Add Teams
                  </NextUI.DialogDisclosure>
                }
              />
            </div>
            <div className="divide-y overflow-x-auto rounded border bg-trueWhite">
              <div className="flex items-center justify-between gap-4 px-4 py-3">
                <span className="font-semibold">
                  Teams: {table.getRowCount()}
                </span>
              </div>
              <NextUI.TableView />
            </div>
          </>
        )}
      </NextUI.TableProvider>
    </div>
  )
}

// MARK: – TeamActionsMenu

interface TeamActionsMenuProps extends NextUI.NextButtonProps {
  team: Api.TabTeam
}

const TeamActionsMenu = ({team, ...restProps}: TeamActionsMenuProps) => {
  const fundraiserId = useCurrentFundraiserId()
  const deleteTabTeamUndoableMutation = useUndoableMutation(
    useDeleteTabTeamMutation({timeout: 8000}),
  )

  return (
    <NextUI.Menu>
      {(menu) => (
        <>
          <NextUI.MenuButton {...restProps} />
          <NextUI.MenuList>
            <EditTeamAlert
              team={team}
              onDidSubmit={() => menu.hide()}
              disclosure={
                <NextUI.DialogDisclosure
                  render={<NextUI.MenuItem hideOnClick={false} />}
                >
                  Edit team
                </NextUI.DialogDisclosure>
              }
            />

            <NextUI.MenuItem
              className="text-orange-500"
              onClick={() =>
                deleteTabTeamUndoableMutation.mutateWithUndo(
                  {
                    description: `You’ve deleted team "${team.name}"`,
                  },
                  {
                    pathParams: {
                      // biome-ignore lint/style/noNonNullAssertion: <explanation>
                      tabId: fundraiserId!,
                      teamId: team.id,
                    },
                  },
                )
              }
            >
              Delete team
            </NextUI.MenuItem>
          </NextUI.MenuList>
        </>
      )}
    </NextUI.Menu>
  )
}

// MARK: - TeamsEmptyState

const TeamsEmptyState = () => (
  <NextUI.Panel className="grow flex-row gap-6 px-9 py-10 sm:rounded-default">
    <NextUI.Image
      src={AddTeamssImage}
      alt="participant"
      width={150}
      height={186}
    />
    <div className="flex flex-col gap-4">
      <NextUI.PanelLabel className="font-extrabold">
        Enable team engagement on your fundraiser
      </NextUI.PanelLabel>
      <NextUI.Text className="text-ds-base">
        Boost engagement and build team spirit by organizing participants into
        teams! It’s an ideal setup for grouping participants by grade levels,
        classes, or other group types. This approach encourages collaboration,
        friendly competition, and shared goals, creating a more dynamic
        experience for everyone during the fundraiser.
      </NextUI.Text>
      <div className="flex flex-row gap-4">
        <AddTeamsAlert
          disclosure={
            <NextUI.DialogDisclosure render={<NextUI.NextButton size="md" />}>
              Add Teams
            </NextUI.DialogDisclosure>
          }
        />
      </div>
    </div>
  </NextUI.Panel>
)

// MARK: – EditTeamAlert

const editTeamFormSchema = z.object({
  name: z.string(),
})

interface EditTeamAlertProps extends NextUI.AlertProps {
  team: Api.TabTeam
  onDidSubmit?: () => void
}

const EditTeamAlert = React.forwardRef<
  NextUI.DialogInstance,
  EditTeamAlertProps
>(({team, onDidSubmit, ...restProps}, forwardedRef) => {
  const dialogRef = useRef<NextUI.DialogInstance>(null)
  const ref = useForkRef(forwardedRef, dialogRef)
  const fundraiserId = useCurrentFundraiserId()
  const updateTabTeamMutation = useUpdateTabTeamMutation()
  const toastsActions = NextUI.useToastsActions()

  const formik = useZodFormik({
    schema: editTeamFormSchema,
    initialValues: {name: team.name},
    onSubmit: async (values) => {
      try {
        await updateTabTeamMutation.mutateAsync({
          pathParams: {
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            tabId: fundraiserId!,
            teamId: team.id,
          },
          body: {
            team: {
              name: values.name,
            },
          },
        })

        onDidSubmit?.()

        toastsActions.show('success', {
          title: 'Added teams',
          description: 'Team has been successfully edited',
        })

        dialogRef.current?.hide()
      } catch (err) {
        toastsActions.show('error', {
          title: 'Error',
          description: guessError(err).message,
        })
      }
    },
  })

  return (
    <NextUI.Alert ref={ref} role="dialog" {...restProps}>
      <NextUI.AlertHeader>Edit Team</NextUI.AlertHeader>
      <FormikForm className="contents" formik={formik}>
        <NextUI.AlertMain>
          <FormikField
            label="Team name"
            name="name"
            control={<NextUI.Input />}
          />
        </NextUI.AlertMain>

        <NextUI.AlertFooter>
          <FormikSubmitButton variant="orange">Save</FormikSubmitButton>
        </NextUI.AlertFooter>
      </FormikForm>
    </NextUI.Alert>
  )
})

// MARK: – EditTeamAlert

const addTeamsFormSchema = z.object({
  teamNamesAndIds: z.array(z.union([z.string(), z.number()])),
})

interface AddTeamsAlertProps extends NextUI.AlertProps {}

const AddTeamsAlert = React.forwardRef<
  NextUI.DialogInstance,
  AddTeamsAlertProps
>((props, forwardedRef) => {
  const dialogRef = useRef<NextUI.DialogInstance>(null)
  const ref = useForkRef(forwardedRef, dialogRef)
  const fundraiserId = useCurrentFundraiserId()
  const teamsQuery = api.tabs.listTeams.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      tabId: fundraiserId!,
    },
  })
  const createTabTeamMutation = useCreateTabTeamMutation()
  const deleteTabTeamMutation = useDeleteTabTeamMutation()
  const toastsActions = NextUI.useToastsActions()

  const formik = useZodFormik({
    schema: addTeamsFormSchema,
    initialValues: teamsQuery.data
      ? {teamNamesAndIds: teamsQuery.data.map((t) => t.id)}
      : undefined,
    onSubmit: async (values) => {
      const newTeamNames = values.teamNamesAndIds.filter(
        (teamNameOrId) => typeof teamNameOrId === 'string',
      )

      const teamIds = values.teamNamesAndIds.filter(
        (teamNameOrId) => typeof teamNameOrId === 'number',
      )
      const existingTeamIds = teamsQuery.data?.map((t) => t.id) ?? []
      const teamIdsToDelete = Util.difference(existingTeamIds, teamIds)

      await Promise.all([
        ...newTeamNames.map((teamName) =>
          createTabTeamMutation.mutateAsync({
            pathParams: {
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              tabId: fundraiserId!,
            },
            body: {team: {name: teamName}},
          }),
        ),
        ...teamIdsToDelete.map((teamId) =>
          deleteTabTeamMutation.mutateAsync({
            pathParams: {
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              tabId: fundraiserId!,
              teamId,
            },
          }),
        ),
      ])

      toastsActions.show('success', {
        title: 'Added teams',
        description: 'Teams have been successfully added',
      })

      dialogRef.current?.hide()
    },
  })

  return (
    <NextUI.Alert ref={ref} role="dialog" {...props}>
      <NextUI.AlertHeader>Add Teams</NextUI.AlertHeader>
      <FormikForm className="contents" formik={formik}>
        <NextUI.AlertMain>
          <FormikField
            label="Enter the team names you’d like to include in your fundraiser."
            name="teamNamesAndIds"
            control={(fieldProps, field) => (
              <TeamsCombobox
                name={fieldProps.name}
                teams={teamsQuery.data ?? []}
                initialSelectedTeamIds={fieldProps.value}
                onTeamNamesAndIdsChange={(newTeams) => field.setValue(newTeams)}
              />
            )}
          />
        </NextUI.AlertMain>

        <NextUI.AlertFooter>
          <FormikSubmitButton variant="orange">Add Teams</FormikSubmitButton>
        </NextUI.AlertFooter>
      </FormikForm>
    </NextUI.Alert>
  )
})

// MARK: – TeamsCombobox

interface TeamsComboboxProps
  extends WebUI.ComboboxInputProps,
    WebUI.InputProps,
    Omit<React.ComponentPropsWithoutRef<'input'>, 'size'> {
  teams: Api.TabTeam[]
  initialSelectedTeamIds: number[]
  onTeamNamesAndIdsChange?: (teamNamesAndIds: Array<number | string>) => void
}

const TeamsCombobox = React.forwardRef<HTMLInputElement, TeamsComboboxProps>(
  (
    {teams, initialSelectedTeamIds, onTeamNamesAndIdsChange, ...restProps},
    forwardedRef,
  ) => {
    const initialSelectedItems = initialSelectedTeamIds.map((teamId) => ({
      label: teams.find((t) => t.id === teamId)?.name ?? '',
      value: String(teamId),
    }))

    return (
      <WebUI.MultiCombobox
        creatable
        initialSelectedItems={initialSelectedItems}
        onSelectedItemsChange={async (changes) => {
          console.log('wtf changes:', changes)

          onTeamNamesAndIdsChange?.(
            changes.selectedItems
              .map((i) =>
                i.value === '__create__' ? i.label : Number(i.value),
              )
              .filter((v) => v !== undefined)
              .filter((v) => !!v),
          )
        }}
      >
        <WebUI.MultiComboboxStatefulInput
          ref={forwardedRef}
          placeholder="Enter Team"
          {...restProps}
        />

        <WebUI.ComboboxPopover>
          <WebUI.ComboboxList>
            {teams.map((team) => (
              <WebUI.ComboboxOption
                key={team.id}
                value={String(team.id)}
                label={team.name}
              />
            ))}
            <WebUI.ComboboxOptionCreate
              sortingScoreMultiplier={999}
              value="__create__"
            />
          </WebUI.ComboboxList>
        </WebUI.ComboboxPopover>
      </WebUI.MultiCombobox>
    )
  },
)
