import React, {useContext} from 'react'
import {
  NavLinkProps,
  UNSAFE_DataRouterStateContext,
  useLocation,
  useResolvedPath,
} from 'react-router-dom'
import {NextAnchorButton, NextAnchorButtonProps} from '@cheddarup/web-ui/next'
import {NextLink, NextLinkProps} from './Link'
import * as Util from '@cheddarup/util'

export interface NextLinkButtonProps
  extends Util.Merge<NextLinkProps, NextAnchorButtonProps> {}

export const NextLinkButton = React.forwardRef<
  HTMLAnchorElement,
  NextLinkButtonProps
>(({disabled, to, ...restProps}, forwardedRef) => {
  return (
    <NextAnchorButton
      ref={forwardedRef}
      render={<NextLink variant="text" disabled={disabled} to={to} />}
      {...restProps}
    />
  )
})

// MARK: – NextNavLinkButton

export interface NextNavLinkButtonProps
  extends NextLinkButtonProps,
    Pick<NavLinkProps, 'caseSensitive' | 'end'>,
    React.ComponentPropsWithoutRef<'a'> {}

export const NextNavLinkButton = React.forwardRef<
  HTMLAnchorElement,
  NextNavLinkButtonProps
>(({to, caseSensitive = false, end = false, ...restProps}, forwardedRef) => {
  const location = useLocation()
  const path = useResolvedPath(to)
  const routerState = useContext(UNSAFE_DataRouterStateContext)

  let locationPathname = location.pathname
  let toPathname = path.pathname
  let nextLocationPathname = routerState?.navigation?.location
    ? routerState.navigation.location.pathname
    : null

  if (!caseSensitive) {
    locationPathname = locationPathname.toLowerCase()
    nextLocationPathname = nextLocationPathname?.toLowerCase() ?? null
    toPathname = toPathname.toLowerCase()
  }

  const isActive =
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === '/')

  return (
    <NextLinkButton
      ref={forwardedRef}
      aria-current={isActive ? 'page' : undefined}
      to={to}
      {...restProps}
    />
  )
})
