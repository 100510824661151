import * as Ariakit from '@ariakit/react'
import React from 'react'
import {cva} from 'class-variance-authority'

import {VariantsProps, cn} from '../../utils'

export const separator = cva(
  [
    'self-stretch border-0 border-solid p-0',
    `aria-orientation-horizontal:max-h-0 aria-orientation-horizontal:w-auto
    aria-orientation-horizontal:border-b`,
    `aria-orientation-vertical:h-auto aria-orientation-vertical:max-w-0
    aria-orientation-vertical:border-r`,
  ],
  {
    variants: {
      variant: {
        dark: 'border-grey-200',
        light: 'border-depr-grey-50',
        black: 'border-gray400',
        // TODO: `primary` variant is used as PanelSeparator only,
        // add it to Panel.js and use from there
        primary: 'border-grey-300',
        input: 'border-grey-300',
      },
    },
    defaultVariants: {
      variant: 'dark',
    },
  },
)

export interface SeparatorProps
  extends Ariakit.SeparatorProps,
    VariantsProps<typeof separator> {}

export const Separator = React.forwardRef<HTMLHRElement, SeparatorProps>(
  ({className, variant = 'dark', ...restProps}, forwardedRef) => (
    <Ariakit.Separator
      ref={forwardedRef}
      className={cn(separator({variant}), className)}
      {...restProps}
    />
  ),
)
