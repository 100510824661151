import * as WebUI from '@cheddarup/web-ui'
import {useMemo, useState} from 'react'
import {api} from '@cheddarup/api-client'
import {useUpdateEffect} from '@cheddarup/react-util'
import {Text} from '@cheddarup/web-ui/next'

export interface ParticipantsAutosuggestComboboxProps
  extends Omit<WebUI.ComboboxProps, 'selectedItem'>,
    Pick<WebUI.InputProps, 'size'> {
  selectedParticipants: Api.FundraiserParticipant[]
  fundraiserId: number
  onSelectedParticipantChange?: (
    selectedParticipant: Api.FundraiserParticipant | null,
  ) => void
}

export const ParticipantsAutosuggestCombobox = ({
  size,
  selectedParticipants,
  onSelectedParticipantChange,
  inputValue: inputValueProp,
  onInputValueChange,
  fundraiserId,
  ...restProps
}: ParticipantsAutosuggestComboboxProps) => {
  const [inputValue, setInputValue] = useState(inputValueProp ?? '')

  const autoSuggestParticipantsQuery = api.participants.search.useQuery(
    {
      pathParams: {
        fundraiserId,
      },
      queryParams: {
        keyword: inputValue,
      },
    },
    {
      enabled: inputValue.length > 2,
    },
  )

  useUpdateEffect(() => {
    if (inputValueProp != null) {
      setInputValue(inputValueProp)
    }
  }, [inputValueProp])

  const options = useMemo(
    () =>
      autoSuggestParticipantsQuery.data?.map(
        (p): WebUI.ComboboxItem => ({
          value: p.email,
          labelEl: (
            <div className="flex gap-2">
              <WebUI.Avatar size={40} name={`${p.first_name} ${p.last_name}`} />
              <div className="flex flex-col gap-1">
                <WebUI.Ellipsis className="text-ds-base">
                  {p.first_name} {p.last_name}
                </WebUI.Ellipsis>
                {p.team ? (
                  <Text>{p.team?.name}</Text>
                ) : (
                  <WebUI.ComboboxOptionText className="text-gray400" />
                )}
              </div>
            </div>
          ),
        }),
      ) ?? [],
    [autoSuggestParticipantsQuery.data],
  )

  return (
    <WebUI.Combobox
      onSelectedItemChange={(change) => {
        if (
          change.type ===
          WebUI.useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem
        ) {
          return
        }

        const newSelectedOption = change.selectedItem
        if (!newSelectedOption) {
          return
        }

        const newSelectedParticipant = autoSuggestParticipantsQuery.data?.find(
          (r) => r.email === newSelectedOption.value,
        )
        if (newSelectedParticipant) {
          onSelectedParticipantChange?.(newSelectedParticipant)
        }
      }}
      onInputValueChange={(changes) => {
        if (changes.type !== WebUI.useCombobox.stateChangeTypes.InputChange) {
          return
        }

        if (!changes.inputValue) {
          onSelectedParticipantChange?.(null)
        }
        onInputValueChange?.(changes)
        setInputValue(changes.inputValue ?? '')
      }}
      {...restProps}
    >
      <WebUI.ComboboxInput
        className="[&_>_.ComboboxInput-chevron]:left-2 [&_>_.ComboboxInput-chevron]:text-grey-500 [&_>_.ComboboxInput-input]:pl-7"
        placeholder="Search participant by name or email"
        size={size}
        chevronIconName="magnifying-glass"
        value={inputValue}
        onClear={() => {
          setInputValue('')
          onSelectedParticipantChange?.(null)
        }}
      />

      <WebUI.ComboboxPopover>
        <WebUI.ComboboxList
          noResultsFoundText={
            inputValue.length < 4
              ? 'Please type more than 3 characters'
              : autoSuggestParticipantsQuery.isPending
                ? 'Searching...'
                : undefined
          }
        >
          {options.map((o) => (
            <WebUI.ComboboxOption
              selectedStyle="bg"
              key={o.value}
              className="h-auto px-3 py-2"
              {...o}
            />
          ))}
        </WebUI.ComboboxList>
      </WebUI.ComboboxPopover>
    </WebUI.Combobox>
  )
}
