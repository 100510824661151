import {uploadImage} from '@cheddarup/core'
import {
  useAsyncCallback,
  useAsyncEffect,
  useDebounceValue,
  useForkRef,
  useFormik,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import React, {useImperativeHandle, useMemo, useRef, useState} from 'react'
import {useManagerRoleId} from './ManageRoleProvider'
import {CameraModal, CameraModalInstance} from './CameraModal'
import {createApi} from 'unsplash-js'
import SearchForm from './SearchForm'
import type {Basic} from 'unsplash-js/dist/methods/photos/types'
import {DashboardPageFooter} from './DashboardPageLayout'
import {FormikForm, FormikSubmitButton} from './Formik'

// MARK: – UploadPinturaImageFormModal

export interface UploadPinturaImageFormModalProps
  extends WebUI.ModalProps,
    Pick<
      UploadPinturaImageFormProps,
      | 'parentPath'
      | 'imageType'
      | 'noOriginalImage'
      | 'placeholderSrc'
      | 'initialImage'
      | 'image'
      | 'utils'
      | 'imageCropAspectRatio'
      | 'imageCropLimitToImage'
      | 'circularCrop'
      | 'uploaderOptions'
      | 'onSubmitAsPinturaRes'
    > {
  heading?: React.ReactNode
  onImageSubmit?: UploadPinturaImageFormProps['onSubmit']
}

export const UploadPinturaImageFormModal = React.forwardRef<
  WebUI.DialogInstance,
  UploadPinturaImageFormModalProps
>(
  (
    {
      heading = 'Add Image',
      parentPath,
      imageType,
      noOriginalImage,
      placeholderSrc,
      initialImage,
      image,
      imageCropAspectRatio,
      imageCropLimitToImage,
      circularCrop,
      uploaderOptions,
      utils,
      onImageSubmit,
      onSubmitAsPinturaRes,
      initialVisible = false,
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <WebUI.Modal
      ref={forwardedRef}
      aria-label="Image picker"
      className={WebUI.cn('[&_>.ModalContentView]:h-full', className)}
      initialVisible={initialVisible}
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />

          <WebUI.ModalHeader>{heading}</WebUI.ModalHeader>

          <UploadPinturaImageForm
            className="min-h-0 grow"
            parentPath={parentPath}
            imageType={imageType}
            noOriginalImage={noOriginalImage}
            placeholderSrc={placeholderSrc}
            initialImage={initialImage}
            image={image}
            imageCropAspectRatio={imageCropAspectRatio}
            imageCropLimitToImage={imageCropLimitToImage}
            circularCrop={circularCrop}
            uploaderOptions={uploaderOptions}
            utils={utils}
            onSubmitAsPinturaRes={
              onSubmitAsPinturaRes
                ? (newPinturaRes) => {
                    onSubmitAsPinturaRes(newPinturaRes)
                    dialog.hide()
                  }
                : undefined
            }
            onSubmit={(newImage) => {
              onImageSubmit?.(newImage)
              dialog.hide()
            }}
          />
        </>
      )}
    </WebUI.Modal>
  ),
)

// MARK: – UploadPinturaImageForm

export interface UploadPinturaImageFormValues {
  src: string | Blob
}

export interface UploadPinturaImageFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'>,
    Pick<
      WebUI.PinturaEditorProps,
      | 'utils'
      | 'imageCropAspectRatio'
      | 'circularCrop'
      | 'imageCropLimitToImage'
    >,
    Pick<UploadPinturaImageProps, 'uploaderOptions'> {
  parentPath?: string
  imageType?: Api.S3ImageMetadataType
  noOriginalImage?: boolean
  placeholderSrc?: string | Blob | null
  initialImage?: Api.S3Image | Blob | null
  image?: Api.S3Image | Blob | null
  onSubmitAsPinturaRes?: (
    pinturaRes: WebUI.PinturaDefaultImageWriterResult,
  ) => void
  onSubmit?: (image: Api.S3Image) => void
}

export const UploadPinturaImageForm = ({
  parentPath,
  imageType,
  noOriginalImage,
  placeholderSrc,
  initialImage,
  image,
  onSubmitAsPinturaRes,
  onSubmit,
  imageCropAspectRatio,
  imageCropLimitToImage = true,
  circularCrop,
  utils,
  uploaderOptions,
  className,
  ...restProps
}: UploadPinturaImageFormProps) => {
  const [managerRoleId] = useManagerRoleId()
  const pinturaEditorRef = useRef<WebUI.PinturaEditorInstance>(null)
  const growlActions = WebUI.useGrowlActions()

  const defaultImage = image ?? initialImage

  const formik = useFormik({
    initialValues: {
      src:
        defaultImage instanceof Blob
          ? defaultImage
          : (defaultImage?.url ?? placeholderSrc),
    },
    onSubmit: async (values) => {
      if (!pinturaEditorRef.current) {
        return
      }

      const pinturaRes = await pinturaEditorRef.current.editor.processImage()

      if (onSubmitAsPinturaRes) {
        onSubmitAsPinturaRes(pinturaRes)
        return
      }

      if (
        values.src &&
        (initialImage instanceof Blob ||
          values.src !== initialImage?.url ||
          !initialImage?.metadata.pintura ||
          !Util.hasSubObject(
            pinturaRes.imageState as any,
            initialImage.metadata.pintura,
          ))
      ) {
        if (!parentPath) {
          throw new Error('Missing `parentPath`')
        }

        try {
          const headerImage = await uploadImage(
            parentPath,
            noOriginalImage ? pinturaRes.dest : values.src,
            noOriginalImage ? undefined : pinturaRes.dest,
            {
              userId: managerRoleId ?? undefined,
              metadata: {
                type: imageType,
                pintura: pinturaRes.imageState,
              },
            },
          )
          onSubmit?.({
            ...headerImage,
            edit_path: noOriginalImage ? undefined : headerImage.edit_path,
            edited_image_url: noOriginalImage
              ? undefined
              : headerImage.edited_image_url,
          })
        } catch (err) {
          growlActions.show('error', {
            title: 'Error',
            body:
              err instanceof Error ? err.message : 'Something went wrong...',
          })
        }
      } else if (initialImage && !(initialImage instanceof Blob)) {
        onSubmit?.(initialImage)
      }
    },
  })

  return (
    <FormikForm
      className={WebUI.cn('flex flex-col', className)}
      formik={formik}
      {...restProps}
    >
      <UploadPinturaImage
        ref={pinturaEditorRef}
        className="p-8"
        imageCropAspectRatio={imageCropAspectRatio}
        imageCropLimitToImage={imageCropLimitToImage}
        circularCrop={circularCrop}
        utils={utils}
        uploaderOptions={uploaderOptions}
        value={image}
        initialValue={initialImage}
        placeholderSrc={placeholderSrc}
        onSrcChange={(newSrc) => formik.setFieldValue('src', newSrc)}
      />

      <DashboardPageFooter>
        <span className="font-normal text-ds-sm">
          By proceeding, you agree that your images do not violate copyright
          permissions.
        </span>
        <FormikSubmitButton size="md" variant="orange">
          Save
        </FormikSubmitButton>
      </DashboardPageFooter>
    </FormikForm>
  )
}

// MARK: – UploadPinturaImage

export type UploadPinturaImageUploadOption = 'upload' | 'gallery' | 'camera'

export interface UploadPinturaImageProps
  extends WebUI.PinturaEditorProps,
    Pick<WebUI.FileUploaderProps, 'onDropAccepted'> {
  uploaderOptions?: UploadPinturaImageUploadOption[]
  placeholderSrc?: string | Blob | null
  initialValue?: Api.S3Image | Blob | null
  value?: Api.S3Image | Blob | null
  onSrcChange?: (src: string | Blob) => void
}

export const UploadPinturaImage = React.forwardRef<
  WebUI.PinturaEditorInstance,
  UploadPinturaImageProps
>(
  (
    {
      uploaderOptions = ['upload', 'gallery', 'camera'],
      onDropAccepted,
      placeholderSrc,
      initialValue,
      value: valueProp,
      onSrcChange,
      ...restProps
    },
    forwardedRef,
  ) => {
    const pinturaEditorRef = useRef<WebUI.PinturaEditorInstance>(null)
    const ref = useForkRef(forwardedRef, pinturaEditorRef)
    const cameraModalRef = useRef<CameraModalInstance>(null)
    const externalImagePickerModalRef =
      useRef<UnsplashImagePickerInstance>(null)
    const defaultValue = valueProp ?? initialValue
    const [value, setValue] = useState<string | Blob | null>(
      defaultValue instanceof Blob
        ? defaultValue
        : (defaultValue?.url ?? placeholderSrc ?? null),
    )
    const onSrcChangeRef = useLiveRef(onSrcChange)

    useUpdateEffect(() => {
      if (value) {
        onSrcChangeRef.current?.(value)
      }
    }, [value])

    useUpdateEffect(() => {
      if (valueProp !== undefined) {
        setValue(
          valueProp instanceof Blob ? valueProp : (valueProp?.url ?? null),
        )
      }
    }, [valueProp])

    return (
      <>
        <WebUI.FileUploader
          accept={{'image/*': []}}
          multiple={false}
          maxFiles={1}
          onDropAccepted={(...args) => {
            onDropAccepted?.(...args)

            const [[newImage]] = args

            if (newImage) {
              setValue(newImage)
            }
          }}
        >
          {(fileUploader) => (
            <>
              <WebUI.PinturaEditor
                ref={ref}
                src={value ?? undefined}
                imageCropAspectRatio={16 / 9}
                willRenderToolbar={
                  uploaderOptions.length === 0
                    ? undefined
                    : (toolbar) => {
                        async function handleChange(value: number) {
                          if (value === 1) {
                            fileUploader.open()
                          } else if (value === 2) {
                            const newImageSrc =
                              await externalImagePickerModalRef.current?.show()
                            if (newImageSrc) {
                              setValue(newImageSrc)
                            }
                          } else if (value === 3) {
                            const newImageSrc =
                              await cameraModalRef.current?.show()
                            if (newImageSrc) {
                              setValue(newImageSrc)
                            }
                          }
                        }

                        return [
                          uploaderOptions.length === 1
                            ? WebUI.createButtonPinturaNode('select-photo', {
                                label: 'Select Photo',
                                onclick: () => {
                                  // biome-ignore lint/style/noNonNullAssertion:
                                  const option = uploaderOptions[0]!
                                  handleChange(
                                    {
                                      upload: 1,
                                      gallery: 2,
                                      camera: 3,
                                    }[option],
                                  )
                                },
                              })
                            : WebUI.createDropdownPinturaNode('select-photo', {
                                label: 'Select Photo',
                                options: uploaderOptions.map((o) => {
                                  switch (o) {
                                    case 'upload':
                                      return [1, 'Browse device files']
                                    case 'gallery':
                                      return [2, 'Select from gallery']
                                    case 'camera':
                                      return [3, 'Take with camera']
                                  }
                                }),
                                onchange: ({value}: {value: number}) =>
                                  handleChange(value),
                              }),
                          ...toolbar,
                        ]
                      }
                }
                {...restProps}
              />

              <WebUI.FileUploaderInput />
            </>
          )}
        </WebUI.FileUploader>

        <CameraModal ref={cameraModalRef} />
        <UnsplashImagePickerModal ref={externalImagePickerModalRef} />
      </>
    )
  },
)

// MARK: – UnsplashImagePickerModal

interface UnsplashImagePickerInstance {
  show: () => Promise<string | null>
  dialog: WebUI.DialogInstance | null
}

const perPage = 12

const UnsplashImagePickerModal = React.forwardRef<
  UnsplashImagePickerInstance,
  WebUI.ModalProps
>(
  (
    {
      initialVisible = false,
      contentViewAppearance = 'overlay',
      onDidHide,
      ...restProps
    },
    forwardedRef,
  ) => {
    const dialogRef = useRef<WebUI.DialogInstance>(null)
    const [searchValue, setSearchValue] = useState('School')
    const [galleryData, setGalleryData] = useState<{
      page: number
      totalPages: number
      photos: Basic[]
    }>({
      page: 1,
      totalPages: 0,
      photos: [],
    })
    const onShowDidResolveRef = useRef<
      ((blob: string | null | PromiseLike<string | null>) => void) | undefined
    >(undefined)

    useImperativeHandle(
      forwardedRef,
      () => ({
        show: () =>
          new Promise((resolve) => {
            dialogRef.current?.show()
            onShowDidResolveRef.current = resolve
          }),
        dialog: dialogRef.current,
      }),
      [],
    )

    const commonImageOptions = useMemo(
      () => ({
        orientation: 'landscape' as const,
      }),
      [],
    )

    const debouncedSearchValue = useDebounceValue(searchValue, 300)

    const searchRes = useAsyncEffect(async () => {
      if (debouncedSearchValue.length > 0) {
        const result = await memoizedSearchUnsplashPhotos({
          ...commonImageOptions,
          query: debouncedSearchValue,
          perPage,
        })

        if (result.type === 'success') {
          setGalleryData((prevGalleryData) => ({
            ...prevGalleryData,
            photos: result.response.results,
            totalPages: result.response.total_pages,
            page: 1,
          }))
        }
      } else {
        setGalleryData((prevGalleryData) => ({
          ...prevGalleryData,
          photos: [],
        }))
      }
    }, [commonImageOptions, debouncedSearchValue])

    const [handleLoadMore, handleLoadMoreStatus] =
      useAsyncCallback(async () => {
        if (searchValue.length > 0) {
          const result = await memoizedSearchUnsplashPhotos({
            ...commonImageOptions,
            query: searchValue,
            perPage,
            page: galleryData.page + 1,
          })

          if (result.type === 'success') {
            setGalleryData((prevGalleryData) => ({
              ...prevGalleryData,
              page: prevGalleryData.page + 1,
              photos: [...prevGalleryData.photos, ...result.response.results],
            }))
          }
        }
      }, [commonImageOptions, galleryData.page, searchValue])

    return (
      <WebUI.Modal
        ref={dialogRef}
        aria-label="Select from gallery"
        initialVisible={initialVisible}
        contentViewAppearance={contentViewAppearance}
        onDidHide={() => {
          onDidHide?.()

          onShowDidResolveRef.current?.(null)
        }}
        {...restProps}
      >
        {(dialog) => (
          <>
            <WebUI.ModalCloseButton />
            <WebUI.ModalHeader>Select from gallery</WebUI.ModalHeader>
            <WebUI.VStack className="min-h-0 grow">
              {Util.isPending(searchRes) && (
                <div
                  className={
                    'absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center bg-[rgba(51,51,51,0.5)]'
                  }
                >
                  <WebUI.Loader />
                </div>
              )}
              <WebUI.VStack className="gap-4 overflow-y-auto px-6 py-4">
                <WebUI.VStack className="items-stretch justify-center gap-2 sm:flex-row sm:items-center sm:justify-start">
                  <SearchForm
                    className="w-full sm:w-[300px]"
                    iconClassName="hidden"
                    placeholder="Enter keyword to search for images"
                    initialValues={{term: searchValue}}
                    onTermChange={(newSearchTerm) =>
                      setSearchValue(newSearchTerm)
                    }
                  />
                  <WebUI.Button className="h-10 text-ds-base" type="button">
                    Search
                  </WebUI.Button>
                  <WebUI.Text className="text-ds-xs sm:text-ds-sm">
                    <WebUI.Anchor
                      href="https://unsplash.com/?utm_source=CheddarUp&utm_medium=referral"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Unsplash
                    </WebUI.Anchor>{' '}
                    photos can be downloaded and used freely.{' '}
                    <WebUI.Anchor
                      href="https://unsplash.com/license"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn more.
                    </WebUI.Anchor>
                  </WebUI.Text>
                </WebUI.VStack>
                <section
                  className={
                    'grid grid-flow-dense grid-cols-[repeat(auto-fit,minmax(300px,1fr))] items-stretch gap-4'
                  }
                >
                  {galleryData.photos.map((item) => (
                    <button
                      key={item.id}
                      className="relative cursor-pointer"
                      style={{
                        gridRow: `span ${Math.ceil(item.height / item.width)}`,
                      }}
                      type="button"
                      onClick={() => {
                        onShowDidResolveRef.current?.(item.urls.regular)
                        dialog.hide()

                        // See https://github.com/unsplash/unsplash-js?tab=readme-ov-file#photostrackdownloadarguments-additionalfetchoptions
                        unsplash.photos.trackDownload({
                          downloadLocation: item.links.download_location,
                        })
                      }}
                    >
                      <WebUI.Image
                        className="h-full w-full object-cover"
                        src={item.urls.small}
                        alt={item.alt_description ?? item.description ?? ''}
                      />
                      <WebUI.Anchor
                        className={
                          'absolute inset-x-0 bottom-0 flex justify-center bg-[rgba(51,51,51,0.5)] py-1'
                        }
                        href={`https://unsplash.com/@${item.user.username}?utm_source=CheddarUp&utm_medium=referral`}
                        target="_blank"
                        onClick={(event) => event.stopPropagation()}
                      >
                        <WebUI.Text className="text-depr-grey-50">
                          {item.user.name}
                        </WebUI.Text>
                      </WebUI.Anchor>
                    </button>
                  ))}
                </section>
                {galleryData.totalPages > galleryData.page &&
                  galleryData.photos.length > 0 &&
                  searchValue && (
                    <WebUI.HStack className="justify-center">
                      <WebUI.Button
                        loading={Util.isPending(handleLoadMoreStatus)}
                        variant="primary"
                        onClick={() => handleLoadMore()}
                      >
                        Load more
                      </WebUI.Button>
                    </WebUI.HStack>
                  )}
              </WebUI.VStack>
            </WebUI.VStack>
          </>
        )}
      </WebUI.Modal>
    )
  },
)

// MARK: – Helpers

const unsplash = createApi({
  apiUrl: `${import.meta.env.REACT_APP_META_PATH}api/unsplash`,
})

const memoizedSearchUnsplashPhotos = Util.memoize(
  (...args: Parameters<(typeof unsplash)['search']['getPhotos']>) =>
    unsplash.search.getPhotos(...args),
  {
    isEqual: Util.deepEqual,
    isPromise: true,
    maxSize: 100,
  },
)
