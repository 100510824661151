import * as Ariakit from '@ariakit/react'
import React, {useImperativeHandle} from 'react'

import {NextButton} from './Button'
import {cn} from '../../utils'

export interface DisclosureInstance extends Ariakit.DisclosureStore {}

export interface DisclosureProps extends Ariakit.DisclosureStoreProps {
  children?:
    | React.ReactNode
    | ((disclosure: DisclosureInstance) => React.ReactNode)
}

export const Disclosure = React.forwardRef<DisclosureInstance, DisclosureProps>(
  ({children, ...restProps}, forwardedRef) => {
    const disclosure = Ariakit.useDisclosureStore(restProps)

    useImperativeHandle(forwardedRef, () => disclosure, [disclosure])

    return (
      <Ariakit.DisclosureProvider store={disclosure}>
        {typeof children === 'function' ? children(disclosure) : children}
      </Ariakit.DisclosureProvider>
    )
  },
)

// MARK: - DisclosureButton

export interface DisclosureButtonProps extends Ariakit.DisclosureProps {}

export const DisclosureButton = React.forwardRef<
  HTMLButtonElement,
  DisclosureButtonProps
>(({className, ...restProps}, forwardedRef) => {
  return (
    <Ariakit.Disclosure
      ref={forwardedRef}
      render={<NextButton />}
      {...restProps}
    />
  )
})

// MARK: - DisclosureContent

export interface DisclosureContentProps
  extends Ariakit.DisclosureContentProps {}

export const DisclosureContent = React.forwardRef<
  HTMLDivElement,
  DisclosureContentProps
>(({className, ...restProps}, forwardedRef) => {
  const disclosure = Ariakit.useDisclosureContext()
  const isOpen = Ariakit.useStoreState(disclosure, (s) => s?.open ?? false)
  const isAnimating = Ariakit.useStoreState(
    disclosure,
    (s) => s?.animating ?? false,
  )

  if (!isOpen && !isAnimating) {
    return null
  }

  return (
    <Ariakit.DisclosureContent
      ref={forwardedRef}
      className={cn(
        'h-0 scale-0 opacity-0 transition data-[enter]:h-auto data-[enter]:scale-100 data-[enter]:opacity-100',
        className,
      )}
      {...restProps}
    />
  )
})

// MARK: – Helpers

export const useDisclosureContext = Ariakit.useDisclosureContext
