import * as Ariakit from '@ariakit/react'
import React, {useState} from 'react'

import {
  Dialog,
  DialogHeading,
  DialogInstance,
  DialogProps,
  useDialog,
} from './Dialog'
import {
  ModalCloseButton,
  ModalCloseButtonProps,
  ModalContent,
  ModalContentProps,
} from './Modal'
import {cn} from '../../utils'
import {NextButton, NextButtonProps} from './Button'

export interface AlertProps
  extends ModalContentProps,
    Pick<DialogProps, 'disclosure'> {}

export const Alert = React.forwardRef<DialogInstance, AlertProps>(
  ({disclosure, className, ...restProps}, forwardedRef) => (
    <Dialog ref={forwardedRef} disclosure={disclosure}>
      <ModalContent
        className={cn('min-h-80 w-[min(500px,100%)]', className)}
        role="alertdialog"
        {...restProps}
      />
    </Dialog>
  ),
)

// MARK: – AlertHeader

export interface AlertHeaderProps extends Ariakit.RoleProps {
  dismissHidden?: boolean
}

export const AlertHeader = React.forwardRef<HTMLDivElement, AlertHeaderProps>(
  ({dismissHidden, className, children, ...restProps}, forwardedRef) => (
    <Ariakit.Role
      ref={forwardedRef}
      className={cn('flex items-center p-6', className)}
      {...restProps}
    >
      <DialogHeading className="grow font-bold text-ds-md">
        {children}
      </DialogHeading>
      {!dismissHidden && (
        <ModalCloseButton className="static text-2xl text-grey-600" />
      )}
    </Ariakit.Role>
  ),
)

// MARK: – AlertMain

export interface AlertMainProps extends Ariakit.RoleProps {}

export const AlertMain = React.forwardRef<HTMLDivElement, AlertMainProps>(
  ({className, ...restProps}, forwardedRef) => (
    <Ariakit.Role
      ref={forwardedRef}
      className={cn('flex grow flex-col gap-6 p-6', className)}
      {...restProps}
    />
  ),
)

// MARK: – AlertFooter

export interface AlertFooterProps extends Ariakit.RoleProps {}

export const AlertFooter = React.forwardRef<HTMLDivElement, AlertFooterProps>(
  ({className, ...restProps}, forwardedRef) => (
    <Ariakit.Role
      ref={forwardedRef}
      className={cn('flex gap-3 p-6', className)}
      {...restProps}
    />
  ),
)

// MARK: – AlertActionButton

export interface AlertActionButtonProps<TExecuteRes> extends NextButtonProps {
  execute?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    // biome-ignore lint/suspicious/noConfusingVoidType:
  ) => Promise<TExecuteRes> | void
  onDidExecute?: (res: TExecuteRes) => void
}

export const AlertActionButton = React.forwardRef<
  HTMLButtonElement,
  AlertActionButtonProps<any>
>(
  (
    {variant = 'orange', execute, onClick, onDidExecute, ...restProps},
    forwardedRef,
  ) => {
    const dialog = useDialog()
    const [loading, setLoading] = useState(false)

    return (
      <NextButton
        ref={forwardedRef}
        variant={variant}
        loading={loading}
        onClick={async (event) => {
          onClick?.(event)

          if (!execute || event.defaultPrevented) {
            return
          }

          try {
            setLoading(true)
            const res = await execute(event)
            onDidExecute?.(res)
            dialog.hide()
          } finally {
            setLoading(false)
          }
        }}
        {...restProps}
      />
    )
  },
)

// MARK: – AlertCancelButton

export interface AlertCancelButtonProps extends ModalCloseButtonProps {}

export const AlertCancelButton = React.forwardRef<
  HTMLButtonElement,
  AlertCancelButtonProps
>(({render, children = 'Cancel', ...restProps}, forwardedRef) => (
  <ModalCloseButton
    ref={forwardedRef}
    render={render ?? <NextButton variant="gray" />}
    {...restProps}
  >
    {children}
  </ModalCloseButton>
))
