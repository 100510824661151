import React from 'react'

import {PhosphorIcon} from '../icons'
import {Ellipsis} from './Ellipsis'
import {IconButton} from './IconButton'
import {cn} from '../utils'

export interface TagProps extends React.ComponentPropsWithoutRef<'div'> {
  clearLabel?: string
  onClear?: () => void
}

// Inspired by https://seek-oss.github.io/braid-design-system/components/Tag
export const Tag = React.forwardRef<HTMLDivElement, TagProps>(
  ({onClear, clearLabel, className, children, ...restProps}, forwardedRef) => (
    <span
      ref={forwardedRef}
      className={cn(
        'Tag',
        'inline-flex h-[2.2em] max-h-full flex-row items-center justify-center gap-1 rounded border px-3 [&[tabindex]:focus]:shadow-[0_0_0_2px_theme(colors.teal.50)] [&[tabindex]:focus]:outline-none',
        className,
      )}
      {...restProps}
    >
      <Ellipsis
        className={cn('Tag-content', 'flex-initial')}
        style={
          Array.isArray(children) || React.isValidElement(children)
            ? {
                display: 'inline-flex',
                alignItems: 'center',
              }
            : undefined
        }
      >
        {children}
      </Ellipsis>

      {onClear && (
        <IconButton
          className={cn('Tag-clearButton', 'h-[1.2em] w-[1.2em] flex-0 p-0')}
          aria-label={clearLabel}
          title={clearLabel}
          tabIndex={-1}
          onClick={(event) => {
            event.stopPropagation()
            onClear()
          }}
        >
          <PhosphorIcon icon="x-circle-fill" />
        </IconButton>
      )}
    </span>
  ),
)
