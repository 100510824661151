import {formatAmount} from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import * as Yup from 'yup'
import {
  DashboardPageContainer,
  DashboardPageHeader,
  DashboardPageHeading,
  DashboardPageContent,
  DashboardPanel,
  DashboardSettingContent,
  DashboardSettingPanel,
  DashboardSettingPanelLabel,
  DashboardPageFooter,
} from 'src/components/DashboardPageLayout'
import {DashboardContentLayout} from 'src/components/Layout'
import {
  SettingDisclosure,
  SettingDisclosureContent,
  SettingDisclosureSwitch,
} from 'src/components/SettingDisclosure'
import {
  ClearableAmountInput,
  ItemFormItemFields,
} from 'src/views/collection/build/items/ItemFormPage/components'
import {EinVerifiedCard} from './components'
import {useNavigate} from 'react-router-dom'
import {
  api,
  useFieldsCUDBatchMutateAsync,
  useUpdateItemMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import {useFormik} from '@cheddarup/react-util'
import React, {useRef, useState} from 'react'
import {FieldsEditValue} from '@cheddarup/core'
import {useSaveFields} from 'src/hooks/fields'
import {FieldGroupsPreview} from 'src/components/FieldsEdit/FieldGroupsPreview'
import {useCurrentFundraiserId, useCurrentFundraiserQuery} from '../hooks'

const DONATION_SUGGESTIONS = [5, 10, 20, 30, 40, 50]

interface DonationOptionsFormValues {
  custom_receipt_enabled: boolean
  custom_receipt_content: string
  options: {
    p2pFundraising: {
      allowGeneralDonations: boolean
    }
  }
  item: {
    donation: {
      suggestedAmounts: {
        enabled: boolean
        values: Array<string | undefined>
      }
    }
    isTaxDeductible: boolean
  }
}

const DonationOptionsPage = () => {
  const navigate = useNavigate()
  const fundraiserId = Number(useCurrentFundraiserId())
  const [deletingFields, setDeletingFields] = useState(false)

  const updateItemMutation = useUpdateItemMutation()
  const updateTabMutation = useUpdateTabMutation()
  const fieldsCUDBatchMutateAsync = useFieldsCUDBatchMutateAsync()

  const [{data: fundraiser}] = useCurrentFundraiserQuery()
  const {data: p2pDonationItem} = api.tabItems.list.useQuery(
    {pathParams: {tabId: fundraiserId}},
    {select: (items) => items[0]},
  )
  const fieldsQuery = api.fields.itemList.useQuery(
    {
      pathParams: {
        tabId: fundraiserId,
        // biome-ignore lint/style/noNonNullAssertion:
        itemId: p2pDonationItem?.id!,
      },
    },
    {
      enabled: !!p2pDonationItem,
      select: (fields) => fields.filter((f) => !f.metadata.p2pDonorField),
    },
  )

  const formik = useFormik<DonationOptionsFormValues>({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      item: Yup.object().shape({
        donation: Yup.object().shape({
          suggestedAmounts: Yup.object().shape({
            enabled: Yup.boolean(),
            minimumAmount: Yup.number()
              .min(1, 'Must be greater than 0.')
              .nullable(),
            values: Yup.array().when('enabled', ([enabled], schema) =>
              enabled
                ? schema
                    .of(
                      Yup.number().nullable().min(1, 'Must be greater than 0.'),
                    )
                    .test(
                      'has-valid-amount',
                      'At least one suggested amount is required.',
                      (values) => values?.some((value) => !!value),
                    )
                : schema,
            ),
          }),
        }),
      }),
    }),
    initialValues: {
      custom_receipt_enabled: !!fundraiser?.custom_receipt_enabled,
      custom_receipt_content: fundraiser?.custom_receipt_content ?? '',
      options: {
        p2pFundraising: {
          allowGeneralDonations:
            !!fundraiser?.options.p2pFundraising?.allowGeneralDonations,
        },
      },
      item: {
        donation: {
          suggestedAmounts: {
            enabled:
              !!p2pDonationItem?.options.donation?.suggestedAmounts.enabled,
            values:
              p2pDonationItem?.options.donation?.suggestedAmounts?.values?.map(
                String,
              ) ?? [],
          },
        },
        isTaxDeductible: !!p2pDonationItem?.options.isTaxDeductible,
      },
    },
    onSubmit: async (values) => {
      const {item: itemPayload, ...fundraiserPayload} = values
      const suggestedDonationAmounts =
        itemPayload.donation.suggestedAmounts.values
          .filter((val) => val != null)
          .map(Number)
          .sort((a, b) => a - b)

      if (p2pDonationItem) {
        await updateItemMutation.mutateAsync({
          pathParams: {
            tabId: fundraiserId,
            itemId: p2pDonationItem.id,
          },
          body: {
            options: {
              donation: {
                suggestedAmounts: {
                  enabled:
                    itemPayload.donation.suggestedAmounts.enabled &&
                    suggestedDonationAmounts.length > 0,
                  values: suggestedDonationAmounts,
                },
              },
              isTaxDeductible: itemPayload.isTaxDeductible,
            },
          },
        })
      }

      await updateTabMutation.mutateAsync({
        pathParams: {tabId: fundraiserId},
        body: fundraiserPayload,
      })

      navigate('../settings')
    },
  })

  const isAccountEinVerified =
    fundraiser?.organizer.verifiedNonProfitStatus === 'verified'
  const isTeam = !!fundraiser?.is_team
  const itemHasQuestions = fieldsQuery.data && fieldsQuery.data.length > 0

  return (
    <form
      className="contents"
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
    >
      <DashboardPageContainer>
        <DashboardPageContent>
          <DashboardPageHeader>
            <DashboardPageHeading>Donation Options</DashboardPageHeading>
            <NextUI.Text>
              Set your donation preferences here to configure how donors can
              contribute to fundraising pages.
            </NextUI.Text>
          </DashboardPageHeader>

          <DashboardContentLayout>
            <DashboardPanel>
              <NextUI.PanelLabel>Donation</NextUI.PanelLabel>
              <DashboardSettingContent>
                <SettingDisclosure
                  open={formik.values.item.donation.suggestedAmounts.enabled}
                  setOpen={(visible) =>
                    formik.setFieldValue(
                      'donation.suggestedAmounts.enabled',
                      visible,
                    )
                  }
                >
                  <SettingDisclosureSwitch>
                    Suggest pre-set donation amounts
                  </SettingDisclosureSwitch>
                  <SettingDisclosureContent>
                    <span>
                      We'll also include an open-amount field along with your
                      suggested amounts. Your pre-set amounts will apply to flat
                      donations only.
                    </span>
                    <WebUI.FormFieldLabel>Suggestions</WebUI.FormFieldLabel>
                    {typeof formik.errors?.item?.donation?.suggestedAmounts
                      ?.values === 'string' && (
                      <WebUI.FormFieldError>
                        At least one suggested amount is required.
                      </WebUI.FormFieldError>
                    )}
                    <div className="grid max-w-[470px] grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-3">
                      {DONATION_SUGGESTIONS.map((amount, idx) => (
                        <WebUI.FormField
                          key={idx}
                          error={
                            (Array.isArray(
                              formik.errors.item?.donation?.suggestedAmounts
                                ?.values,
                            )
                              ? formik.errors.item.donation.suggestedAmounts
                                  .values
                              : [])[idx]
                          }
                        >
                          <ClearableAmountInput
                            className="w-36"
                            placeholder={formatAmount(amount)}
                            value={
                              formik.values.item.donation.suggestedAmounts
                                .values[idx] ?? ''
                            }
                            onValueChange={(newAmount) => {
                              const updatedValues = [
                                ...formik.values.item.donation.suggestedAmounts
                                  .values,
                              ]
                              updatedValues[idx] = newAmount
                              formik.setFieldValue(
                                'item.donation.suggestedAmounts.values',
                                updatedValues,
                              )
                            }}
                          />
                        </WebUI.FormField>
                      ))}
                    </div>
                  </SettingDisclosureContent>
                </SettingDisclosure>
                <SettingDisclosure
                  open={
                    formik.values.options.p2pFundraising.allowGeneralDonations
                  }
                  setOpen={(enabled) =>
                    formik.setFieldValue(
                      'options.p2pFundraising.allowGeneralDonations',
                      enabled,
                    )
                  }
                >
                  <SettingDisclosureSwitch>
                    Allow general donations (without assigning them to a
                    participant)
                  </SettingDisclosureSwitch>
                  <SettingDisclosureContent>
                    Donors can make contributions on your main fundraising page
                    without assigning it to a specific participant
                  </SettingDisclosureContent>
                </SettingDisclosure>
                <DashboardSettingPanel
                  className={WebUI.cn(
                    'gap-4',
                    !itemHasQuestions && 'flex-row justify-between',
                  )}
                >
                  <DashboardSettingPanelLabel>
                    Add custom questions that you want donors to answer
                  </DashboardSettingPanelLabel>
                  {itemHasQuestions && (
                    <>
                      <NextUI.Text className="max-w-2xl">
                        We would like to mail you a gift for donating. Please
                        provide your full name and shipping address below.
                      </NextUI.Text>
                      <FieldGroupsPreview
                        fieldSets={p2pDonationItem?.options.fieldSets ?? []}
                        fields={fieldsQuery.data}
                      />
                    </>
                  )}
                  {!!p2pDonationItem && (
                    <div
                      className={WebUI.cn(
                        'mt-4 flex gap-2',
                        !itemHasQuestions && 'contents',
                      )}
                    >
                      <DonationQuestionsModal
                        item={p2pDonationItem}
                        fields={fieldsQuery.data}
                        fundraiserId={fundraiserId}
                        disclosure={
                          <NextUI.DialogDisclosure
                            render={<NextUI.NextButton size="xs" />}
                          >
                            {itemHasQuestions
                              ? 'Edit Questions'
                              : 'Add Question'}
                          </NextUI.DialogDisclosure>
                        }
                      />
                      {itemHasQuestions && (
                        <NextUI.NextButton
                          variant="gray"
                          size="xs"
                          loading={deletingFields}
                          onClick={async () => {
                            setDeletingFields(true)
                            await fieldsCUDBatchMutateAsync(
                              {
                                tabId: fundraiserId,
                                tabObjectId: p2pDonationItem.id,
                                tabObjectType: 'item',
                              },
                              {
                                create: [],
                                update: [],
                                delete: fieldsQuery.data,
                              },
                            )

                            setDeletingFields(false)
                          }}
                        >
                          Delete
                        </NextUI.NextButton>
                      )}
                    </div>
                  )}
                </DashboardSettingPanel>
              </DashboardSettingContent>
            </DashboardPanel>
            <DashboardPanel>
              <NextUI.PanelLabel>Donation Receipts</NextUI.PanelLabel>
              {!isTeam && isAccountEinVerified && <EinVerifiedCard />}
              <DashboardSettingContent>
                {isAccountEinVerified && (
                  <SettingDisclosure
                    open={formik.values.item.isTaxDeductible}
                    setOpen={(visible) =>
                      formik.setFieldValue('item.isTaxDeductible', visible)
                    }
                  >
                    <SettingDisclosureSwitch disabled={!isTeam}>
                      Show donation as tax deductible
                    </SettingDisclosureSwitch>
                    <SettingDisclosureContent>
                      Your account has been verified as a 501(c)(3). If this
                      item is tax-deductible, you can toggle this option, and we
                      will send a tax-deductible receipt to anyone who makes a
                      payment.
                    </SettingDisclosureContent>
                  </SettingDisclosure>
                )}
                <SettingDisclosure
                  open={formik.values.custom_receipt_enabled}
                  setOpen={(enabled) =>
                    formik.setFieldValue('custom_receipt_enabled', enabled)
                  }
                >
                  <SettingDisclosureSwitch disabled={!isTeam}>
                    Add a custom “Thank You” on donor receipts
                  </SettingDisclosureSwitch>
                  <SettingDisclosureContent className="mr-0">
                    <span>
                      Add a custom message that will be included in the payment
                      confirmation email that is sent to your donors.
                    </span>
                    <WebUI.FormField>
                      <WebUI.RichTextEditor
                        name="description"
                        placeholder="Enter message to appear at the top of donation receipts."
                        key={formik.initialValues.custom_receipt_content}
                        initialMarkdownValue={
                          formik.values.custom_receipt_content
                        }
                        onMarkdownValueChange={(value) =>
                          formik.setFieldValue('custom_receipt_content', value)
                        }
                      >
                        <WebUI.RichTextEditorToolbar rootClassName="-order-1" />
                      </WebUI.RichTextEditor>
                    </WebUI.FormField>
                  </SettingDisclosureContent>
                </SettingDisclosure>
              </DashboardSettingContent>
            </DashboardPanel>
          </DashboardContentLayout>
        </DashboardPageContent>
      </DashboardPageContainer>

      <DashboardPageFooter>
        <NextUI.NextButton
          type="submit"
          size="md"
          loading={formik.isSubmitting}
        >
          Continue <WebUI.PhosphorIcon icon="caret-right" />
        </NextUI.NextButton>
      </DashboardPageFooter>
    </form>
  )
}

// MARK: - DonationQuestionsModal

interface DonationQuestionsModalProps extends NextUI.DialogProps {
  fundraiserId: number
  fields?: Api.TabObjectField[]
  item: Api.TabItem
}

const DonationQuestionsModal: React.FC<DonationQuestionsModalProps> = ({
  item,
  fields,
  fundraiserId,
  ...restProps
}) => {
  const growlActions = WebUI.useGrowlActions()
  const fieldsEditValueRef = useRef<FieldsEditValue[]>([])

  const [loading, setLoading] = useState(false)

  const saveFields = useSaveFields()
  const updateItemMutation = useUpdateItemMutation()

  return (
    <NextUI.Dialog {...restProps}>
      {(modal) => (
        <NextUI.ModalContent className="w-full sm:max-w-5xl">
          <NextUI.ModalCloseButton className="top-6 right-6 text-ds-xl" />

          <WebUI.ModalHeader className="p-6">
            <WebUI.PageHeader subheading="Add custom questions that you want donors to answer. ">
              Custom Donor Questions
            </WebUI.PageHeader>
          </WebUI.ModalHeader>
          <ItemFormItemFields
            className="min-h-0"
            label={null}
            text={null}
            initialFieldSets={
              fields?.length
                ? (item?.options.fieldSets ?? undefined)
                : undefined
            }
            initialFields={fields}
            onInit={(initialFieldsEditValue) => {
              fieldsEditValueRef.current = initialFieldsEditValue
            }}
            onChange={(newFieldsEditValue) => {
              fieldsEditValueRef.current = newFieldsEditValue
            }}
          />
          <DashboardPageFooter>
            <NextUI.NextButton
              className="w-32"
              size="md"
              variant="orange"
              loading={loading}
              onClick={async () => {
                setLoading(true)
                const localFields = fieldsEditValueRef.current.flatMap(
                  (fev) => fev.fields,
                )

                const someCheckboxOrMultipleChoiceFieldsEmpty = localFields
                  .filter(
                    (f) =>
                      f.field_type === 'checkbox' ||
                      f.field_type === 'multiple_choice',
                  )
                  .some((f) => 'values' in f && f.values?.length === 0)
                if (someCheckboxOrMultipleChoiceFieldsEmpty) {
                  growlActions.show('error', {
                    title: 'Error',
                    body: 'Checkbox and dropdown questions require at least one option',
                  })
                }

                await saveFields({
                  tabId: fundraiserId,
                  tabObjectId: item.id,
                  tabObjectType: 'item',
                  existingFields: fields ?? [],
                  newFields: localFields,
                })

                await updateItemMutation.mutateAsync({
                  pathParams: {
                    tabId: fundraiserId,
                    itemId: item.id,
                  },
                  body: {
                    options: {
                      fieldSets: fieldsEditValueRef.current.map(
                        (fev) => fev.fieldSet,
                      ),
                    },
                  },
                })
                setLoading(false)
                modal.hide()
              }}
            >
              Save Questions
            </NextUI.NextButton>
          </DashboardPageFooter>
        </NextUI.ModalContent>
      )}
    </NextUI.Dialog>
  )
}

export default DonationOptionsPage
