import PrimitiveCurrencyInput, {
  CurrencyInputProps as PrimitiveCurrencyInputProps,
} from 'react-currency-input-field'
import React from 'react'
import {Merge} from '@cheddarup/util'

import {Input, InputProps} from './Input'

export interface CurrencyInputProps
  extends Merge<
    InputProps,
    Omit<PrimitiveCurrencyInputProps, 'size' | 'customInput'>
  > {}

export const CurrencyInput = React.forwardRef<
  HTMLInputElement,
  CurrencyInputProps
>(({size, ...restProps}, forwardedRef) => (
  <PrimitiveCurrencyInput
    ref={forwardedRef}
    customInput={Input}
    size={size as any}
    {...restProps}
  />
))
