import {Navigate, Outlet, Route} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {RoutableTab, RoutableTabs} from 'src/components/__next/RoutableTabs'
import {SelectedTabUnderline, TabList} from '@cheddarup/web-ui/next'
import {NextLinkButton} from 'src/components/__next/LinkButton'
import {NavListItemNavLink} from 'src/components/NavListItemNavLink'
import {PageContainer} from 'src/components/PageContainer'
import {
  FundraiserDetailsPage,
  PeerToPeerOptionsPage,
  FundraiserSettingsPage,
  DonationOptionsPage,
  FundraiserShareLinkPage,
} from 'src/views/fundraiser/build'
import {
  useCurrentFundraiserId,
  useCurrentFundraiserQuery,
} from 'src/views/fundraiser/hooks'
import {FundraiserParticipantsPage} from 'src/views/fundraiser/participants/FundraiserParticipantsPage'
import {ParticipantsTab} from 'src/views/fundraiser/participants/ParticipantsTab'
import {TeamsTab} from 'src/views/fundraiser/participants/TeamsTab'
import {Suspense} from 'react'

// MARK: – FundraiserNav

interface FundraiserBuilderNavProps
  extends React.ComponentPropsWithoutRef<'div'> {}

const FundraiserNav = ({
  className,
  ...restProps
}: FundraiserBuilderNavProps) => {
  const [fundraiserQuery, fundraiserId] = useCurrentFundraiserQuery()

  const isFundraiserPublished =
    fundraiserQuery.data && fundraiserQuery.data?.status !== 'draft'

  return (
    <div
      className={WebUI.cn(
        'relative flex h-15 shrink-0 flex-row overflow-x-auto',
        className,
      )}
      {...restProps}
    >
      <div className="flex flex-auto flex-row justify-between gap-3 bg-grey-600 text-trueWhite shadow-100 sm:pl-8">
        <WebUI.Ellipsis className="hidden grow self-center text-ds-lg sm:inline">
          {fundraiserId == null
            ? 'Untitled'
            : (fundraiserQuery.data?.name ?? '')}
        </WebUI.Ellipsis>

        <RoutableTabs>
          <TabList
            className="gap-1 *:font-semibold *:text-trueWhite"
            variant="headless"
          >
            <RoutableTab to="build">Build</RoutableTab>
            <RoutableTab disabled={fundraiserId == null} to="share">
              Publish
            </RoutableTab>
            <RoutableTab disabled={fundraiserId == null} to="participants">
              Participants
            </RoutableTab>
            <RoutableTab disabled={fundraiserId == null} to="manage">
              Manage
            </RoutableTab>

            <SelectedTabUnderline />
          </TabList>
        </RoutableTabs>
      </div>
      {fundraiserId != null && (
        <NextLinkButton
          className="font-semibold"
          size="md"
          variant="violet"
          roundness="none"
          disabled={!fundraiserQuery.data?.slug}
          target={isFundraiserPublished ? '_blank' : '_self'}
          to={
            isFundraiserPublished
              ? `/c/${fundraiserQuery.data?.slug}`
              : `c/${fundraiserQuery.data?.slug}`
          }
        >
          {isFundraiserPublished ? 'View' : 'Preview'}
        </NextLinkButton>
      )}
    </div>
  )
}

// MARK: – FundraiserBuilderDetailsSlideOutNav

interface FundraiserBuilderDetailsSlideOutNavProps
  extends React.ComponentPropsWithoutRef<'div'> {}

const FundraiserBuilderDetailsSlideOutNav = ({
  className,
  ...restProps
}: FundraiserBuilderDetailsSlideOutNavProps) => {
  const fundraiserId = useCurrentFundraiserId()

  return (
    <WebUI.NavList
      className={WebUI.cn('bg-violet-500 text-ds-sm text-trueWhite', className)}
      {...restProps}
    >
      <WebUI.NavListContent
        className="aria-current-page:[&_*]:bg-violet-400 [&_svg]:text-ds-2xl"
        variant="slideOut"
      >
        <WebUI.NavListItem>
          <NavListItemNavLink to="details">
            <WebUI.PhosphorIcon icon="info" />
            <span>Details</span>
          </NavListItemNavLink>
        </WebUI.NavListItem>
        <WebUI.NavListItem>
          <NavListItemNavLink disabled={fundraiserId == null} to="peer-to-peer">
            <WebUI.PhosphorIcon icon="users-three" />
            <span>Peer to Peer</span>
          </NavListItemNavLink>
        </WebUI.NavListItem>
        <WebUI.NavListItem>
          <NavListItemNavLink disabled={fundraiserId == null} to="donation">
            <WebUI.PhosphorIcon icon="hand-heart" />
            <span>Donation</span>
          </NavListItemNavLink>
        </WebUI.NavListItem>
        <WebUI.NavListItem>
          <NavListItemNavLink disabled={fundraiserId == null} to="settings">
            <WebUI.PhosphorIcon icon="sliders" />
            <span>Settings</span>
          </NavListItemNavLink>
        </WebUI.NavListItem>
      </WebUI.NavListContent>
    </WebUI.NavList>
  )
}

// MARK: – getFundraiserRoutes

const getFundraiserRoutes = ({rootPath}: {rootPath: string}) => (
  <Route
    path={rootPath}
    element={
      <>
        <FundraiserNav />
        <Outlet />
      </>
    }
  >
    <Route index element={<Navigate to="build" />} />

    <Route
      path="build"
      element={
        <div className="relative flex min-h-0 flex-auto text-grey-800">
          <div className="ml-17 flex flex-auto flex-col">
            <Outlet />
          </div>

          <FundraiserBuilderDetailsSlideOutNav className="absolute inset-y-0 left-0" />
        </div>
      }
    >
      <Route index element={<Navigate to="details" />} />

      <Route
        path="details/*"
        element={
          <PageContainer>
            <FundraiserDetailsPage />
            <Outlet />
          </PageContainer>
        }
      />
      <Route
        path="peer-to-peer/*"
        element={
          <PageContainer>
            <PeerToPeerOptionsPage />
            <Outlet />
          </PageContainer>
        }
      />
      <Route
        path="donation/*"
        element={
          <PageContainer>
            <DonationOptionsPage />
            <Outlet />
          </PageContainer>
        }
      />
      <Route
        path="settings/*"
        element={
          <PageContainer>
            <FundraiserSettingsPage />
            <Outlet />
          </PageContainer>
        }
      />
    </Route>

    <Route
      path="share"
      element={
        <PageContainer>
          <FundraiserShareLinkPage />
          <Outlet />
        </PageContainer>
      }
    />

    <Route path="participants" element={<FundraiserParticipantsPage />}>
      <Route index element={<Navigate to="index" />} />
      <Route
        path="index/*"
        element={
          <PageContainer>
            <ParticipantsTab />
            <Outlet />
          </PageContainer>
        }
      />
      <Route
        path="teams/*"
        element={
          <PageContainer>
            <Suspense>
              <TeamsTab />
            </Suspense>
            <Outlet />
          </PageContainer>
        }
      />
    </Route>

    <Route path="manage" />
  </Route>
)

// MARK: – fundraiserRoutes

export const fundraiserRoutes = (
  <>
    <Route index element={<Navigate to="new" />} />

    {getFundraiserRoutes({rootPath: 'new'})}
    {getFundraiserRoutes({rootPath: ':fundraiser'})}
  </>
)
