import * as Ariakit from '@ariakit/react'
import React from 'react'

import {cn} from '../../utils'
import {NextHeading} from './Heading'

export interface PanelProps extends Ariakit.GroupProps {}

export const Panel = React.forwardRef<HTMLDivElement, PanelProps>(
  ({className, ...restProps}, forwardedRef) => (
    <Ariakit.Group
      ref={forwardedRef}
      className={cn(
        'group/panel relative flex flex-col gap-4 overflow-hidden border bg-trueWhite p-6 aria-disabled:pointer-events-none aria-disabled:cursor-not-allowed aria-disabled:opacity-70 sm:rounded-lg',
        className,
      )}
      {...restProps}
    />
  ),
)

// MARK: – PanelLabel

export interface PanelLabelProps extends Ariakit.GroupLabelProps {}

export const PanelLabel = React.forwardRef<HTMLDivElement, PanelLabelProps>(
  ({render, ...restProps}, forwardedRef) => (
    <Ariakit.GroupLabel
      ref={forwardedRef}
      render={
        render ?? (
          <NextHeading className="font-body font-bold text-ds-lg" as="h2" />
        )
      }
      {...restProps}
    />
  ),
)
