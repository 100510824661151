import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import {useFormik} from '@cheddarup/react-util'
import {useNavigate, useParams} from 'react-router-dom'
import {api, useUpdateTabMutation} from '@cheddarup/api-client'
import {
  DashboardPageContainer,
  DashboardPageHeader,
  DashboardPageHeading,
  DashboardPageContent,
  DashboardPanel,
  DashboardPanelHeader,
  DashboardSettingContent,
  DashboardPageFooter,
  DashboardSettingPanel,
} from 'src/components/DashboardPageLayout'
import {DashboardContentLayout} from 'src/components/Layout'
import {
  SettingDisclosure,
  SettingDisclosureContent,
  SettingDisclosureSwitch,
} from 'src/components/SettingDisclosure'

const MESSAGE_TO_PARTICIPANT_MAX_LEN = 1000

interface PeerToPeerOptionsFormValues {
  participantOptions: {
    allowSelfSignUp: boolean
    customInviteMessage: {
      enabled: boolean
      message: string
    }
    allowNameChanges: boolean
    hideLastNames: boolean
    allowOwnMedia: boolean
    noteToDonors: {
      enabled: boolean
      suggestedTextEnabled: boolean
      suggestedText: string
    }
    goal: {
      enabled: boolean
      type: 'all_the_same' | 'set_your_own' | 'no_goal' | null
      amount: string
    }
  }
  leaderboard: {
    enabled: boolean
    type: 'team' | 'participant'
  }
}

const PeerToPeerOptionsPage = () => {
  const urlParams = useParams()
  const navigate = useNavigate()

  const updateTabMutation = useUpdateTabMutation()

  const {data: p2pFundraising} = api.tabs.detail.useQuery(
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    {pathParams: {tabId: urlParams.fundraiser!}},
    {
      select: (fundraiser) => fundraiser.options.p2pFundraising,
    },
  )

  const formik = useFormik<PeerToPeerOptionsFormValues>({
    enableReinitialize: true,
    initialValues: {
      participantOptions: {
        allowSelfSignUp:
          p2pFundraising?.participantOptions?.allowSelfSignUp ?? true,
        customInviteMessage: {
          enabled:
            p2pFundraising?.participantOptions?.customInviteMessage?.enabled ??
            true,
          message:
            p2pFundraising?.participantOptions?.customInviteMessage?.message ??
            '',
        },
        allowNameChanges:
          p2pFundraising?.participantOptions?.allowNameChanges ?? true,
        hideLastNames:
          p2pFundraising?.participantOptions?.hideLastNames ?? true,
        allowOwnMedia:
          p2pFundraising?.participantOptions?.allowOwnMedia ?? true,
        noteToDonors: {
          enabled:
            p2pFundraising?.participantOptions?.noteToDonors?.enabled ?? true,
          suggestedTextEnabled:
            !!p2pFundraising?.participantOptions?.noteToDonors
              ?.suggestedTextEnabled,
          suggestedText:
            p2pFundraising?.participantOptions?.noteToDonors?.suggestedText ??
            '',
        },
        goal: {
          enabled: p2pFundraising?.participantOptions?.goal?.enabled ?? true,
          type: p2pFundraising?.participantOptions?.goal?.type ?? null,
          amount: String(
            p2pFundraising?.participantOptions?.goal?.amount?.toFixed(2) ?? '',
          ),
        },
      },
      leaderboard: {
        enabled: p2pFundraising?.leaderboard?.enabled ?? false,
        type: p2pFundraising?.leaderboard?.type ?? 'participant',
      },
    },
    onSubmit: async (values) => {
      if (formik.dirty) {
        await updateTabMutation.mutateAsync({
          pathParams: {
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            tabId: urlParams.fundraiser!,
          },
          body: {
            options: {
              p2pFundraising: {
                ...values,
                participantOptions: {
                  ...values.participantOptions,
                  noteToDonors: {
                    ...values.participantOptions.noteToDonors,
                    suggestedTextEnabled:
                      values.participantOptions.noteToDonors
                        .suggestedTextEnabled &&
                      values.participantOptions.noteToDonors.suggestedText
                        .length > 0,
                  },
                  goal: {
                    enabled: values.participantOptions.goal.enabled,
                    type: values.participantOptions.goal.type,
                    amount: Number(values.participantOptions.goal.amount),
                  },
                },
              },
            },
          },
        })
      }
      navigate('../donation')
    },
  })

  return (
    <form
      className="contents"
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
    >
      <DashboardPageContainer>
        <DashboardPageContent>
          <DashboardPageHeader>
            <DashboardPageHeading>Peer-to-Peer Options</DashboardPageHeading>
            <NextUI.Text>
              Enlisting participants is essential to a successful peer-to-peer
              fundraiser because it expands your organization’s reach. Customize
              participant registration and participant fundraising page options
              below.{' '}
              <NextUI.NextAnchor
                href="#" // TODO: to be added
              >
                Example Participant Fundraising Page
              </NextUI.NextAnchor>
            </NextUI.Text>
          </DashboardPageHeader>

          <DashboardContentLayout>
            <DashboardPanel>
              <DashboardPanelHeader>
                <NextUI.PanelLabel>Participant Registration</NextUI.PanelLabel>
                <NextUI.Text>
                  After your fundraiser has been published, you can add
                  participants (or they can register themselves), which will
                  create their individual participant fundraising pages.
                </NextUI.Text>
              </DashboardPanelHeader>
              <SettingDisclosure
                open={formik.values.participantOptions.allowSelfSignUp}
                setOpen={(visible) =>
                  formik.setFieldValue(
                    'participantOptions.allowSelfSignUp',
                    visible,
                  )
                }
              >
                <SettingDisclosureSwitch>
                  Include a public registration link on main fundraising page
                </SettingDisclosureSwitch>
                <SettingDisclosureContent>
                  When you add and invite participants, they receive an email
                  that allows them to view and customize their fundraising page.
                  Add a customized message to this email.
                </SettingDisclosureContent>
              </SettingDisclosure>
              <SettingDisclosure
                open={
                  formik.values.participantOptions.customInviteMessage.enabled
                }
                setOpen={(value) =>
                  formik.setFieldValue(
                    'participantOptions.customInviteMessage.enabled',
                    value,
                  )
                }
              >
                <SettingDisclosureSwitch>
                  Add a custom message to the participant registration email
                </SettingDisclosureSwitch>
                <SettingDisclosureContent className="gap-0">
                  <WebUI.FormField
                    label="Add a custom message to the participant fundraising page invitation
            email."
                    className="[&_>_.FormField-caption]:block [&_>_.FormField-caption]:text-ds-sm [&_>_.FormField-label]:font-light"
                  >
                    <WebUI.Textarea
                      name="participantOptions.customInviteMessage.message"
                      maxLength={MESSAGE_TO_PARTICIPANT_MAX_LEN}
                      placeholder={`The Fun Org PTA is thrilled to kick off the annual Fun-Athon! We encourage you to invite your
friends and family to support this exciting event — the more, the merrier! And most importantly,
don’t forget to have fun, as that’s what this event is all about!

Cheers!`}
                      rows={6}
                      value={
                        formik.values.participantOptions.customInviteMessage
                          .message
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                  <div className="flex justify-between">
                    <NextUI.Text className="font-normal text-ds-sm text-grey-500">
                      {`${formik.values.participantOptions.customInviteMessage.message.length} / ${MESSAGE_TO_PARTICIPANT_MAX_LEN}`}
                    </NextUI.Text>
                    {formik.values.participantOptions.customInviteMessage
                      .message.length > 0 && (
                      <NextUI.NextButton
                        className="bg-teal-200 text-teal-600"
                        variant="headless"
                        size="xs"
                      >
                        Send Test Email
                      </NextUI.NextButton>
                    )}
                  </div>
                </SettingDisclosureContent>
              </SettingDisclosure>
            </DashboardPanel>

            <DashboardPanel>
              <DashboardPanelHeader>
                <NextUI.PanelLabel>
                  Participant Fundraising Pages
                </NextUI.PanelLabel>
                <NextUI.Text>
                  Each registered participant has their own fundraising page,
                  which increases engagement, and ultimately funds raised.
                  Control what they can customize on their page below.
                </NextUI.Text>
              </DashboardPanelHeader>
              <DashboardSettingContent>
                <DashboardSettingPanel>
                  <NextUI.Switch
                    className="flex-row-reverse justify-between"
                    size="lg"
                    checked={formik.values.participantOptions.allowNameChanges}
                    onChange={(event) =>
                      formik.setFieldValue(
                        'participantOptions.allowNameChanges',
                        event.target.checked,
                      )
                    }
                  >
                    Allow participants to change their display name
                  </NextUI.Switch>
                </DashboardSettingPanel>
                <DashboardSettingPanel>
                  <NextUI.Switch
                    className="flex-row-reverse justify-between"
                    size="lg"
                    checked={formik.values.participantOptions.hideLastNames}
                    onChange={(event) => {
                      formik.setFieldValue(
                        'participantOptions.hideLastNames',
                        event.target.checked,
                      )
                    }}
                  >
                    Only show participants first name and last initial for
                    privacy
                  </NextUI.Switch>
                </DashboardSettingPanel>
                <SettingDisclosure
                  open={formik.values.participantOptions.allowOwnMedia}
                  setOpen={(visible) =>
                    formik.setFieldValue(
                      'participantOptions.allowOwnMedia',
                      visible,
                    )
                  }
                >
                  <SettingDisclosureSwitch>
                    Allow participants to upload their own image or video
                  </SettingDisclosureSwitch>
                  <SettingDisclosureContent>
                    A unique image or video helps donors relate to your cause on
                    a personal level.
                  </SettingDisclosureContent>
                </SettingDisclosure>
                <SettingDisclosure
                  open={formik.values.participantOptions.noteToDonors.enabled}
                  setOpen={(visible) =>
                    formik.setFieldValue(
                      'participantOptions.noteToDonors.enabled',
                      visible,
                    )
                  }
                >
                  <SettingDisclosureSwitch>
                    Allow participants to include a short note to potential
                    donors
                  </SettingDisclosureSwitch>
                  <SettingDisclosureContent className="[&_>_.DisclosureContent-inner]:gap-4">
                    This note is in addition to the details about your
                    fundraiser that will be included on all participant pages.
                    <WebUI.Checkbox
                      className="[&_>_.Checkbox-text]:font-light"
                      checked={
                        formik.values.participantOptions.noteToDonors
                          .suggestedTextEnabled
                      }
                      onChange={(event) =>
                        formik.setFieldValue(
                          'participantOptions.noteToDonors.suggestedTextEnabled',
                          event.target.checked,
                        )
                      }
                    >
                      Suggest text for participant’s note to potential donors
                    </WebUI.Checkbox>
                    {formik.values.participantOptions.noteToDonors
                      .suggestedTextEnabled && (
                      <WebUI.FormField
                        className="[&_>_.FormField-caption]:block [&_>_.FormField-caption]:text-ds-sm"
                        caption={`${formik.values.participantOptions.noteToDonors.suggestedText.length} / ${MESSAGE_TO_PARTICIPANT_MAX_LEN}`}
                      >
                        <WebUI.Textarea
                          name="participantOptions.noteToDonors.suggestedText"
                          maxLength={MESSAGE_TO_PARTICIPANT_MAX_LEN}
                          placeholder={`Hi friends and family!
Please consider making a donation to my page this year so that I can reach my goal and help the
cause. Help to SPREAD THE WORD by sharing my fundraising page with your friends and family, and
asking them to give!`}
                          rows={6}
                          value={
                            formik.values.participantOptions.noteToDonors
                              .suggestedText
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </WebUI.FormField>
                    )}
                  </SettingDisclosureContent>
                </SettingDisclosure>
                <SettingDisclosure
                  open={formik.values.leaderboard.enabled}
                  setOpen={(visible) =>
                    formik.setFieldValue('leaderboard.enabled', visible)
                  }
                >
                  <SettingDisclosureSwitch>
                    Show leaderboard with top fundraising teams or donors on the
                    participant’s page
                  </SettingDisclosureSwitch>
                  <SettingDisclosureContent>
                    <NextUI.RadioGroup
                      className="[&_>_label]:text-grey-800"
                      variant="teal"
                      size="sm"
                      value={formik.values.leaderboard.type}
                      setValue={(value) =>
                        formik.setFieldValue('leaderboard.type', value)
                      }
                    >
                      <NextUI.Radio value="team">
                        Teams Leaderboard (Teams must be enabled when adding
                        participants)
                      </NextUI.Radio>
                      <NextUI.Radio value="participant">
                        Donors Leaderboard (Displays the highest contributions)
                      </NextUI.Radio>
                    </NextUI.RadioGroup>
                  </SettingDisclosureContent>
                </SettingDisclosure>
              </DashboardSettingContent>
            </DashboardPanel>

            <DashboardPanel>
              <NextUI.PanelLabel>Goal Setting</NextUI.PanelLabel>
              <SettingDisclosure
                className="gap-4"
                open={formik.values.participantOptions.goal.enabled}
                setOpen={(visible) =>
                  formik.setFieldValue(
                    'participantOptions.goal.enabled',
                    visible,
                  )
                }
              >
                <SettingDisclosureSwitch>
                  Display a fundraising goal on participant fundraising pages
                </SettingDisclosureSwitch>
                <SettingDisclosureContent>
                  <WebUI.FormFieldGroup>
                    <WebUI.FormField
                      label="Participation Goal"
                      className="sm:max-w-md"
                      required
                    >
                      <WebUI.DropdownSelect<
                        PeerToPeerOptionsFormValues['participantOptions']['goal']['type']
                      >
                        placeholder="Select Participation Goal"
                        value={formik.values.participantOptions.goal.type}
                        onValueChange={(value) =>
                          formik.setFieldValue(
                            'participantOptions.goal.type',
                            value,
                          )
                        }
                      >
                        <WebUI.DropdownSelectOption value="set_your_own">
                          Participants can set their own donation goal
                        </WebUI.DropdownSelectOption>
                        <WebUI.DropdownSelectOption value="all_the_same">
                          All participants have the same donation goal
                        </WebUI.DropdownSelectOption>
                        <WebUI.DropdownSelectOption value="no_goal">
                          There is no donation goal
                        </WebUI.DropdownSelectOption>
                      </WebUI.DropdownSelect>
                    </WebUI.FormField>
                    {formik.values.participantOptions.goal.type ===
                      'all_the_same' && (
                      <WebUI.FormField
                        className="sm:max-w-48"
                        label="Goal Amount"
                        required
                      >
                        <WebUI.AmountInput
                          name="amount"
                          placeholder="$0.00"
                          value={formik.values.participantOptions.goal.amount}
                          onValueChange={(newAmount) =>
                            formik.setFieldValue(
                              'participantOptions.goal.amount',
                              newAmount,
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                      </WebUI.FormField>
                    )}
                  </WebUI.FormFieldGroup>
                </SettingDisclosureContent>
              </SettingDisclosure>
            </DashboardPanel>
          </DashboardContentLayout>
        </DashboardPageContent>
      </DashboardPageContainer>

      <DashboardPageFooter>
        <NextUI.NextButton
          type="submit"
          size="md"
          loading={formik.isSubmitting}
        >
          Continue <WebUI.PhosphorIcon icon="caret-right" />
        </NextUI.NextButton>
      </DashboardPageFooter>
    </form>
  )
}

export default PeerToPeerOptionsPage
