import * as Ariakit from '@ariakit/react'
import React, {useRef} from 'react'
import {useDateSegment} from 'react-aria'
import type {
  DateFieldState,
  DateSegment as DateSegmentType,
  TimeFieldState,
} from 'react-stately'
import {useForkRef} from '@cheddarup/react-util'

import {cn} from '../../utils'

export interface DateSegmentProps extends Ariakit.RoleProps<'div'> {
  segment: DateSegmentType
  state: TimeFieldState | DateFieldState
}

export const DateSegment = React.forwardRef<HTMLDivElement, DateSegmentProps>(
  ({segment, state, className, style, ...restProps}, forwardedRef) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const {
      segmentProps: {style: segmentStyle, ...segmentProps},
    } = useDateSegment(segment, state, ownRef)

    return (
      <Ariakit.Role.div
        ref={ref}
        data-placeholder={
          segment.isPlaceholder ||
          (!segment.isEditable && state.segments.some((s) => s.isPlaceholder))
        }
        // TODO: Ask Molly to provide a monospace font for this
        className={cn(
          'relative box-content rounded px-0_5 text-right tabular-nums transition-colors',
          'outline-none focus:bg-teal-100 focus:ring focus:ring-teal-600',
          'hover:bg-grey-100',
          'data-[placeholder=true]:text-grey-300',
          'data-[segment-type=literal]:text-grey-300',
          className,
        )}
        style={{
          minWidth:
            segment.maxValue == null
              ? undefined
              : `${String(segment.maxValue).length}ch`,
          ...segmentStyle,
          ...style,
        }}
        {...segmentProps}
        {...restProps}
      >
        {segment.isPlaceholder && (
          <span aria-hidden className="block w-full text-center">
            {segment.placeholder}
          </span>
        )}
        {segment.isPlaceholder ? '' : segment.text}
      </Ariakit.Role.div>
    )
  },
)
