import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {useManagerRole} from 'src/components/ManageRoleProvider'
import CreateCollectionButton from './CreateCollectionButton'
import FundraiserTypeSelectionModal from './FundraiserTypeSelectionModal'

export interface CreateCollectionMenuProps
  extends WebUI.MenuButtonProps,
    WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {}

const CreateCollectionMenu: React.FC<CreateCollectionMenuProps> = (props) => {
  const [managerRole] = useManagerRole()

  return (
    <WebUI.Menu>
      <WebUI.MenuButton
        variant="primary"
        disabled={managerRole?.canCreate === false}
        {...props}
      >
        Create a Collection
      </WebUI.MenuButton>

      <WebUI.MenuList>
        <WebUI.MenuItem
          as={CreateCollectionButton}
          iconBefore={<WebUI.PhosphorIcon icon="suitcase-simple" />}
          hideOnClick={false}
        >
          Standard Collection
        </WebUI.MenuItem>
        <FundraiserTypeSelectionModal
          disclosure={
            <WebUI.MenuItem
              as={WebUI.DialogDisclosure}
              iconBefore={<WebUI.PhosphorIcon icon="hand-heart" />}
              hideOnClick={false}
            >
              <span className="font-normal">
                <span className="font-bold text-orange-600">NEW</span>{' '}
                Peer-to-Peer Fundraiser
              </span>
            </WebUI.MenuItem>
          }
        />
      </WebUI.MenuList>
    </WebUI.Menu>
  )
}

export default CreateCollectionMenu
