import {api} from '@cheddarup/api-client'
import {useParams} from 'react-router-dom'

export function useCurrentFundraiserId() {
  const urlParams = useParams()

  if (urlParams.fundraiser != null) {
    return Number(urlParams.fundraiser)
  }

  return urlParams.fundraiser
}

export function useCurrentFundraiserQuery() {
  const currentFundraiserId = useCurrentFundraiserId()
  const fundraiserQuery = api.tabs.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        tabId: currentFundraiserId!,
      },
    },
    {
      enabled: currentFundraiserId != null,
    },
  )

  return [fundraiserQuery, currentFundraiserId] as const
}
