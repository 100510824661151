import {z} from '@cheddarup/util'

export const participantOptionsSchema = z.object({
  aboutMeText: z.string(),
  thankYouEmailText: z.string(),
  hideDonors: z.boolean(),
  donationGoal: z.number(),
  activityGoal: z.number(),
})

export const participantEmailInviteStatus = z.enum(['sent', 'active', 'failed'])

export const participantSchema = z.object({
  archived: z.boolean(),
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email(),
  team_id: z.number(),
  invite_email_status: participantEmailInviteStatus.nullable(),
  invite_sent_at: z.string().nullable(),
  options: participantOptionsSchema,
  team: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
  donations: z.number(),
  totalCollected: z.number(),
})

declare global {
  namespace Api {
    type FundraiserParticipant = z.infer<typeof participantSchema>
  }
}
