import {
  DashboardPageContainer,
  DashboardPageContent,
  DashboardPageHeader,
  DashboardPageHeading,
} from 'src/components/DashboardPageLayout'
import {DashboardContentLayout} from 'src/components/Layout'
import {
  ShareByHTMLButtonPanel,
  ShareByQRCodePanel,
  ShareLinkPanel,
} from 'src/views/collection/share/components/InviteOthersPanels'
import TextToPayPanel from 'src/views/collection/share/components/TextToPayPanel'
import {
  AddParticipantsReminderDisclosure,
  FundraiserPublishPanel,
} from './components'
import {useState} from 'react'
import {useCurrentFundraiserQuery} from '../hooks'

const FundraiserShareLinkPage = () => {
  const [{data: fundraiser}] = useCurrentFundraiserQuery()

  const [isPublished, setIsPublished] = useState(
    fundraiser?.status === 'active',
  )

  return (
    <DashboardPageContainer>
      <DashboardPageContent>
        <DashboardPageHeader>
          <DashboardPageHeading>Publish</DashboardPageHeading>
        </DashboardPageHeader>
        {fundraiser && (
          <DashboardContentLayout className="gap-4">
            {!isPublished && (
              <FundraiserPublishPanel
                fundraiser={fundraiser}
                onDidPublish={() =>
                  setTimeout(() => setIsPublished(true), 2000)
                }
              />
            )}
            <AddParticipantsReminderDisclosure open={isPublished} />
            <ShareLinkPanel
              aria-disabled={fundraiser?.status === 'draft'}
              collection={fundraiser}
            />
            <div
              aria-disabled={fundraiser?.status === 'draft'}
              className="flex flex-col gap-4 aria-disabled:pointer-events-none aria-disabled:cursor-not-allowed aria-disabled:opacity-70 lg:flex-row lg:[&_>_.Panel]:flex-[1_0_0px]"
            >
              <ShareByHTMLButtonPanel collection={fundraiser} />
              <ShareByQRCodePanel collection={fundraiser} />
              <TextToPayPanel collection={fundraiser} />
            </div>
          </DashboardContentLayout>
        )}
      </DashboardPageContent>
    </DashboardPageContainer>
  )
}

export default FundraiserShareLinkPage
