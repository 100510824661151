import {BrowserRouter} from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import '@cheddarup/tailwind-config/base.css'

import './init'
import './index.css'
import {RootLayout} from './RootLayout'
import {SentryErrorBoundary} from './components/ErrorBoundary'

// biome-ignore lint/style/noNonNullAssertion:
ReactDOM.createRoot(document.querySelector('#root')!).render(
  <BrowserRouter future={{v7_startTransition: true}}>
    <SentryErrorBoundary>
      <RootLayout />
    </SentryErrorBoundary>
  </BrowserRouter>,
)
