import {api} from '@cheddarup/api-client'
import {getPayerBrandKitColors} from '@cheddarup/core'
import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import {NextLink} from 'src/components/__next/Link'
import {LinkButton} from 'src/components/LinkButton'
import {SharpImage} from 'src/components/SharpImage'
import {ColorRow} from 'src/views/group/GroupPage/GroupPage'

export interface BrandingUpsellPanelProps extends NextUI.PanelProps {}

export const BrandingUpsellPanel = ({
  className,
  ...restProps
}: BrandingUpsellPanelProps) => {
  const {data: branding} = api.userBrandings.detail.useQuery()

  const brandingEnabled =
    branding?.color_palette.payerPage.enabled || branding?.logo
  const brandKitColors = getPayerBrandKitColors(
    branding?.color_palette.payerPage,
  )

  return (
    <NextUI.Panel className={WebUI.cn('gap-5', className)} {...restProps}>
      <NextUI.PanelLabel>Branding</NextUI.PanelLabel>

      <NextUI.Text>
        Replace Cheddar Up’s logo at the top of all of your pages and customize
        button colors within your{' '}
        <NextLink to="my-account/brand-kit-logo">Brand Kit</NextLink>.
      </NextUI.Text>

      {brandingEnabled ? (
        <div className="contents divide-y">
          {branding.color_palette.payerPage.enabled && (
            <div className="flex flex-col gap-2">
              <NextLink variant="text" to="my-account/brand-kit-palette">
                <ColorRow color={brandKitColors.primaryButton}>
                  Primary
                </ColorRow>
              </NextLink>
              <NextLink variant="text" to="my-account/brand-kit-palette">
                <ColorRow color={brandKitColors.secondaryButton}>
                  Secondary
                </ColorRow>
              </NextLink>
            </div>
          )}
          {branding.enable_brand_logo && branding.logo && (
            <NextLink to="my-account/brand-kit-logo">
              <SharpImage
                image={branding.logo?.url}
                alt="Custom logo"
                className="max-h-13 w-fit"
              />
            </NextLink>
          )}
        </div>
      ) : (
        <LinkButton
          variant="default"
          className="w-40 self-start"
          to="my-account/brand-kit-logo"
        >
          Add Branding
        </LinkButton>
      )}
    </NextUI.Panel>
  )
}
