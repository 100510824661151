import {z} from '@cheddarup/util'
import {paginationSchema, participantSchema} from '../schemas'
import {makeEndpoint} from '../utils'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:fundraiserId/participants',
    queryParamsSchema: z
      .object({
        page: z.number(),
        perPage: z.number(),
        name_or_email: z.string(),
      })
      .optional(),
    responseSchema: z.object({
      data: participantSchema.array(),
      pagination: paginationSchema,
    }),
  }),
  search: makeEndpoint({
    path: 'users/tabs/:fundraiserId/participants/suggestions',
    queryParamsSchema: z
      .object({
        keyword: z.string(),
      })
      .optional(),
    responseSchema: participantSchema.array(),
  }),
  update: makeEndpoint({
    path: 'users/tabs/:fundraiserId/participants/:id',
    method: 'PUT',
    bodySchema: participantSchema
      .pick({
        first_name: true,
        last_name: true,
        email: true,
        team_id: true,
        archived: true,
      })
      .partial(),
    responseSchema: participantSchema,
  }),
  createBulk: makeEndpoint({
    path: 'users/tabs/:fundraiserId/participants/bulk_upload',
    method: 'POST',
    bodySchema: z.object({
      participants: z.array(
        participantSchema.pick({
          first_name: true,
          last_name: true,
          email: true,
        }),
      ),
    }),
    responseSchema: z.object({
      message: z.string(),
      results: z.object({
        failed: participantSchema.array(),
        successful: participantSchema.array(),
        updated: participantSchema.array(),
      }),
    }),
  }),
  deleteBulk: makeEndpoint({
    path: 'users/tabs/:fundraiserId/participants/bulk_delete',
    method: 'DELETE',
    bodySchema: z.object({
      participant_ids: z.number().array(),
    }),
    responseSchema: z.void(),
  }),
  sendInvite: makeEndpoint({
    path: 'users/tabs/:fundraiserId/participants/send_invite',
    method: 'POST',
    bodySchema: z.object({
      type: z.enum(['new', 'all', 'specific']),
      participant_ids: z.number().array().optional(),
    }),
    responseSchema: z.void(),
  }),
  resendInvite: makeEndpoint({
    path: 'users/tabs/:fundraiserId/participants/:id/resend_invite',
    method: 'POST',
    responseSchema: z.void(),
  }),
}
