// Inspired by https://github.com/primer/react/blob/main/packages/react/src/NavList/NavList.tsx

import React, {useContext, useMemo, useState} from 'react'
import * as AriaKit from '@ariakit/react'
import {Merge} from '@cheddarup/util'
import {PhosphorIcon} from '../icons'
import {cn, VariantsProps} from '../utils'
import {genericForwardRef} from '@cheddarup/react-util'
import {NextAnchorButton, NextButton, NextButtonProps} from './__next'
import {cva} from 'class-variance-authority'

export interface NavListProps
  extends Omit<
      AriaKit.CompositeStoreProps,
      'items' | 'defaultItems' | 'setActiveId' | 'setItems'
    >,
    AriaKit.RoleProps<'nav'> {
  onActiveIdChange?: AriaKit.CompositeStoreProps['setActiveId']
}

export const NavList = React.forwardRef<HTMLElement, NavListProps>(
  (
    {
      defaultActiveId,
      activeId,
      onActiveIdChange,
      includesBaseElement = false,
      focusWrap = true,
      focusLoop = true,
      focusShift = false,
      virtualFocus = false,
      orientation,
      store,
      rtl,
      ...restProps
    },
    forwardedRef,
  ) => {
    const composieStore = AriaKit.useCompositeStore({
      defaultActiveId,
      activeId,
      setActiveId: onActiveIdChange,
      includesBaseElement,
      focusWrap: 'vertical',
      focusLoop,
      focusShift,
      virtualFocus,
      store,
      rtl,
      orientation,
    })

    return (
      <AriaKit.CompositeProvider store={composieStore}>
        <AriaKit.Composite
          render={<AriaKit.Role.nav ref={forwardedRef} {...restProps} />}
        />
      </AriaKit.CompositeProvider>
    )
  },
)

// MARK: – NavListContent

export const navListContent = cva('group/nav-list-content font-normal', {
  variants: {
    variant: {
      default: '',
      slideOut: 'min-w-0 transition-[min-width] hover:min-w-56',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

interface InternalNavListContentContextValue
  extends VariantsProps<typeof navListContent> {}

const InternalNavListContentContext =
  React.createContext<InternalNavListContentContextValue | null>(null)

function useNavListContent() {
  const contextValue = useContext(InternalNavListContentContext)

  if (!contextValue) {
    throw new Error('Missing `NavListContent`')
  }

  return contextValue
}

export interface NavListContentProps
  extends AriaKit.RoleProps<'ul'>,
    VariantsProps<typeof navListContent> {}

export const NavListContent = React.forwardRef<
  HTMLUListElement,
  NavListContentProps
>(({variant, className, ...restProps}, forwardedRef) => {
  const uiContextValue: InternalNavListContentContextValue = useMemo(
    () => ({
      variant,
    }),
    [variant],
  )

  return (
    <InternalNavListContentContext.Provider value={uiContextValue}>
      <AriaKit.Role.ul
        ref={forwardedRef}
        className={cn(navListContent({variant}), className)}
        {...restProps}
      />
    </InternalNavListContentContext.Provider>
  )
})

// MARK: – NavListItem

export const navListItem = cva('', {
  variants: {
    variant: {
      default: 'h-10',
      slideOut: '',
    },
  },
})

export interface NavListItemProps extends AriaKit.RoleProps<'li'> {}

export const NavListItem = React.forwardRef<HTMLLIElement, NavListItemProps>(
  ({className, ...restProps}, forwardedRef) => {
    const navListContent = useNavListContent()

    return (
      <AriaKit.Role.li
        ref={forwardedRef}
        className={cn(
          navListItem({variant: navListContent.variant}),
          className,
        )}
        {...restProps}
      />
    )
  },
)

// MARK: – NavListItemContent

export const navListItemContent = cva(
  [
    'font-normal outline-none',
    'hover:opacity-50',
    'ring-inset data-[focus-visible]:ring data-[focus-visible]:ring-teal-600',
  ],
  {
    variants: {
      variant: {
        default:
          'rounded py-2 pr-3 pl-[calc(theme(spacing.6)*(var(--nav-list-depth,0)+1))] text-grey-700',
        slideOut: [
          'rounded-none px-5 py-4 transition-[display] delay-1000',
          'group-[&:not(:hover)]/nav-list-content:gap-0 [&:not(:first-child)]:*:invisible [&:not(:first-child)]:*:w-0 [&:not(:first-child)]:*:group-hover/nav-list-content:visible',
        ],
      },
    },
  },
)

export type NavListItemContentProps<
  T extends React.ElementType = 'button' | 'a',
> = AriaKit.CompositeItemProps<T>

export const NavListItemContent = genericForwardRef(
  <T extends React.ElementType = 'button'>(
    {className, disabled, ...restProps}: NavListItemContentProps<T>,
    forwardedRef: React.Ref<T>,
  ) => {
    const navListSubNav = useContext(InternalNavListSubNavContext)
    const navListContent = useNavListContent()

    return (
      <AriaKit.CompositeItem
        ref={forwardedRef as any}
        className={cn(
          navListItemContent({variant: navListContent.variant}),
          className,
        )}
        disabled={disabled ?? (!!navListSubNav && !navListSubNav.visible)}
        {...restProps}
      />
    )
  },
)

// MARK: – InternalNavListSubNavContext

interface InternalNavListSubNavContextValue {
  depth: number
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const InternalNavListSubNavContext = React.createContext(
  null as InternalNavListSubNavContextValue | null,
)

// MARK: – NavListSubNav

export interface NavListSubNavProps extends NavListContentProps {
  defaultVisible?: boolean
  disclosure: React.ReactNode
}

export const NavListSub = React.forwardRef<
  HTMLUListElement,
  NavListSubNavProps
>(
  (
    {defaultVisible, disclosure, className, style, children, ...restProps},
    forwardedRef,
  ) => {
    const [visible, setVisible] = useState(defaultVisible ?? false)
    const navListSubNav = useContext(InternalNavListSubNavContext)

    const depth = (navListSubNav?.depth ?? 0) + 1

    const contextValue: InternalNavListSubNavContextValue = useMemo(
      () => ({
        depth,
        visible,
        setVisible,
      }),
      [visible, depth],
    )

    return (
      <InternalNavListSubNavContext.Provider value={contextValue}>
        {disclosure}

        <NavListContent
          data-visible={visible}
          ref={forwardedRef}
          className={cn(
            'invisible relative h-0 scale-90 overflow-hidden opacity-0 transition data-[visible=true]:visible data-[visible=true]:h-auto data-[visible=true]:scale-100 data-[visible=true]:opacity-100',
            className,
          )}
          style={{
            '--nav-list-depth': depth,
            ...style,
          }}
          {...restProps}
        >
          {children}
        </NavListContent>
      </InternalNavListSubNavContext.Provider>
    )
  },
)

export function useNavListSubNav() {
  return useContext(InternalNavListSubNavContext)
}

// MARK: – NavListSubDisclosure

export interface NavListSubDisclosureProps extends NextButtonProps {}

export const NavListSubDisclosure = React.forwardRef<
  HTMLButtonElement,
  NavListSubDisclosureProps
>(({className, onClick, children, ...restProps}, forwardedRef) => {
  const navListSub = useContext(InternalNavListSubNavContext)

  return (
    <NavListItemContent
      disabled={navListSub?.depth !== 1}
      render={
        <NextButton
          aria-expanded={navListSub?.visible}
          ref={forwardedRef}
          className={cn(
            'h-full w-full justify-start pr-2',
            'pl-[calc(theme(spacing.6)*(var(--nav-list-depth,0))]',
            className,
          )}
          variant="headless"
          size="headless"
          roundness="headless"
          onClick={(event) => {
            onClick?.(event)

            if (event.defaultPrevented) {
              return
            }

            navListSub?.setVisible((prevVisible) => !prevVisible)
          }}
          {...restProps}
        >
          <PhosphorIcon
            className={`text-ds-base transition-transform [.Button[aria-expanded="true"]_>_.Button-iconBefore_>_&]:rotate-90`}
            icon="caret-right"
          />
          {children}
        </NextButton>
      }
    />
  )
})

// MARK: – NavListItemAnchor

export const navListItemAnchor = cva('h-full w-full justify-start text-left', {
  variants: {
    variant: {
      default:
        'aria-current-page:font-semibold aria-current-page:text-orange-500',
      slideOut: '',
    },
  },
})

export interface NavListItemAnchorProps
  extends Merge<NavListItemContentProps, React.ComponentPropsWithoutRef<'a'>> {}

export const NavListItemAnchor = React.forwardRef<
  HTMLAnchorElement,
  NavListItemAnchorProps
>(({className, ...restProps}, forwardedRef) => {
  const navListContent = useNavListContent()

  return (
    <NavListItemContent<'a'>
      ref={forwardedRef}
      className={cn(
        navListItemAnchor({variant: navListContent.variant}),
        className,
      )}
      render={
        <NextAnchorButton size="headless" roundness="none" variant="headless" />
      }
      {...restProps}
    />
  )
})
