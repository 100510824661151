import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import {SharePanelHeading} from './SharePanelHeading'
import TextToPayModal, {TEXT_TO_PAY_CONTACT} from './TextToPayModal'
import React, {useRef} from 'react'
import {useDeleteTabDonationCodeMutation} from '@cheddarup/api-client'

export interface TextToPayPanelProps
  extends WebUI.PanelProps,
    React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

const TextToPayPanel = ({
  collection,
  className,
  ...restProps
}: TextToPayPanelProps) => {
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const deleteTabDonationCodeMutation = useDeleteTabDonationCodeMutation()
  const growActions = WebUI.useGrowlActions()

  const keyword = collection.donationCode?.code

  return (
    <NextUI.Panel
      className={WebUI.cn(
        'relative flex flex-col gap-5 px-8 py-7 sm:rounded-default',
        className,
      )}
      {...restProps}
    >
      <SharePanelHeading iconName="chat-dots">Text-to-Pay</SharePanelHeading>
      <NextUI.Text className="grow">
        Share a keyword for payers to text to {TEXT_TO_PAY_CONTACT} and we’ll
        reply with your link.
      </NextUI.Text>
      {keyword ? (
        <NextUI.Panel className="flex h-9 items-center justify-between px-3">
          <NextUI.Text className="text-ds-sm">{keyword}</NextUI.Text>
          <WebUI.ActionGroup className="border-0" maxVisible={1}>
            <WebUI.Action onClick={() => modalRef.current?.show()}>
              Edit
            </WebUI.Action>
            <WebUI.Action
              execute={async () => {
                await deleteTabDonationCodeMutation.mutateAsync({
                  pathParams: {tabId: collection.id},
                })
                growActions.show('success', {
                  body: 'Code has been deleted successfully',
                })
              }}
            >
              Delete
            </WebUI.Action>
          </WebUI.ActionGroup>
        </NextUI.Panel>
      ) : (
        <>
          <NextUI.NextButton
            disabled={collection?.status === 'draft'}
            onClick={() => modalRef.current?.show()}
          >
            Create Your Keyword
          </NextUI.NextButton>
          {!collection.options.p2pFundraising && (
            <span
              className={WebUI.cn(
                'flex h-[28px] w-[120px] items-center justify-center font-accentAlt text-teal-50 uppercase',
                '-right-8 absolute top-4 rotate-45',
                'bg-gradient-to-b from-[#CBEBEF] via-[#E7F6F8] to-[#CBEBEF]',
              )}
            >
              NEW!
            </span>
          )}
        </>
      )}

      <TextToPayModal ref={modalRef} collection={collection} />
    </NextUI.Panel>
  )
}

export default TextToPayPanel
