import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import {useForkRef, useFormik} from '@cheddarup/react-util'
import React, {useMemo, useRef} from 'react'
import ReactDOM from 'react-dom/server'
import {useUpdateTabMutation} from '@cheddarup/api-client'
import config from 'src/config'
import {LinkButton} from 'src/components/LinkButton'
import cheddarUpPayIconPath from 'src/images/CheddarUpPay.svg?url'
import {ShareQRCodeLinkModal} from 'src/components/ShareQRCodeLinkModal'
import {shareEventTracking} from 'src/helpers/analytics'
import {useManagerRole} from 'src/components/ManageRoleProvider'
import FacebookLogo from 'src/images/facebook-logo.svg'
import {SharePanelHeading} from './SharePanelHeading'
import {isUrl} from '@cheddarup/util'

export interface ShareLinkPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const ShareLinkPanel = ({
  collection,
  className,
  ...restProps
}: ShareLinkPanelProps) => {
  const link =
    collection.status === 'draft'
      ? null
      : config.helpers.shareUrl(collection.slug)
  const isFundraiser = !!collection.options.p2pFundraising

  return (
    <NextUI.Panel
      className={WebUI.cn(
        'flex flex-col gap-4 px-8 py-7 sm:rounded-default',
        className,
      )}
      {...restProps}
    >
      <SharePanelHeading iconName="link">Share with a link</SharePanelHeading>

      <div className="flex flex-col gap-2">
        <NextUI.Text>
          {isFundraiser
            ? 'Copy and share your main fundraising page URL or choose another sharing method below. '
            : 'Copy and share your collection URL or choose another sharing method below. '}
          <NextUI.NextAnchor
            href={config.links.shareCollection}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </NextUI.NextAnchor>
          .
        </NextUI.Text>
        <CopyCollectionShareLink collection={collection} includeFacebookLink />
      </div>
      <EditLinkPrompt
        collection={collection}
        disclosure={
          <WebUI.DialogDisclosure
            className="gap-2 font-normal text-ds-sm"
            variant="link"
            disabled={!link}
          >
            Edit Link{' '}
            <WebUI.PhosphorIcon className="text-xl" icon="pencil-simple" />
          </WebUI.DialogDisclosure>
        }
      />
    </NextUI.Panel>
  )
}

// MARK: – CopyCollectionShareLink

export interface CopyCollectionShareLinkProps
  extends React.ComponentPropsWithoutRef<'div'> {
  includeFacebookLink?: boolean
  collection: Api.Tab
}

export const CopyCollectionShareLink = ({
  className,
  includeFacebookLink,
  collection,
  ...restProps
}: CopyCollectionShareLinkProps) => {
  const growlActions = WebUI.useGrowlActions()
  const link =
    collection.status === 'draft'
      ? null
      : config.helpers.shareUrl(collection.slug)

  return (
    <div
      className={WebUI.cn(
        'flex w-full max-w-screen-md flex-col gap-2 sm:flex-row',
        className,
      )}
      {...restProps}
    >
      {link != null && (
        <NextUI.Input
          className="grow read-only:ring-1"
          readOnly
          size="default"
          value={link}
        />
      )}
      <div className="flex gap-2">
        <NextUI.NextButton
          disabled={!link}
          onClick={() => {
            WebUI.copyToClipboard(link ?? '')
            shareEventTracking(collection, 'link', 'link')
            growlActions.show('success', {
              title: 'Success',
              body: 'Link copied',
            })
          }}
        >
          Copy link
        </NextUI.NextButton>
        {includeFacebookLink && (
          <NextUI.NextAnchorButton
            className="h-auto w-auto p-0"
            variant="headless"
            disabled={!link}
            rel="noopener noreferrer"
            target="_blank"
            href={
              link ? `https://www.facebook.com/sharer/sharer.php?u=${link}` : ''
            }
            onClick={() =>
              shareEventTracking(collection, 'facebook', 'collection link')
            }
          >
            <WebUI.Image
              width={40}
              height={40}
              alt="Facebook logo"
              src={FacebookLogo}
            />
          </NextUI.NextAnchorButton>
        )}
      </div>
    </div>
  )
}

// MARK: – EditLinkPrompt

interface EditLinkPromptProps extends WebUI.PromptProps {
  collection: Api.Tab
}

const EditLinkPrompt = React.forwardRef<
  WebUI.DialogInstance,
  EditLinkPromptProps
>(({collection, ...restProps}, forwardedRef) => {
  const ownRef = useRef<WebUI.DialogInstance>(null)
  const ref = useForkRef(ownRef, forwardedRef)
  const growlActions = WebUI.useGrowlActions()
  const updateTabMutation = useUpdateTabMutation()

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      slug: Yup.string()
        .required('Required')
        .max(63, 'Must be 63 characters or less')
        .matches(/^[\da-z]+(?:-[\da-z]+)*$/i, 'Invalid format'),
    }),
    initialValues: {
      slug: collection.slug,
    },
    onSubmit: async (values) => {
      try {
        if (collection.slug !== values.slug) {
          await updateTabMutation.mutateAsync({
            pathParams: {
              tabId: collection.id,
            },
            body: {slug: values.slug},
          })
        }
        ownRef.current?.hide()
      } catch (err: any) {
        if (err.response?.data?.errors?.[0]?.error === 'slug_taken') {
          growlActions.show('error', {
            title: 'This link is already in use',
            body: 'Please try something else.',
          })
        } else {
          growlActions.show('error', {
            title: 'Something went wrong',
            body: `Error: ${err.message}`,
          })
        }
      }
    },
  })

  return (
    <WebUI.Prompt ref={ref} aria-label="Edit collection link" {...restProps}>
      {(dialog) => (
        <form onSubmit={formik.handleSubmit}>
          <WebUI.VStack className="gap-3">
            <WebUI.PromptHeader
              className="[&_>_.PromptHeader-heading]:text-ds-lg"
              heading="Edit Link:"
            />
            <WebUI.VStack className="gap-4">
              <WebUI.HStack className="justify-center gap-2">
                <WebUI.VStack
                  className={
                    'justify-center rounded bg-teal-80 px-4 text-center text-ds-sm text-gray600'
                  }
                >
                  https://
                </WebUI.VStack>
                <WebUI.FormField className="grow" error={formik.errors.slug}>
                  <WebUI.Input
                    className="rounded-none text-center"
                    name="slug"
                    size="compact"
                    value={formik.values.slug}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
                <WebUI.VStack className="justify-center rounded bg-teal-80 px-4 text-center text-ds-sm text-gray600">
                  .cheddarup.com
                </WebUI.VStack>
              </WebUI.HStack>
              <div className="text-ds-sm">
                <span className="font-bold text-orange-500">NOTE:</span> Editing
                this URL will deactivate any QR codes you have shared for this
                collection.
              </div>
              <WebUI.HStack className="gap-3">
                <WebUI.Button type="submit">Save</WebUI.Button>
                <WebUI.Button
                  type="button"
                  variant="secondary"
                  onClick={() => dialog.hide()}
                >
                  Cancel
                </WebUI.Button>
              </WebUI.HStack>
            </WebUI.VStack>
          </WebUI.VStack>
        </form>
      )}
    </WebUI.Prompt>
  )
})

// MARK: – ShareByEmailPanel

export interface ShareByEmailPanelProps
  extends WebUI.PanelProps,
    React.ComponentPropsWithoutRef<'div'> {
  collection?: Api.Tab
}

export const ShareByEmailPanel = ({
  className,
  collection,
  ...restProps
}: ShareByEmailPanelProps) => {
  const [managerRole] = useManagerRole()
  return (
    <NextUI.Panel
      className={WebUI.cn('gap-5 px-8 py-7 sm:rounded-default', className)}
      {...restProps}
    >
      <SharePanelHeading iconName="envelope">Share by email</SharePanelHeading>
      <NextUI.Text className="grow">
        Send invitations and track payments and responses.
      </NextUI.Text>
      <LinkButton
        variant="default"
        disabled={
          collection?.status === 'draft' ||
          (!!managerRole &&
            (managerRole.permissions?.role === 'viewer' ||
              !managerRole.permissions.address_book_and_message_center))
        }
        to="message-center?messageType=invite"
      >
        Send a Custom Invitation
      </LinkButton>
    </NextUI.Panel>
  )
}

// MARK: – ShareByQRCodePanel

export interface ShareByQRCodePanelProps
  extends WebUI.PanelProps,
    React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const ShareByQRCodePanel = ({
  collection,
  className,
  ...restProps
}: ShareByQRCodePanelProps) => (
  <NextUI.Panel
    className={WebUI.cn(
      'flex flex-col gap-5 px-8 py-7 sm:rounded-default',
      className,
    )}
    {...restProps}
  >
    <SharePanelHeading iconName="qr-code">
      Share with a QR Code
    </SharePanelHeading>
    <NextUI.Text className="grow">
      Download a QR code that links to your{' '}
      {collection.options.p2pFundraising ? 'fundraising page' : 'collection'}.{' '}
      <NextUI.NextAnchor
        href={config.links.shareCollection}
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </NextUI.NextAnchor>
      .
    </NextUI.Text>
    <ShareQRCodeLinkModal
      link={config.helpers.shareUrl(collection.slug)}
      text={
        <>
          Share this QR code that will send people to your Collection Page URL.
          Download it or take a screenshot, then add it to any of your marketing
          materials.{' '}
          <NextUI.NextAnchor
            href={config.links.shareCollection}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </NextUI.NextAnchor>
          .
        </>
      }
      disclosure={
        <WebUI.DialogDisclosure
          disabled={collection?.status === 'draft'}
          onClick={() =>
            shareEventTracking(collection, 'qr', 'collection link')
          }
        >
          Create a QR Code
        </WebUI.DialogDisclosure>
      }
    />
  </NextUI.Panel>
)

// MARK: – ShareByHTMLButtonPanel

export interface ShareByHTMLButtonPanelProps
  extends WebUI.PanelProps,
    React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const ShareByHTMLButtonPanel = ({
  collection,
  className,
  ...restProps
}: ShareByHTMLButtonPanelProps) => {
  const growlActions = WebUI.useGrowlActions()

  const websiteButton = useMemo(() => {
    const url =
      import.meta.env.PUBLIC_URL ||
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }`

    return (
      <a
        href={`${url}/c/${collection.slug}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          alt="Pay with Cheddar Up"
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '172px',
          }}
          width="auto"
          height="auto"
          src={
            isUrl(cheddarUpPayIconPath)
              ? cheddarUpPayIconPath
              : `${url}${cheddarUpPayIconPath}`
          }
        />
      </a>
    )
  }, [collection.slug])

  const code = useMemo(
    () => ReactDOM.renderToString(websiteButton),
    [websiteButton],
  )

  return (
    <NextUI.Panel
      className={WebUI.cn(
        'flex flex-col gap-5 px-8 py-7 sm:rounded-default',
        className,
      )}
      {...restProps}
    >
      <SharePanelHeading iconName="code">Share on website</SharePanelHeading>
      <NextUI.Text className="grow">
        Add a button to your website that links to your collection.
      </NextUI.Text>
      <WebUI.Prompt
        aria-label="Embed cheddar up pay button"
        disclosure={
          <WebUI.DialogDisclosure
            disabled={collection.status === 'draft'}
            onClick={() =>
              shareEventTracking(collection, 'embed', 'collection link')
            }
          >
            Add a Website Button
          </WebUI.DialogDisclosure>
        }
      >
        <div className="flex flex-col gap-4">
          <WebUI.PromptHeader
            className={
              '[&_>_.PromptHeader-heading]:text-ds-md [&_>_.PromptHeader-heading]:text-gray800 [&_>_.PromptHeader-subheading]:mt-4 [&_>_.PromptHeader-subheading]:font-light [&_>_.PromptHeader-subheading]:text-ds-sm'
            }
            heading="Copy and paste this code"
            subheading="Because each webpage is different, you may need to work with your site’s administrator or developer to embed the HTML button code onto your blog or website."
          />
          <div className="flex flex-col gap-2 py-2">
            <div className="font-medium text-ds-sm">BUTTON PREVIEW</div>
            {websiteButton}
          </div>
          <WebUI.Textarea
            readOnly
            className="font-light text-ds-sm"
            value={code}
            rows={5}
          />
          <div className="flex">
            <WebUI.Button
              onClick={() => {
                WebUI.copyToClipboard(code)
                growlActions.show('success', {
                  title: 'Success!',
                  body: 'Code copied',
                })
              }}
            >
              Copy
            </WebUI.Button>
          </div>
        </div>
      </WebUI.Prompt>
    </NextUI.Panel>
  )
}
