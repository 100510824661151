import React from 'react'

import {CurrencyInput, CurrencyInputProps} from './CurrencyInput'
import {cn} from '../../utils'

export interface AmountInputProps extends CurrencyInputProps {}

export const AmountInput = React.forwardRef<HTMLInputElement, AmountInputProps>(
  ({className, ...restProps}, forwardedRef) => (
    <CurrencyInput
      ref={forwardedRef}
      className={cn('slashed-zero', className)}
      prefix="$"
      step={1}
      maxLength={15}
      decimalSeparator="."
      groupSeparator=","
      allowNegativeValue={false}
      decimalsLimit={2}
      {...restProps}
    />
  ),
)
