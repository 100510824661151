import {useState} from 'react'
import {
  FormikConfig as FormikConfigPrimitive,
  FormikValues,
  useFormik as useFormikPrimitive,
} from 'formik'
import {
  getDefaultsForSchema,
  mergeDeep,
  omitValuesDeep,
  z,
} from '@cheddarup/util'
import {toFormikValidationSchema} from 'zod-formik-adapter'
import {useUpdateEffect} from './useUpdateEffect'
import {useLiveRef} from './useLiveRef'

export interface FormikConfig<T extends FormikValues>
  extends FormikConfigPrimitive<T> {
  reinitializeDeps?: React.DependencyList
}

export function useFormik<T extends FormikValues>({
  validateOnBlur,
  validateOnChange,
  initialValues,
  reinitializeDeps,
  ...config
}: FormikConfig<T>) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const formik = useFormikPrimitive<T>({
    validateOnBlur: validateOnBlur ?? isSubmitted,
    validateOnChange: validateOnChange ?? isSubmitted,
    initialValues,
    ...config,
  })

  const _isSubmitted = formik.submitCount > 0
  if (_isSubmitted !== isSubmitted) {
    setIsSubmitted(_isSubmitted)
  }

  const initialValuesRef = useLiveRef(initialValues)
  useUpdateEffect(() => {
    formik.resetForm({values: initialValuesRef.current})
  }, [...(reinitializeDeps ?? []), formik.resetForm])

  return formik
}

// MARK: – useZodFormik

export interface ZodFormikConfig<T extends z.ZodTypeAny>
  extends Omit<FormikConfig<z.infer<T>>, 'validationSchema' | 'initialValues'> {
  schema: T
  initialValues?: z.input<T>
}

export function useZodFormik<T extends z.ZodTypeAny>({
  reinitializeDeps,
  schema,
  initialValues,
  ...config
}: ZodFormikConfig<T>) {
  const schemaDefaults = getDefaultsForSchema(schema)
  const isInitialValuesInitialized = initialValues !== undefined

  return useFormik<z.infer<T>>({
    reinitializeDeps: reinitializeDeps
      ? [isInitialValuesInitialized, ...reinitializeDeps]
      : [isInitialValuesInitialized],
    validationSchema: toFormikValidationSchema(schema),
    initialValues: initialValues
      ? mergeDeep(schemaDefaults, omitValuesDeep(initialValues, [undefined]))
      : schemaDefaults,
    ...config,
  })
}
