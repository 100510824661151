import * as Ariakit from '@ariakit/react'
import React from 'react'

import {PhosphorIcon} from '../../icons'
import {cn} from '../../utils'
import {NextButton, NextButtonProps} from './Button'
import {useControlledState} from '@cheddarup/react-util'

export interface PaginationProps extends Ariakit.RoleProps<'nav'> {
  pagesCount: number
  dotsJump?: number
  defaultPage?: number
  page?: number
  onPageChange?: (page: number) => void
}

export const Pagination = React.forwardRef<HTMLElement, PaginationProps>(
  (
    {
      pagesCount,
      dotsJump = 5,
      defaultPage,
      page,
      onPageChange,
      className,
      style,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [selectedPage, setSelectedPage] = useControlledState(
      page,
      defaultPage ?? 1,
      onPageChange,
    )

    const visiblePagesRange = getVisiblePaginationRange({
      siblingsCount: 1,
      boundariesCount: 1,
      pagesCount,
      selectedPage,
    })

    return (
      <Ariakit.Role.nav
        role="navigation"
        aria-label="pagination"
        ref={forwardedRef}
        className={cn('flex h-10 flex-row gap-2 text-center', className)}
        {...restProps}
      >
        <ul className="contents">
          <PaginationButton
            aria-label="Go to previous page"
            render={<li />}
            onClick={() => setSelectedPage(Math.max(selectedPage - 1, 1))}
          >
            <PhosphorIcon icon="caret-left" />
            Prev
          </PaginationButton>
          {visiblePagesRange.map((p, idx) => {
            const isSelected = p === selectedPage
            const isBefore = idx < visiblePagesRange.indexOf(selectedPage)

            return (
              <PaginationButton
                key={p === 'dots' ? `${p}-${isBefore}` : idx}
                aria-current={isSelected}
                data-active-item={isSelected || undefined}
                aria-label={`Go to page ${p}`}
                className={cn(
                  'aspect-square',
                  'data-[active-item]:bg-teal-100',
                )}
                render={<li />}
                onClick={() => {
                  if (p === 'dots') {
                    setSelectedPage(
                      isBefore
                        ? Math.max(selectedPage - dotsJump, 1)
                        : Math.min(selectedPage + dotsJump, pagesCount),
                    )
                  } else {
                    setSelectedPage(p)
                  }
                }}
              >
                {p === 'dots' ? (
                  <>
                    <PhosphorIcon
                      className="-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 opacity-100 transition-opacity group-hover/button:invisible group-hover/button:opacity-0"
                      icon="dots-three"
                    />
                    <PhosphorIcon
                      className="-translate-x-1/2 -translate-y-1/2 invisible absolute top-1/2 left-1/2 opacity-0 transition-opacity group-hover/button:visible group-hover/button:opacity-100"
                      icon="caret-double-right"
                    />
                  </>
                ) : (
                  p
                )}
              </PaginationButton>
            )
          })}
          <PaginationButton
            aria-label="Go to next page"
            render={<li />}
            onClick={() =>
              setSelectedPage(Math.min(selectedPage + 1, pagesCount))
            }
          >
            Next
            <PhosphorIcon icon="caret-right" />
          </PaginationButton>
        </ul>
      </Ariakit.Role.nav>
    )
  },
)

// MARK: – PaginationButton

interface PaginationButtonProps extends NextButtonProps {}

const PaginationButton = React.forwardRef<
  HTMLButtonElement,
  PaginationButtonProps
>(({className, ...restProps}, forwardedRef) => (
  <NextButton
    ref={forwardedRef}
    className={cn(
      'gap-1 rounded-md bg-trueWhite font-normal text-ds-base text-grey-800 transition',
      'hover:bg-grey-100',
      'data-[focus-visible]:ring-3 data-[focus-visible]:ring-teal-100',
      'aria-disabled:bg-depr-grey-50 aria-disabled:text-grey-400',
      className,
    )}
    variant="headless"
    {...restProps}
  />
))

// MARK: – Helpers

// Based on https://github.com/nextui-org/nextui/blob/canary/packages/hooks/use-pagination/src/index.ts#L106
function getVisiblePaginationRange({
  pagesCount,
  siblingsCount,
  boundariesCount,
  selectedPage,
}: {
  pagesCount: number
  siblingsCount: number
  boundariesCount: number
  selectedPage: number
}) {
  const maxVisiblePagesCount = siblingsCount * 2 + 3 + boundariesCount * 2

  if (maxVisiblePagesCount >= pagesCount) {
    return range(1, pagesCount)
  }

  const dots = 'dots' as const

  const leftSiblingIndex = Math.max(
    selectedPage - siblingsCount,
    boundariesCount,
  )
  const rightSiblingIndex = Math.min(
    selectedPage + siblingsCount,
    pagesCount - boundariesCount,
  )

  /*
   * We do not want to show dots if there is only one position left
   * after/before the left/right page count as that would lead to a change if our Pagination
   * component size which we do not want
   */
  const shouldShowLeftDots = leftSiblingIndex > boundariesCount + 2
  const shouldShowRightDots =
    rightSiblingIndex < pagesCount - (boundariesCount + 1)

  if (!shouldShowLeftDots && shouldShowRightDots) {
    const leftItemCount = siblingsCount * 2 + boundariesCount + 2

    return [
      ...range(1, leftItemCount),
      dots,
      ...range(pagesCount - (boundariesCount - 1), pagesCount),
    ]
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    const rightItemCount = boundariesCount + 1 + 2 * siblingsCount

    return [
      ...range(1, boundariesCount),
      dots,
      ...range(pagesCount - rightItemCount, pagesCount),
    ]
  }

  return [
    ...range(1, boundariesCount),
    dots,
    ...range(leftSiblingIndex, rightSiblingIndex),
    dots,
    ...range(pagesCount - boundariesCount + 1, pagesCount),
  ]
}

/**
 * Returns an array of numbers, starting at `start` and ending at `end`.
 * @param start number
 * @param end number
 * @returns number[]
 */
function range(start: number, end: number) {
  const length = end - start + 1

  return Array.from({length}, (_, index) => index + start)
}
