import {
  DelimitedNumericArrayParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface CategoryFilterDropdownProps {
  categories: Api.Category[]
}

export const CategoryFilterDropdown: React.FC<CategoryFilterDropdownProps> = ({
  categories,
}) => {
  const [, setCategoryPath] = useQueryParam(
    'categoryPath',
    withDefault(DelimitedNumericArrayParam, []),
  )
  const [hoveredCategoryId, setHoveredCategoryId] = useState<number | null>(
    null,
  )
  return (
    <WebUI.Menu aria-label="Item categories">
      <WebUI.MenuButton className="font-semibold" variant="outlined">
        Shop All
      </WebUI.MenuButton>

      <WebUI.MenuList>
        <WebUI.HStack>
          <WebUI.VStack>
            <WebUI.MenuItem
              onMouseEnter={() => setHoveredCategoryId(null)}
              onClick={() => setCategoryPath([])}
            >
              Shop All
            </WebUI.MenuItem>
            {categories?.map((category) => (
              <WebUI.MenuItem
                key={category.id}
                onMouseEnter={() => setHoveredCategoryId(category.id)}
                onClick={() => setCategoryPath([category.id])}
              >
                {category.name}
              </WebUI.MenuItem>
            ))}
          </WebUI.VStack>
          <WebUI.VStack className="bg-depr-grey-200">
            {hoveredCategoryId !== null &&
              categories
                ?.find(({id}) => id === hoveredCategoryId)
                ?.options.subcategories?.map((subcategory) => (
                  <WebUI.MenuItem
                    key={subcategory.uuid}
                    onClick={() =>
                      setCategoryPath([
                        hoveredCategoryId,
                        subcategory.uuid as any,
                      ])
                    }
                  >
                    {subcategory.name}
                  </WebUI.MenuItem>
                ))}
          </WebUI.VStack>
        </WebUI.HStack>
      </WebUI.MenuList>
    </WebUI.Menu>
  )
}
