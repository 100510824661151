import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import React from 'react'
import {
  UploadPinturaImageForm,
  UploadPinturaImageFormProps,
} from './UploadPinturaImageForm'
import {
  AlbumImagePickerForm,
  usePartnerAlbumsQuery,
} from './AlbumImagePickerForm'

export interface HeaderImagePickerModalProps
  extends NextUI.DialogProps,
    Pick<
      UploadPinturaImageFormProps,
      | 'placeholderSrc'
      | 'initialImage'
      | 'imageType'
      | 'imageCropAspectRatio'
      | 'imageCropLimitToImage'
    > {
  heading?: React.ReactNode
  pinturaUtils?: UploadPinturaImageFormProps['utils']
  parentPath?: UploadPinturaImageFormProps['parentPath']
  onImageSubmit?: UploadPinturaImageFormProps['onSubmit']
}

export const HeaderImagePickerModal = React.forwardRef<
  NextUI.DialogInstance,
  HeaderImagePickerModalProps
>(
  (
    {
      heading = 'Add Image',
      placeholderSrc,
      initialImage,
      imageCropAspectRatio,
      imageCropLimitToImage,
      imageType = 'header',
      parentPath = 'user',
      pinturaUtils,
      onImageSubmit,
      ...restProps
    },
    forwardedRef,
  ) => {
    const media = WebUI.useMedia()
    const albumsQuery = usePartnerAlbumsQuery()

    return (
      <NextUI.Dialog ref={forwardedRef} {...restProps}>
        {(dialog) => (
          <NextUI.ModalContent
            aria-label="Header image picker"
            variant="overlay"
          >
            <WebUI.ModalHeader>
              <NextUI.DialogHeading>{heading}</NextUI.DialogHeading>
            </WebUI.ModalHeader>

            <WebUI.Tabs
              className="min-h-0 grow flex-col divide-y"
              orientation={media.lg ? 'vertical' : 'horizontal'}
            >
              <div className="flex min-h-0 grow flex-col divide-y lg:flex-row lg:divide-x lg:divide-y-0">
                <WebUI.TabList
                  aria-label="Navigation"
                  className="!border-r-0 !border-b-0 min-w-[256px] flex-0 overflow-x-auto lg:overflow-hidden"
                >
                  <WebUI.Tab id="upload-image">Upload Image</WebUI.Tab>
                  {albumsQuery.data?.map((p) => (
                    <WebUI.Tab key={p.key} id={p.key}>
                      {p.name}
                    </WebUI.Tab>
                  ))}
                </WebUI.TabList>

                <WebUI.TabPanel className="min-h-0 grow" tabId="upload-image">
                  <UploadPinturaImageForm
                    className="h-full"
                    imageType={imageType}
                    imageCropAspectRatio={imageCropAspectRatio}
                    imageCropLimitToImage={imageCropLimitToImage}
                    parentPath={parentPath}
                    utils={pinturaUtils}
                    placeholderSrc={placeholderSrc}
                    initialImage={initialImage}
                    onSubmit={(newImage) => {
                      onImageSubmit?.(newImage)
                      dialog.hide()
                    }}
                  />
                </WebUI.TabPanel>

                {albumsQuery.data?.map((p) => (
                  <WebUI.TabPanel
                    key={p.key}
                    className="min-h-0 grow"
                    tabId={p.key}
                  >
                    <AlbumImagePickerForm
                      className="h-full"
                      images={p.images}
                      onSubmit={(selectedImageId) => {
                        const selectedImage = p.images.find(
                          (i) => i.id === selectedImageId,
                        )
                        if (selectedImage) {
                          onImageSubmit?.(selectedImage)
                          dialog.hide()
                        }
                      }}
                    />
                  </WebUI.TabPanel>
                ))}
              </div>
            </WebUI.Tabs>

            <NextUI.ModalCloseButton />
          </NextUI.ModalContent>
        )}
      </NextUI.Dialog>
    )
  },
)
