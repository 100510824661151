import * as NextUI from '@cheddarup/web-ui/next'
import * as WebUI from '@cheddarup/web-ui'
import FundraiserAddParticipantImage from 'src/images/add-participant.png'
import {useCurrentFundraiserId, useCurrentFundraiserQuery} from '../hooks'
import {api} from '@cheddarup/api-client'
import ParticipantsTable from './ParticipantsTable'
import {CopyCollectionShareLink} from 'src/views/collection/share/components/InviteOthersPanels'
import React from 'react'
import {AddParticipantsModal} from './modals'

export const ParticipantsTab = () => {
  const fundraiserId = Number(useCurrentFundraiserId())
  const participantsExistQuery = api.participants.list.useQuery(
    {
      pathParams: {fundraiserId},
    },
    {select: (res) => res.data.length > 0},
  )

  return participantsExistQuery.isLoading || participantsExistQuery.data ? (
    <ParticipantsTable fundraiserId={fundraiserId} />
  ) : (
    <div className="flex flex-col flex-wrap gap-6 xl:flex-row">
      <AddParticipantsPanel className="flex-1" />
      <SelfRegisterPanel className="flex-1" />
    </div>
  )
}

// MARK: - AddParticipantsPanel

const AddParticipantsPanel = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <NextUI.Panel
      className={WebUI.cn(
        'grow flex-row gap-6 px-8 py-10 sm:rounded',
        className,
      )}
      {...restProps}
    >
      <WebUI.Image
        className="shrink-0"
        src={FundraiserAddParticipantImage}
        alt=""
        width={150}
        height={186}
      />
      <div className="flex flex-col items-start gap-4 text-grey-800">
        <NextUI.PanelLabel className="font-extrabold">
          Add participants to your fundraiser
        </NextUI.PanelLabel>
        <NextUI.Text className="text-ds-base">
          Start accepting donations as soon as you share your fundraiser. Add
          participants to the table below and then send them an invitation with
          their unique page link to share with donors.
        </NextUI.Text>
        <AddParticipantsModal
          disclosure={
            <NextUI.DialogDisclosure render={<NextUI.NextButton size="md" />}>
              <WebUI.PhosphorIcon className="text-ds-lg" icon="user-plus" />
              Add Participants
            </NextUI.DialogDisclosure>
          }
        />
      </div>
    </NextUI.Panel>
  )
}

// MARK: - SelfRegisterPanel

const SelfRegisterPanel = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const [{data: fundraiser}] = useCurrentFundraiserQuery()

  return (
    <NextUI.Panel
      className={WebUI.cn('items-start px-8 py-10 sm:rounded', className)}
      {...restProps}
    >
      <NextUI.PanelLabel className="font-extrabold">
        Let participants register themselves
      </NextUI.PanelLabel>
      <NextUI.Text className="text-ds-base">
        Let participants to register themselves by sharing a registration link
        in any of your communications. As participants register, they will be
        added to the participants table.
      </NextUI.Text>
      {fundraiser && <CopyCollectionShareLink collection={fundraiser} />}
    </NextUI.Panel>
  )
}
