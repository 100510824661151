import {Image} from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import WomanInBluewithBadgeCircleSmall from 'src/images/Woman-in-Blue-with-Badge_Circle_Small.png'

export const EinVerifiedCard = () => (
  <div className="flex max-w-3xl flex-col items-start gap-4 rounded-extended bg-teal-200 px-8 py-6 sm:flex-row">
    <Image
      className="shrink-0 rounded-full"
      src={WomanInBluewithBadgeCircleSmall}
      alt=""
    />
    <div className="flex flex-col items-start gap-2">
      <NextUI.Text className="font-bold">
        Your Account has been verified as a 501(c)(3)!
      </NextUI.Text>
      <NextUI.Text className="font-light text-ds-sm">
        Collect more by showing this item as tax-deductible. This makes it
        easier for your donors with instant tax-deductible receipts delivered to
        their inbox.
      </NextUI.Text>
      <NextUI.NextButton size="xs">Upgrade to Team</NextUI.NextButton>
    </div>
  </div>
)
