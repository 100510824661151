import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import {ParammedTabs} from 'src/components/ParammedTabs'
import {NextAnchor, NextAnchorButton, Text} from '@cheddarup/web-ui/next'
import {
  DashboardPageContainer,
  DashboardPageHeader,
  DashboardPageHeading,
  DashboardPageContent,
  DashboardPanel,
  DashboardSettingContent,
  DashboardSettingPanel,
  DashboardSettingPanelLabel,
  DashboardPageFooter,
} from 'src/components/DashboardPageLayout'
import {DashboardContentLayout} from 'src/components/Layout'
import {
  SettingDisclosure,
  SettingDisclosureContent,
  SettingDisclosureSwitch,
} from 'src/components/SettingDisclosure'
import {useFormik} from '@cheddarup/react-util'
import {api, useUpdateTabMutation} from '@cheddarup/api-client'
import {useNavigate} from 'react-router-dom'
import {useManagerRole} from 'src/components/ManageRoleProvider'
import {useCurrentFundraiserQuery} from '../hooks'

const MAILING_DETAILS_MAX_LEN = 1000
const STATEMENT_DESCRIPTOR_MAX_LEN = 10
const CUSTOM_PAYER_MESSAGE_MAX_LEN = 220

interface FundraiserSettingsFormValues {
  allow_echeck_payments: boolean
  allow_offline_payments: boolean
  processing_preference?: 'member' | 'user'
  offline_payment_instructions: string
  options: {
    customCardDescriptor: {
      enabled: boolean
      value: string
    }
    payerCanCoverFees: boolean
    payerCanCoverFeesCustomMessage: string
    coverOnlyEcheckFees: boolean
  }
}

const FundraiserSettingsPage = () => {
  const navigate = useNavigate()
  const [managerRole] = useManagerRole()

  const updateTabMutation = useUpdateTabMutation()

  const {data: acceptedManagers} = api.managers.list.useQuery(undefined, {
    select: (managers) => managers.filter((m) => m.accepted_at) ?? [],
  })
  const {data: userCurrency = 'usd'} = api.auth.session.useQuery(undefined, {
    enabled: !managerRole,
    select: (session) => session.user.currency,
  })
  const [{data: fundraiser}] = useCurrentFundraiserQuery()

  const formik = useFormik<FundraiserSettingsFormValues>({
    enableReinitialize: true,
    initialValues: {
      allow_echeck_payments: !!fundraiser?.allow_echeck_payments,
      allow_offline_payments: !!fundraiser?.allow_offline_payments,
      processing_preference: fundraiser?.processing_preference,
      offline_payment_instructions:
        fundraiser?.offline_payment_instructions ?? '',
      options: {
        customCardDescriptor: {
          enabled: !!fundraiser?.options.customCardDescriptor?.enabled,
          value: fundraiser?.options.customCardDescriptor?.value ?? '',
        },
        payerCanCoverFees: !!fundraiser?.options.payerCanCoverFees,
        payerCanCoverFeesCustomMessage:
          fundraiser?.options.payerCanCoverFeesCustomMessage ??
          'This will add a small amount to your total to cover convenience fees for the organizer.',
        coverOnlyEcheckFees: !!fundraiser?.options.coverOnlyEcheckFees,
      },
    },
    onSubmit: async (values) => {
      if (formik.dirty && fundraiser) {
        await updateTabMutation.mutateAsync({
          pathParams: {
            tabId: fundraiser.id,
          },
          body: {
            ...values,
            options: {
              ...values.options,
              customCardDescriptor: {
                enabled:
                  values.options.customCardDescriptor &&
                  values.options.customCardDescriptor.value.length > 0,
                value: values.options.customCardDescriptor.value,
              },
            },
          },
        })
      }

      navigate('../../share')
    },
  })

  return (
    <form
      className="contents"
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
    >
      <DashboardPageContainer>
        <DashboardPageContent>
          <DashboardPageHeader>
            <DashboardPageHeading>Settings</DashboardPageHeading>
            <NextUI.Text>
              Customize the checkout settings for your main fundraising page and
              all participant fundraising pages.
            </NextUI.Text>
          </DashboardPageHeader>
          <DashboardContentLayout>
            <ParammedTabs
              defaultPaneKey="checkout"
              orientation="horizontal"
              variant="underlined"
            >
              <WebUI.TabList className="[&_.TabList-underline]:bg-orange-500 [&_>_.Tab]:text-ds-base">
                <WebUI.Tab id="checkout">Checkout</WebUI.Tab>
                <WebUI.Tab id="managers">Managers</WebUI.Tab>
              </WebUI.TabList>

              <div className="pt-4 [&_>_.TabPanel]:flex [&_>_.TabPanel]:flex-col [&_>_.TabPanel]:gap-6">
                <WebUI.TabPanel id="checkout">
                  <DashboardPanel>
                    <NextUI.PanelLabel>Payment Methods</NextUI.PanelLabel>
                    <DashboardSettingContent>
                      <DashboardSettingPanel>
                        <DashboardSettingPanelLabel>
                          Credit Cards
                        </DashboardSettingPanelLabel>
                        <Text className="text-ds-sm">
                          Payment by credit card is always offered by default
                          and cannot be disabled.
                        </Text>
                      </DashboardSettingPanel>
                      <SettingDisclosure
                        open={formik.values.allow_echeck_payments}
                        setOpen={(enabled) =>
                          formik.setFieldValue('allow_echeck_payments', enabled)
                        }
                      >
                        <SettingDisclosureSwitch>
                          Allow payments by echeck
                        </SettingDisclosureSwitch>
                        <SettingDisclosureContent>
                          EChecks (electronic checks) take up to 5 business days
                          to process.
                        </SettingDisclosureContent>
                      </SettingDisclosure>
                      <SettingDisclosure
                        open={formik.values.allow_offline_payments}
                        setOpen={(enabled) =>
                          formik.setFieldValue(
                            'allow_offline_payments',
                            enabled,
                          )
                        }
                      >
                        <SettingDisclosureSwitch>
                          Allow payments by cash or check
                        </SettingDisclosureSwitch>
                        <SettingDisclosureContent>
                          <Text className="text-orange-500">
                            Note: This setting is not available on carts with
                            recurring items.
                          </Text>
                          <Text>
                            <span className="font-bold">Important note:</span>{' '}
                            If your collection contains tickets, your customers
                            will receive their QR code ticket(s) immediately
                            after checkout, likely prior to them submitting
                            their cash or check payment.
                          </Text>
                          <WebUI.FormField
                            className="[&_>_.FormField-caption]:block [&_>_.FormField-caption]:text-ds-sm"
                            caption={`${formik.values.offline_payment_instructions.length} / ${MAILING_DETAILS_MAX_LEN}`}
                          >
                            <WebUI.Textarea
                              name="offline_payment_instructions"
                              maxLength={MAILING_DETAILS_MAX_LEN}
                              placeholder="Optional: Provide mailing or delivery details for those that choose to pay by cash or check."
                              rows={6}
                              value={formik.values.offline_payment_instructions}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </WebUI.FormField>
                        </SettingDisclosureContent>
                      </SettingDisclosure>
                    </DashboardSettingContent>
                  </DashboardPanel>

                  <DashboardPanel>
                    <NextUI.PanelLabel>
                      Credit Card Statements
                    </NextUI.PanelLabel>
                    <SettingDisclosure
                      open={formik.values.options.customCardDescriptor.enabled}
                      setOpen={(enabled) =>
                        formik.setFieldValue(
                          'options.customCardDescriptor.enabled',
                          enabled,
                        )
                      }
                    >
                      <SettingDisclosureSwitch>
                        Customize what donors see on their credit card
                        statement:{' '}
                        <span className="text-orange-500">
                          {formik.values.options.customCardDescriptor.value}
                        </span>
                      </SettingDisclosureSwitch>
                      <SettingDisclosureContent>
                        Choose something that your donors will recognize to
                        avoid disputes (numbers and special characters not
                        allowed).
                        <WebUI.FormField
                          className="max-w-36 [&_>_.FormField-caption]:block [&_>_.FormField-caption]:text-ds-sm"
                          caption={`${formik.values.options.customCardDescriptor.value.length} / ${STATEMENT_DESCRIPTOR_MAX_LEN}`}
                        >
                          <WebUI.Input
                            name="options.customCardDescriptor.value"
                            value={
                              formik.values.options.customCardDescriptor.value
                            }
                            maxLength={STATEMENT_DESCRIPTOR_MAX_LEN}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </WebUI.FormField>
                      </SettingDisclosureContent>
                    </SettingDisclosure>
                  </DashboardPanel>

                  <DashboardPanel>
                    <NextUI.PanelLabel>Fees</NextUI.PanelLabel>
                    <SettingDisclosure
                      open={formik.values.processing_preference === 'user'}
                      setOpen={(enabled) => {
                        formik.setFieldValue(
                          'processing_preference',
                          enabled ? 'user' : 'member',
                        )
                        if (!enabled) {
                          formik.values.options.payerCanCoverFees = false
                        }
                      }}
                    >
                      <SettingDisclosureSwitch>
                        Cover all or some fees for your donors
                      </SettingDisclosureSwitch>
                      <SettingDisclosureContent className="sm:mr-0">
                        <NextUI.Text className="sm:mr-10">
                          By default, the transactional convenience fee is
                          passed on to the payer but you can choose to absorb
                          the fees or give the payer the option to cover them. 
                        </NextUI.Text>
                        {userCurrency === 'usd' && fundraiser?.is_pro && (
                          <>
                            <NextUI.RadioGroup
                              name="coverOnlyEcheckFees"
                              variant="teal"
                              size="sm"
                              className="[&_>_label]:text-grey-600"
                              value={formik.values.options.coverOnlyEcheckFees.toString()}
                              setValue={(value) =>
                                formik.setFieldValue(
                                  'options.coverOnlyEcheckFees',
                                  value === 'true',
                                )
                              }
                            >
                              <NextUI.Radio value="false">
                                Cover all fees
                              </NextUI.Radio>
                              <NextUI.Radio value="true">
                                Cover only eCheck fees (if included as payment
                                method)
                              </NextUI.Radio>
                            </NextUI.RadioGroup>
                            <WebUI.Separator />
                          </>
                        )}
                        <SettingDisclosure
                          className="border-none sm:p-0"
                          open={formik.values.options.payerCanCoverFees}
                          setOpen={(enabled) =>
                            formik.setFieldValue(
                              'options.payerCanCoverFees',
                              enabled,
                            )
                          }
                        >
                          <SettingDisclosureSwitch className="text-ds-base">
                            Give donors the option to cover fees
                          </SettingDisclosureSwitch>
                          <SettingDisclosureContent>
                            <NextUI.Text>
                              Include a custom message for payers.
                            </NextUI.Text>
                            <WebUI.FormField
                              className="max-w-lg"
                              caption={`${
                                formik.values.options
                                  .payerCanCoverFeesCustomMessage.length
                              } / ${CUSTOM_PAYER_MESSAGE_MAX_LEN}`}
                            >
                              <WebUI.Textarea
                                name="options.payerCanCoverFeesCustomMessage"
                                placeholder="This will add a small amount to your total to cover convenience fees for the organizer."
                                rows={6}
                                value={
                                  formik.values.options
                                    .payerCanCoverFeesCustomMessage
                                }
                                maxLength={CUSTOM_PAYER_MESSAGE_MAX_LEN}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </WebUI.FormField>
                          </SettingDisclosureContent>
                        </SettingDisclosure>
                      </SettingDisclosureContent>
                    </SettingDisclosure>
                  </DashboardPanel>
                </WebUI.TabPanel>

                <WebUI.TabPanel id="managers">
                  <DashboardPanel>
                    <NextUI.PanelLabel>Managers</NextUI.PanelLabel>
                    <DashboardSettingContent render={<DashboardSettingPanel />}>
                      <Text className="font-normal">
                        Give others access to edit, view payments and receive
                        notifications.
                      </Text>
                      <Text className="text-ds-sm">
                        <NextAnchor
                          href="https://support.cheddarup.com/hc/en-us/articles/360035587291-Assign-manager-permissions"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn more about setting permissions
                        </NextAnchor>
                        <br />
                        <span className="font-bold">
                          You can add new managers and edit manager permissions
                          within the{' '}
                          <NextAnchor href="/managers" target="_blank">
                            Managers section.
                          </NextAnchor>
                        </span>
                      </Text>
                      <NextAnchorButton
                        className="self-start"
                        variant="gray"
                        href="/managers"
                        target="_blank"
                      >
                        <div className="flex items-center gap-2">
                          <span>Add Manager</span>
                          <WebUI.PhosphorIcon
                            className="text-ds-md"
                            icon="arrow-square-out"
                          />
                        </div>
                      </NextAnchorButton>
                    </DashboardSettingContent>
                  </DashboardPanel>

                  <DashboardPanel>
                    <NextUI.PanelLabel>Main Contact</NextUI.PanelLabel>
                    <DashboardSettingContent render={<DashboardSettingPanel />}>
                      <Text className="font-normal">
                        <span>Select main contact for payer inquires: </span>
                        <span className="text-orange-500">
                          {acceptedManagers?.find(
                            (m) => m.id === fundraiser?.contact_manager_id,
                          )?.name ?? fundraiser?.organizer.name}
                        </span>
                      </Text>
                      <Text className="text-ds-sm">
                        Select a manager to receive email inquiries from the
                        contact link on your fundraising page.
                      </Text>
                      <NextAnchorButton
                        className="self-start"
                        variant="gray"
                        target="_blank"
                        href="/managers"
                      >
                        <div className="flex items-center gap-2">
                          <span>Add Manager</span>
                          <WebUI.PhosphorIcon
                            className="text-ds-md"
                            icon="arrow-square-out"
                          />
                        </div>
                      </NextAnchorButton>
                    </DashboardSettingContent>
                  </DashboardPanel>
                </WebUI.TabPanel>
              </div>
            </ParammedTabs>
          </DashboardContentLayout>
        </DashboardPageContent>
      </DashboardPageContainer>

      <DashboardPageFooter>
        <NextUI.NextButton
          className="w-32"
          type="submit"
          size="md"
          variant="orange"
          loading={formik.isSubmitting}
        >
          Continue <WebUI.PhosphorIcon icon="caret-right" />
        </NextUI.NextButton>
      </DashboardPageFooter>
    </form>
  )
}

export default FundraiserSettingsPage
