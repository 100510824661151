// @ts-ignore

import {z} from '@cheddarup/util'

import {currencySchema, s3ImageSchema} from './common'
import {
  processingPreferenceTypeSchema,
  tabFaqSchema,
  tabInfoBlockSettingsSchema,
  tabSchema,
  tabShippingOptionsSchema,
  tabTypeSchema,
} from './tabs'
import {categorySchema} from './tab-categories'
import {
  fieldSetSchema,
  tabObjectFieldMetadataSchema,
  tabObjectFieldTypeSchema,
} from './fields'
import {signUpSpotSchema} from './tab-signup-spots'
import {signUpOptionsSchema} from './tab-signups'
import {
  itemTypeSchema,
  tabAmountTypeSchema,
  tabItemOptionsVariantsSchema,
  tabItemOptionsWaitlistSchema,
  tabItemQuantityDiscountSchema,
} from './tab-items'
import {recurringPaymentContractSchema} from './recurring-payment-contracts'
import {timeSlotSchema} from './time-slots'
import {headerImageSchema} from './header-images'
import {inventoryGroupItemSchema, inventoryGroupSchema} from './inventory-group'
import {userBrandingSchema} from './user-brandings'

export const internalMarketplaceSchema = z.object({
  enabled: z.boolean(),
  organizerNickname: z.string(),
})

export const publicTabOrganizerCobrandingSchema = z.object({
  enabled: z.boolean(),
})

export const publicTabOrganizerPartnerSchema = z.object({
  name: z.string(),
  internalMarketplace: internalMarketplaceSchema,
  cobranding: publicTabOrganizerCobrandingSchema.optional(),
  slug: z.string(),
  type: z.string(),
  logo: z.string(),
  partnerUrl: z.string(),
})

export const publicTabOrganizerSchema = z.object({
  id: z.any().nullish(),
  name: z.string().optional(),
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  displayName: z.string(),
  customerCategory: z.string(),
  email: z.string(),
  profile_pic: s3ImageSchema.nullable(),
  partner: publicTabOrganizerPartnerSchema.optional(),
  currency: currencySchema,
  branding: userBrandingSchema.nullable(),
  groupPage: z.object({
    published: z.boolean(),
    slug: z.string(),
  }),
})

export const collectionUnavailableSchema = z.enum([
  'closed',
  'requires_pro',
  'timing_out_of_range',
  'payments_disabled',
])

export const publicTabObjectFieldSchema = z.object({
  id: z.number(),
  name: z.string(),
  required: z.boolean(),
  position: z.number().nullish(),
  field_type: tabObjectFieldTypeSchema,
  values: z.string().nullish(),
  metadata: tabObjectFieldMetadataSchema,
})

export const publicTabFormSchema = z.object({
  id: z.number(),
  name: z.string(),
  required: z.boolean(),
  position: z.number(),
  description: z.any().nullish(),
  linked_item_id: z.number().nullable(),
  options: z.object({
    isWaiver: z.boolean().optional(),
    fieldSets: fieldSetSchema.array().optional(),
  }),
  detail: z
    .object({
      name: z.string(),
      linked_item_id: z.number(),
    })
    .optional(),
  fields: publicTabObjectFieldSchema.array(),
})

const timeSlotPublicParticipantSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  comment: z.string().optional(),
})

export const publicTimeSlotSchema = timeSlotSchema
  .pick({
    id: true,
    name: true,
    available_quantity: true,
    position: true,
    options: true,
  })
  .extend({
    public_participants: timeSlotPublicParticipantSchema.array(),
    required: z.boolean(),
  })

export const publicSignUpSpotSchema = signUpSpotSchema
  .pick({
    id: true,
    description: true,
    name: true,
    options: true,
    position: true,
  })
  .extend({
    required: z.boolean(),
    time_slots: publicTimeSlotSchema.array(),
  })

export const publicTabSignupSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullish(),
  detail: z
    .object({
      name: z.string(),
      linked_item_id: z.number(),
    })
    .optional(),
  required: z.boolean(),
  position: z.number(),
  options: signUpOptionsSchema,
  fields: publicTabObjectFieldSchema.array(),
  visible_spots: publicSignUpSpotSchema.array(),
})

export const publicTabItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  amount: z.number().nullable(),
  amount_type: tabAmountTypeSchema,
  allow_quantity: z.boolean().nullish(),
  required: z.boolean(),
  position: z.number(),
  parent_id: z.number().nullish(),
  variant_id: z.string().nullish(),
  description: z.string().nullable(),
  available_quantity: z.number().nullish(),
  total_collected: z.number(),
  options: z.object({
    isTaxDeductible: z.boolean().optional(),
    subcategoryId: z.string().nullish().optional(),
    makeAvailableQuantityPublic: z.boolean().nullish().optional(),
    makeTotalCollectedPublic: z.boolean().optional(),
    recurring: z
      .object({
        enabled: z.boolean(),
        options: recurringPaymentContractSchema.shape.options.optional(),
      })
      .optional(),
    variants: tabItemOptionsVariantsSchema.nullish().optional(),
    perPersonMaxQuantity: z
      .object({
        enabled: z.boolean(),
        value: z.number(),
      })
      .nullish()
      .optional(),
    fieldSets: fieldSetSchema.array().nullish().optional(),
    waitlist: tabItemOptionsWaitlistSchema.optional(),
    itemSubType: itemTypeSchema.optional(),
    time: z
      .object({
        startTime: z.string(),
        endTime: z.string(),
        timeZone: z.string(),
      })
      .optional(),
    location: z
      .object({
        address: z.string(),
        city: z.string(),
        state: z.string(),
        country: z.string(),
        zip: z.string(),
      })
      .optional(),
    donation: z
      .object({
        suggestedAmounts: z.object({
          enabled: z.boolean(),
          values: z.number().array(),
          minimumAmount: z.number().nullable(),
          buttonText: z.string(),
        }),
      })
      .optional(),
    fundraisingGoal: z
      .object({
        enabled: z.boolean(),
        value: z.number(),
      })
      .optional(),
    quantityDiscount: tabItemQuantityDiscountSchema.optional(),
  }),
  retailPrice: z.any().nullish(),
  fields: publicTabObjectFieldSchema.array(),
  images: s3ImageSchema.array(),
  category: z
    .object({
      id: z.number(),
    })
    .nullish(),
  inventory_items: inventoryGroupItemSchema
    .pick({
      available_quantity: true,
      variant_uuid: true,
    })
    .extend({
      inventory_groups: inventoryGroupSchema
        .pick({
          id: true,
          name: true,
          available_quantity: true,
        })
        .array(),
    })
    .array(),
})

export const publicTabCategory = categorySchema.pick({
  id: true,
  name: true,
  description: true,
  position: true,
  items: true,
  anchor: true,
  options: true,
})

export const publicTabPaymentGoalSchema = z.object({
  enabled: z.boolean(),
  value: z.number(),
  order: z.number().optional(),
})

export const publicTabSchema = z.object({
  id: z.number(),
  /**
   * @deprecated – Use `headerImages` and `featuredImage`
   */
  headerImage: headerImageSchema.nullable(),
  featuredImage: s3ImageSchema.nullable(),
  headerImages: s3ImageSchema.array().optional(),
  name: z.string(),
  slug: z.string(),
  description: z.string().nullable(),
  collectionCategory: z.string().nullable(),
  collectionSubcategory: z.string().array().nullish(),
  collectionPurpose: z.string(),
  organizer: publicTabOrganizerSchema.extend({
    is_team_complimentary: z.boolean(),
  }),
  allowPayments: z.boolean(),
  acceptsEcheck: z.boolean(),
  shippingOptions: tabShippingOptionsSchema.partial(),
  acceptsCash: z.boolean(),
  discountsEnabled: z.boolean(),
  taxesEnabled: z.boolean(),
  offlinePaymentInstructions: z.string().nullable(),
  allowIndexing: z.boolean().nullable(),
  hideSoldOutItems: z.boolean().nullable(),
  defaultToGalleryView: z.boolean().nullable(),
  displayOrder: z.string().array(),
  payerCanCoverFees: z.boolean(),
  paymentGoal: publicTabPaymentGoalSchema.nullable(),
  userManagesCollection: z.boolean(),
  logo: s3ImageSchema.nullish(),
  payers: z.string().array().nullish(),
  totalCollected: z.number().optional(),
  unavailable: collectionUnavailableSchema.nullable(),
  timing: z
    .object({
      opens: z.string().nullish(),
      closes: z.string().nullish(),
    })
    .nullish(),
  attachments: z.any().array().nullish(),
  categories: publicTabCategory.array(),
  forms: publicTabFormSchema.array(),
  signups: publicTabSignupSchema.array(),
  filters: z
    .object({
      categoryId: z.number().nullable(),
      subcategoryId: z.string().nullable(),
      filters: z.string().array(),
    })
    .array(),
  filterOrder: z
    .object({
      key: z.string(),
      order: z.string().array(),
    })
    .array(),
  items: publicTabItemSchema.array(),
  is_pro: z.boolean(),
  is_team: z.boolean(),
  imageCarouselIds: z.number().array().nullable(),
  requires_pro: z.boolean(),
  requires_team: z.boolean(),
  disablePointOfSaleSignatures: z.boolean().nullable(),
  statementDescriptor: z.string().nullish(),
  fields_required: z.string().array().nullish(),
  allowPreview: z.boolean(),
  requirePayment: z.boolean(),
  coverOnlyEcheckFees: z.boolean().nullish(),
  onlyAllowOneDiscountPerPurchase: z.boolean().nullable(),
  error: z.string().optional(),
  processing_preference: processingPreferenceTypeSchema,
  groupPage: z.boolean(),
  cZoala: z.number(),
  faqs: tabFaqSchema
    .pick({
      answer: true,
      position: true,
      question: true,
    })
    .array(),
  otherShoppers: z.any().nullable(),
  thankYouLink: z.string().nullable(),
  published_at: z.string().nullable(),
  discounts_enabled: z.boolean().nullish(),
  requireSignatureForPosCode: z.boolean().nullable(),
  payerCanCoverFeesCustomMessage: z.string().nullable(),
  customContributorName: z
    .object({
      enabled: z.boolean(),
      value: z.string(),
    })
    .or(z.string())
    .nullable(),
  type: tabTypeSchema,
  infoBlockSettings: tabInfoBlockSettingsSchema.nullable(),
  fee_transparency: z.boolean(),
  fee_structure: z.object({
    percentage: z.number(),
    fixedCents: z.number(),
  }),
})

export const groupTabSchema = tabSchema.extend({
  position: z.number(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type GroupTab = z.infer<typeof groupTabSchema>
    // @ts-ignore
    type PublicTab = z.infer<typeof publicTabSchema>
    // @ts-ignore
    type PublicTabItem = z.infer<typeof publicTabItemSchema>
    // @ts-ignore
    type PublicTabSignup = z.infer<typeof publicTabSignupSchema>
    // @ts-ignore
    type PublicSignUpSpot = z.infer<typeof publicSignUpSpotSchema>
    // @ts-ignore
    type PublicTimeSlot = z.infer<typeof publicTimeSlotSchema>
    // @ts-ignore
    type PublicTabForm = z.infer<typeof publicTabFormSchema>
    // @ts-ignore
    type PublicTabObjectField = z.infer<typeof publicTabObjectFieldSchema>
    // @ts-ignore
    type CollectionUnavailable = z.infer<typeof collectionUnavailableSchema>
    // @ts-ignore
    type PublicTabOrganizer = z.infer<typeof publicTabOrganizerSchema>
    // @ts-ignore
    type PublicTabOrganizerPartner = z.infer<
      typeof publicTabOrganizerPartnerSchema
    >
    // @ts-ignore
    type InternalMarketplace = z.infer<typeof internalMarketplaceSchema>
    // @ts-ignore
    type TimeSlotPublicParticipant = z.infer<
      typeof timeSlotPublicParticipantSchema
    >
    // @ts-ignore
    type PublicTabCategory = z.infer<typeof publicTabCategory>
  }
}
