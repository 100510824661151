import React, {useMemo, useRef} from 'react'
import * as NextUI from '@cheddarup/web-ui/next'
import * as WebUI from '@cheddarup/web-ui'
import * as Yup from 'yup'
import * as Util from '@cheddarup/util'
import {useSendParticipantInviteMutation} from '@cheddarup/api-client'
import {useFormik} from '@cheddarup/react-util'
import {ParticipantsAutosuggestCombobox} from '../ParticipantsAutoSuggestCombobox'

interface SendInvitesModalProps extends NextUI.DialogProps {
  fundraiserId: number
  participantIds: number[]
}

enum inviteTypes {
  all = 'all',
  specific = 'specific',
  new = 'new',
}

interface invitesFormValues {
  option: inviteTypes
  selectedParticipants: Api.FundraiserParticipant[]
}

export const SendInvitesModal = React.forwardRef<
  WebUI.DialogInstance,
  SendInvitesModalProps
>(({fundraiserId, participantIds, ...restProps}) => {
  const dialogRef = useRef<NextUI.DialogInstance | null>(null)
  const growlActions = WebUI.useGrowlActions()

  const sendInvitesToParticipantsMutation = useSendParticipantInviteMutation()

  const formik = useFormik<invitesFormValues>({
    enableReinitialize: true,
    initialValues: {
      option: inviteTypes.new,
      selectedParticipants: [],
    },
    validationSchema: Yup.object().shape({
      selectedParticipants: Yup.array()
        .when('option', ([option], schema) =>
          option === inviteTypes.specific
            ? schema.min(1, 'Please select participant(s)')
            : schema,
        )
        .nullable(),
    }),
    onSubmit: async (values) => {
      try {
        await sendInvitesToParticipantsMutation.mutateAsync({
          pathParams: {fundraiserId},
          body: {
            type: values.option,
            participant_ids:
              values.option === inviteTypes.specific
                ? values.selectedParticipants.map((p) => p.id)
                : undefined,
          },
        })
        growlActions.show('success', {
          title: 'Invites sent to participants',
          body: 'You sent participant invites successfully',
        })
        formik.resetForm()
        dialogRef.current?.hide()
      } catch (error) {
        growlActions.show('error', {
          title: 'Invites to participants failed',
          body: 'Some or all invite attempts failed. Please verify the information and try again.',
        })
      }
    },
  })

  const ParticipantRowWithHandler: WebUI.ListRowComponentType<Api.FundraiserParticipant> =
    useMemo(
      () =>
        ({data: p, className, ...rowProps}) => (
          <NextUI.Panel
            className={WebUI.cn(
              'grow flex-row items-center gap-2 py-2 pr-0 pl-3 sm:rounded-default',
              className,
            )}
            {...rowProps}
          >
            <WebUI.Avatar size={40} name={`${p.first_name} ${p.last_name}`} />
            <div className="flex grow flex-col gap-1">
              <WebUI.Ellipsis className="text-ds-base">
                {p.first_name} {p.last_name}
              </WebUI.Ellipsis>
              <NextUI.Text>{p.team?.name ?? p.email}</NextUI.Text>
            </div>
            <NextUI.NextButton
              onClick={() => {
                formik.setFieldValue(
                  'selectedParticipants',
                  formik.values.selectedParticipants.filter(
                    (participant) => participant.id !== p.id,
                  ),
                )
              }}
              variant="headless"
            >
              <WebUI.PhosphorIcon icon="x" className="text-ds-xl" />
            </NextUI.NextButton>
          </NextUI.Panel>
        ),
      [formik],
    )

  const selectedParticipantsCount = formik.values.selectedParticipants.length

  return (
    <NextUI.Dialog
      aria-label="Share Registration Link"
      ref={dialogRef}
      {...restProps}
    >
      <NextUI.ModalContent className="sm:max-w-[620px]">
        <NextUI.ModalCloseButton className="text-ds-xl" />
        <div className="flex flex-col">
          <div className="p-6">
            <NextUI.Text className="font-extrabold text-ds-lg text-grey-800">
              Send Invites to Participants
            </NextUI.Text>
          </div>
          <NextUI.Separator />
          <div className="flex flex-col items-start gap-4 p-6">
            <NextUI.Text className="text-ds-base text-grey-800">
              Once you send invites, selected participants will receive an email
              with links to manage their fundraising page.
            </NextUI.Text>
            <NextUI.Text className="text-ds-base text-grey-800">
              Please select the participants you want to send an invite to:
            </NextUI.Text>
            <form
              className="flex flex-col gap-4"
              onSubmit={formik.handleSubmit}
              onReset={formik.handleReset}
            >
              <NextUI.RadioGroup
                name="option"
                variant="teal"
                size="sm"
                className="[&_>_label]:text-grey-600"
                value={formik.values.option}
                setValue={(value) => {
                  formik.setFieldValue('option', value)
                }}
              >
                <NextUI.Radio className="text-grey-800" value="new">
                  <NextUI.Text>
                    Send invite to {''}
                    <span className="font-extrabold">
                      participants that have not been invited
                    </span>
                  </NextUI.Text>
                </NextUI.Radio>
                <NextUI.Radio className="text-grey-800" value="all">
                  <NextUI.Text>
                    Send invite to {''}
                    <span className="font-extrabold">
                      all participants, regardless of invitation email
                    </span>
                  </NextUI.Text>
                </NextUI.Radio>
                <NextUI.Radio className="text-grey-800" value="specific">
                  <NextUI.Text>
                    Send invite to {''}
                    <span className="font-extrabold">
                      custom list of participants
                    </span>
                  </NextUI.Text>
                </NextUI.Radio>
              </NextUI.RadioGroup>
              {formik.values.option === inviteTypes.specific && (
                <div className="ml-7 flex flex-col gap-2">
                  <ParticipantsAutosuggestCombobox
                    selectedParticipants={formik.values.selectedParticipants}
                    onSelectedParticipantChange={(participant) => {
                      if (participant !== null) {
                        formik.setFieldValue(
                          'selectedParticipants',
                          Util.uniqueBy(
                            [
                              participant,
                              ...formik.values.selectedParticipants,
                            ],
                            (p) => p.id,
                          ),
                        )
                      }
                    }}
                    fundraiserId={fundraiserId}
                  />
                  {selectedParticipantsCount > 0 && (
                    <WebUI.List<Api.FundraiserParticipant>
                      className="gap-2"
                      data={formik.values.selectedParticipants}
                      RowComponent={ParticipantRowWithHandler}
                    />
                  )}
                </div>
              )}
              <div className="mt-4 flex items-center gap-3">
                <NextUI.NextButton
                  type="submit"
                  size="sm"
                  loading={formik.isSubmitting}
                >
                  {selectedParticipantsCount > 0 &&
                  formik.values.option === inviteTypes.specific
                    ? `Send invites (${selectedParticipantsCount} ${Util.pluralize('Participant', selectedParticipantsCount)})`
                    : 'Send invites'}
                </NextUI.NextButton>
                {formik.errors.selectedParticipants && (
                  <NextUI.FormFieldError>
                    Please select participant(s)
                  </NextUI.FormFieldError>
                )}
              </div>
            </form>
          </div>
        </div>
      </NextUI.ModalContent>
    </NextUI.Dialog>
  )
})
