import * as Ariakit from '@ariakit/react'

export type PortalProps = Ariakit.PortalProps
export const Portal = Ariakit.Portal

// MARK: – Helpers

export function getPortalElement() {
  const div = document.createElement('div')
  const portalRoot = document.body

  // HACK: Ariakit's dialog appended before reakit's
  setTimeout(() => {
    portalRoot.append(div)
  }, 50)

  return div
}
