import * as NextUI from '@cheddarup/web-ui/next'
import {
  Form,
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  FormikContextType,
  FormikProvider,
  FormikValues,
  useField,
  useFormikContext,
} from 'formik'
import React, {useContext, useMemo} from 'react'

export {useFormikContext} from 'formik'
export {FormikProvider}

// MARK: – FormikForm

export interface FormikFormProps<T extends FormikValues>
  extends React.ComponentPropsWithoutRef<'form'> {
  formik: FormikContextType<T>
}

export const FormikForm = React.forwardRef<
  HTMLFormElement,
  FormikFormProps<any>
>(({formik, ...restProps}, forwardedRef) => (
  <FormikProvider value={formik}>
    <Form ref={forwardedRef} {...restProps} />
  </FormikProvider>
))

// MARK: – FormikField

interface InternalFormikFieldContextValue {
  name: string
}

const InternalFormikFieldContext =
  React.createContext<InternalFormikFieldContextValue | null>(null)

export interface FormikFieldProps extends NextUI.FormFieldProps {
  name: string
  control?: FormikControlProps['children']
}

export const FormikField = React.forwardRef<HTMLDivElement, FormikFieldProps>(
  (
    {label, description, error, name, control, children, ...restProps},
    forwardedRef,
  ) => {
    const [_fieldProps, fieldMeta] = useField(name)

    const contextValue: InternalFormikFieldContextValue = useMemo(
      () => ({
        name,
      }),
      [name],
    )

    return (
      <InternalFormikFieldContext.Provider value={contextValue}>
        <NextUI.FormField
          ref={forwardedRef}
          error={!!fieldMeta.error}
          {...restProps}
        >
          {children ?? (
            <>
              {!!label && (
                <NextUI.FormFieldLabel>{label}</NextUI.FormFieldLabel>
              )}

              <FormikControl>{control}</FormikControl>

              {!!description && (
                <NextUI.FormFieldDescription>
                  {description}
                </NextUI.FormFieldDescription>
              )}

              <FormikFieldError />
            </>
          )}
        </NextUI.FormField>
      </InternalFormikFieldContext.Provider>
    )
  },
)

// MARK: – FormikControl

export type FormikChildrenRenderProp = (
  fieldProps: FieldInputProps<any>,
  fieldHelpers: FieldHelperProps<any>,
  fieldMeta: FieldMetaProps<any>,
) => React.ReactElement

export interface FormikControlProps
  extends Omit<NextUI.FormControlProps, 'children'> {
  children?: FormikChildrenRenderProp | React.ReactElement
}

export const FormikControl = React.forwardRef<
  HTMLInputElement,
  FormikControlProps
>(({name: nameProp, children, ...restProps}, forwardedRef) => {
  const contextValue = useContext(InternalFormikFieldContext)

  const name = nameProp ?? contextValue?.name

  if (!name) {
    throw new Error('`FormikControl` missing name')
  }

  const [fieldProps, fieldMeta, fieldHelpers] = useField(name)

  return (
    <NextUI.FormControl
      aria-invalid={!!fieldMeta.error || undefined}
      ref={forwardedRef}
      name={name}
      render={
        typeof children === 'function'
          ? children(fieldProps, fieldHelpers, fieldMeta)
          : children
      }
      {...(typeof children === 'function' ? undefined : fieldProps)}
      {...restProps}
    />
  )
})

// MARK: – FormikFieldError

export interface FormikFieldErrorProps extends NextUI.FormFieldErrorProps {
  name?: string
}

export const FormikFieldError = React.forwardRef<
  HTMLSpanElement,
  FormikFieldErrorProps
>(({name: nameProp, children: childrenProp, ...restProps}, forwardedRef) => {
  const contextValue = useContext(InternalFormikFieldContext)

  const name = nameProp ?? contextValue?.name

  if (!name) {
    throw new Error('`FormikFieldError` missing name')
  }

  const [_fieldProps, fieldMeta] = useField(name)

  const children = childrenProp ?? fieldMeta.error

  if (!children) {
    return null
  }

  return (
    <NextUI.FormFieldError ref={forwardedRef} {...restProps}>
      {children}
    </NextUI.FormFieldError>
  )
})

// MARK: – FormikSubmitButton

export interface FormikSubmitButtonProps extends NextUI.NextButtonProps {}

export const FormikSubmitButton = React.forwardRef<
  HTMLButtonElement,
  FormikSubmitButtonProps
>((props, forwardedRef) => {
  const formik = useFormikContext()

  return (
    <NextUI.NextButton
      ref={forwardedRef}
      type="submit"
      loading={formik.isSubmitting}
      {...props}
    />
  )
})
