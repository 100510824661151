import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {TemplatesSuggestionsModal} from 'src/components/TemplatesSuggestionsModal'
import HandWithCard from 'src/images/HandWithCard.png'
import FlowerPot from 'src/images/FlowerPot.png'
import FeesBadge from 'src/images/FeesBadge.svg'
import {NextLinkButton} from 'src/components/__next/LinkButton'

const FundraiserTypeSelectionModal: React.FC<WebUI.ModalProps> = ({
  initialVisible = false,
  ...restProps
}) => {
  return (
    <WebUI.Modal
      aria-label="Fundraiser Type Selection Modal"
      className="[&_>_.ModalContentView]:overflow-y-auto [&_>_.ModalContentView]:rounded-extended [&_>_.ModalContentView]:p-8 sm:[&_>_.ModalContentView]:max-w-screen-sm"
      role="dialog"
      initialVisible={initialVisible}
      {...restProps}
    >
      <WebUI.ModalCloseButton />
      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col gap-2 pt-4">
          <WebUI.Heading className="font-bold" as="h2">
            Choose a Peer-to-Peer Fundraiser Type
          </WebUI.Heading>
          <WebUI.Text className="font-light">
            A “Peer-to-Peer fundraiser allows you to invite participants to
            fundraise on your behalf, and gives each participant their own,
            customizable participant fundraising page that they can share with
            their communities and track their progress.
          </WebUI.Text>
        </div>
        <div className="flex flex-col justify-between gap-6 sm:flex-row">
          <FundraiserTypePanel type="donation" />
          <FundraiserTypePanel type="sale" />
        </div>
        <div className="flex items-center gap-4 rounded-md bg-violet-500 p-6">
          <WebUI.Image className="w-14 shrink-0" src={FeesBadge} alt="badge" />
          <WebUI.Text className="text-ds-sm text-trueWhite">
            <span className="font-bold">
              Good News! Peer-to-Peer fundraisers are available to all plan
              types.
            </span>
            <br />
            <span className="font-light">
              Amplify the reach of your fundraising with only a 1.95%
              peer-to-peer fee that will be deducted from total funds collected.
            </span>
          </WebUI.Text>
        </div>
      </div>
    </WebUI.Modal>
  )
}

const FUNDRAISER_TYPE_CONTENT = {
  donation: {
    heading: 'Flat Donations',
    description: `Participants ask supporters to contribute a one-time donation on
              their behalf. We’ll keep track of all payments and who is in the
              lead!`,
    imgSrc: HandWithCard,
  },
  sale: {
    heading: 'Item Sales',
    description: `Set up an online store for each participant to share and track their progress.
    Perfect for plant sales, popcorn orders and holiday shops. `,
    imgSrc: FlowerPot,
  },
}

const FundraiserTypePanel = ({type}: {type: 'donation' | 'sale'}) => (
  <WebUI.Panel className="!rounded-lg flex flex-col items-center gap-4 px-4 py-8 text-center">
    <WebUI.Image
      className="h-[88px]"
      src={FUNDRAISER_TYPE_CONTENT[type].imgSrc}
      alt=""
    />
    <WebUI.Text className="font-bold text-ds-md">
      {FUNDRAISER_TYPE_CONTENT[type].heading}
    </WebUI.Text>
    <WebUI.Text className="text-ds-sm">
      {FUNDRAISER_TYPE_CONTENT[type].description}
    </WebUI.Text>
    <NextLinkButton variant="orange" className="w-full" to="/fundraisers/new">
      Create from Scratch
    </NextLinkButton>
    <TemplatesSuggestionsModal
      initialVisible={false}
      disclosure={
        <WebUI.DialogDisclosure
          variant="link"
          className="text-ds-sm text-orange-500"
        >
          Use a Template
        </WebUI.DialogDisclosure>
      }
    />
  </WebUI.Panel>
)

export default FundraiserTypeSelectionModal
