import {useBeforeUnload} from 'react-router-dom'
import * as NextUI from '@cheddarup/web-ui/next'
import {useCallback, useMemo} from 'react'
import {useLiveRef} from '@cheddarup/react-util'
import {
  FetchInput,
  MutateFunction,
  UseMutationResult,
} from '@cheddarup/api-client'
import {useToastsActions} from '@cheddarup/web-ui/next'

type UndoMutationToastPayload = Omit<NextUI.ToastContentUndo, 'type'> &
  Pick<NextUI.ToastOptions, 'onClose'>

export function useUndoableMutation<
  T extends UseMutationResult<any, any, number>,
  TVariables = T extends UseMutationResult<
    any,
    infer _T extends FetchInput<any>,
    any
  >
    ? _T
    : never,
>(
  mutation: T,
): T & {
  mutateWithUndo: (
    toastPayload: UndoMutationToastPayload,
    ...args: Parameters<MutateFunction<T['data'], unknown, TVariables>>
  ) => void
  mutateAsyncWithUndo: (
    toastPayload: UndoMutationToastPayload,
    ...args: Parameters<MutateFunction<T['data'], unknown, TVariables>>
  ) => Promise<MutateFunction<T['data']>>
} {
  const mutationRef = useLiveRef(mutation)
  const toastActions = useToastsActions()

  useBeforeUnload(
    useCallback((event) => {
      if (!mutationRef.current.isCancelPendingRef.current) {
        return
      }

      event.preventDefault()

      // biome-ignore lint/suspicious/noAssignInExpressions:
      return (event.returnValue = '') // See https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes
    }, []),
  )

  return useMemo(() => {
    function showUndoGrowl({onClose, ...content}: UndoMutationToastPayload) {
      toastActions.show(
        'undo',
        {
          ...content,
          onUndo: () => {
            content.onUndo?.()
            try {
              mutationRef.current.cancelRef.current?.()
            } catch {
              // noop, ignore "User cancelled error"
            }
          },
        },
        {
          timeout: mutationRef.current.timeout,
          onClose: () => {
            onClose?.()
            mutationRef.current.forceRunRef.current?.()
          },
        },
      )
    }

    return {
      ...mutationRef.current,
      mutateWithUndo: (toastPayload, ...args) => {
        showUndoGrowl(toastPayload)
        return mutationRef.current.mutate(...args)
      },
      mutateAsyncWithUndo: (toastPayload, ...args) => {
        showUndoGrowl(toastPayload)
        return mutationRef.current.mutateAsync(...args)
      },
    }
  }, [toastActions.show])
}
