import {usePublishTabMutation} from '@cheddarup/api-client'
import {Image} from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'
import {useState} from 'react'
import MegaPhoneImage from 'src/images/megaphone.png'
import PublishFundraiserConfetti from 'src/images/DonationCodeConfetti.svg'

interface FundraiserPublishPanelProps {
  fundraiser: Api.Tab
  onDidPublish: () => void
}

export const FundraiserPublishPanel = ({
  fundraiser,
  onDidPublish,
}: FundraiserPublishPanelProps) => {
  const [justPublished, setJustPublished] = useState(false)
  const publishTabMutation = usePublishTabMutation()

  return (
    <NextUI.Panel className="flex-row items-center gap-7 overflow-visible px-8 py-7 shadow-10 sm:rounded-default">
      <Image
        src={PublishFundraiserConfetti}
        height={150}
        width={150}
        alt=""
        className={`-left-4 -top-4 absolute transform transition-all duration-300 ${justPublished ? 'scale-100 opacity-100' : 'scale-50 opacity-0'}`}
      />
      <Image src={MegaPhoneImage} alt="megaphone" width={150} height={188} />
      <div className="flex flex-col items-start gap-4">
        <NextUI.Text className="font-extrabold text-ds-xl text-grey-800">
          Publish your fundraiser to start sharing
        </NextUI.Text>
        <ul className="list-disc pl-5 text-ds-md">
          <li>
            Once your fundraiser is published, you'll be able to add and invite
            participants
          </li>
          <li>You can edit your fundraiser after publishing</li>
        </ul>
        <div className="flex flex-row gap-4">
          <NextUI.NextAnchorButton variant="teal" href="" size="xl">
            Preview
          </NextUI.NextAnchorButton>
          <NextUI.NextButton
            variant="orange"
            size="xl"
            loading={publishTabMutation.isPending}
            onClick={async () => {
              await publishTabMutation.mutateAsync({
                pathParams: {
                  tabId: fundraiser.id,
                },
              })
              setJustPublished(true)
              onDidPublish()
            }}
          >
            Publish
          </NextUI.NextButton>
        </div>
      </div>
    </NextUI.Panel>
  )
}
