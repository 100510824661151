import * as NextUI from '@cheddarup/web-ui/next'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {
  DashboardSettingPanel,
  DashboardSettingPanelLabel,
} from './DashboardPageLayout'

export interface SettingDisclosureProps
  extends Pick<NextUI.DisclosureProps, 'open' | 'setOpen' | 'children'>,
    Omit<NextUI.PanelProps, 'children'> {}

export const SettingDisclosure = React.forwardRef<
  NextUI.DisclosureInstance,
  SettingDisclosureProps
>(({open, setOpen, children, ...restProps}, forwardedRef) => (
  <NextUI.Disclosure ref={forwardedRef} open={open} setOpen={setOpen}>
    {(disclosure) => (
      <DashboardSettingPanel {...restProps}>
        {typeof children === 'function' ? children(disclosure) : children}
      </DashboardSettingPanel>
    )}
  </NextUI.Disclosure>
))

// MARK: – SettingDisclosureSwitch

export interface SettingDisclosureSwitchProps
  extends NextUI.DisclosureButtonProps,
    Pick<NextUI.SwitchProps, 'size'> {}

export const SettingDisclosureSwitch = React.forwardRef<
  HTMLInputElement,
  SettingDisclosureSwitchProps
>(({size, children, ...restProps}, forwardedRef) => {
  const disclosureContext = NextUI.useDisclosureContext()
  const isOpen = WebUI.useStoreState(disclosureContext, (s) => s?.open)

  return (
    <NextUI.DisclosureButton
      render={
        <NextUI.Switch
          ref={forwardedRef}
          className="flex flex-row-reverse justify-between"
          checked={isOpen}
          size={size ?? 'lg'}
        />
      }
      {...restProps}
    >
      <DashboardSettingPanelLabel>{children}</DashboardSettingPanelLabel>
    </NextUI.DisclosureButton>
  )
})

// MARK: – SettingDisclosureContent

export interface SettingDisclosureContentProps
  extends NextUI.DisclosureContentProps {}

export const SettingDisclosureContent = React.forwardRef<
  HTMLDivElement,
  SettingDisclosureContentProps
>(({className, ...restProps}, forwardedRef) => (
  <NextUI.DisclosureContent
    ref={forwardedRef}
    className={WebUI.cn('flex flex-col gap-4 text-ds-sm sm:mr-10', className)}
    {...restProps}
  />
))
